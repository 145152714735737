import React from 'react';

const DobleInputs = ({ label, valueDate, valueTime }) => {
    return (
        <div className="input-group input-group-sm mb-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><small>{label}</small></span>
            </div>
            <input type="text" value={valueDate && !!valueDate ? new Date(valueDate.replaceAll("-", "/")).toLocaleString("default", { day: '2-digit', month: '2-digit', year: 'numeric' }) : ''} disabled className="form-control" />
            <input type="text" value={valueTime && !!valueTime ? valueTime.replace(":00", "") : ''} disabled className="form-control col-4" />
        </div>
    )
}

export default React.memo(DobleInputs)