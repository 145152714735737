import React, { useContext } from 'react'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function DescriptionInput() {

    const { currentService = {}, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <InputsElements
            label="Comentario"
            name="Description"
            value={!!dinamicDataService?.ClosingDate ? currentService?.Description : dinamicDataService?.Description ?? ''}
            functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
            inputState={!!dinamicDataService?.ClosingDate}
        />
    )
}

export default DescriptionInput