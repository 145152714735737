import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../../elements/SelectInput'
import { options } from './options'

function RoturaSelect() {

    const { editNewServiceHandler, newService } = useContext(MotorBikesContext)
    const { currentService = {}, dinamicDataService = {} } = useContext(ContextService)

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        label="Rotura"
                        name="U_Rotura"
                        value={!!newService.U_Rotura ? newService.U_Rotura : ''}
                        inputState={true}
                        functEdit={e => editNewServiceHandler(e)}
                    >
                        <option hidden>Elegir...</option>
                        {options?.YESORNO.map(val => <option key={'rotura' + val}
                            selected={!!newService.U_Rotura && newService.U_Rotura === val}
                            value={val}>{val}</option>)}
                    </SelectInput>
                    :
                    <SelectInput
                        label="Rotura"
                        name="U_Rotura"
                        value={!!currentService.U_Rotura ? currentService.U_Rotura : ''}
                        inputState={true}
                        functEdit={e => editNewServiceHandler(e)}
                    >
                        <option hidden>Elegir...</option>
                        {options?.YESORNO.map(val => <option key={'rotura' + val}
                            selected={!!currentService.U_Rotura && currentService.U_Rotura === val}
                            value={val}>{val}</option>)}
                    </SelectInput>
            }
        </>
    )
}

export default React.memo(RoturaSelect)