import React, { useEffect, useRef, useState } from 'react'
import '../../css/modalServices.css'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'
import SweetAlert from '../../elements/SweetAlert'
import clientAxios from '../../utils/clientAxios'
import Spinner from '../Spinner'

export default function ModalLogin({ setCompanyDB }) {

    const history = useHistory()
    const [cookies, setCookie] = useCookies()
    const modalLogin = useRef(null)
    const [loginData, setLoginData] = useState({
        CompanyDB: 'YUHMAKSA_PRD',
        userLoged: '',
        tryTologin: false
    })

    const loginHandler = async (e) => {
        e.preventDefault()
        setLoginData({ ...loginData, tryTologin: true })
        try {
            const resultLogin = await clientAxios.post('/api/v1/login', loginData)
            modalLogin.current.classList.remove('show', 'backModal', 'd-block')
            modalLogin.current.removeAttribute("style")
            localStorage.setItem('userName', loginData.UserName)
            console.log('resultLogin.data', resultLogin.data[["set-cookie"]]);
            for (const cookie of resultLogin.data["set-cookie"]) {
                const cookieName = cookie.split("=")[0]
                const cookieValue = cookie.split("=")[1].split(";")[0]
                setCookie(cookieName, cookieValue, { path: '' })
            }
            setCompanyDB(loginData.CompanyDB)
            setCookie('userName', loginData.UserName)
            setCookie('pass', loginData.Password)

            setLoginData({ ...loginData, tryTologin: false })
            history.push("/inicio")
        } catch (error) {
            const { response } = error
            console.log(response)
            if (response?.status === 401 && response?.statusText === "Unauthorized") {
                SweetAlert({
                    title: 'Ocurrió un error',
                    html: '<p className="mb-0">El usuario no posee los permisos correspondientes <br />o<br /> Las credenciales son incorrectas </p>',
                    showConfirmButton: true,
                    width: '400',
                })
            } else {
                SweetAlert({
                    title: `${response?.data?.message}`,
                    text: `Por favor, intenta nuevamente`,
                    showConfirmButton: true,
                })
            }
            setLoginData({ ...loginData, tryTologin: false })
        }
    }

    useEffect(() => {
        if (!cookies.B1SESSION) {
            modalLogin.current.classList.add('show', 'backModal', "d-block")
            modalLogin.current.style.display = 'block'
        }
    }, [cookies.B1SESSION])

    return (
        <div className="modal fade" id="loginModal" ref={modalLogin} tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true" data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                    <div className="modal-body">
                        <form onSubmit={loginHandler}>
                            <div className="form-group">
                                <label htmlFor="user">Usuario</label>
                                <input type="text" className="form-control" name="UserName" onChange={(e) => setLoginData({ ...loginData, [e.target.name]: e.target.value })} id="user" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="Password">Contraseña</label>
                                <input type="password" className="form-control" name="Password" onChange={(e) => setLoginData({ ...loginData, [e.target.name]: e.target.value })} id="Password" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="bd">Base de Datos</label>
                                <select className="custom-select" name="CompanyDB" onChange={(e) => setLoginData({ ...loginData, [e.target.name]: e.target.value })} defaultValue={{ value: "YUHMAKSA_PRD" }}>
                                    <option hidden selected>Elegir Base de Datos</option>
                                    <option value="YUHMAKSA_PRD">YUHMAKSA_PRD</option>
                                    <option value="YUHMAKSA_PRD3">YUHMAKSA_PRD3</option>
                                    <option value="YUHMAKSA_TEST">YUHMAKSA_TEST</option>
                                </select>
                            </div>
                            {
                                loginData.tryTologin ?
                                    <Spinner color="text-primary" />
                                    :
                                    <button type="submit" className="btn btn-primary">Ingresar</button>
                            }
                            <button hidden className="close" data-dismiss="modal" aria-label="Close"></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}