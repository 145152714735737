import React, { useContext, useEffect, useState } from 'react'
import clientAxios from '../../../../../utils/clientAxios'
import SelectInput from '../../../../../elements/SelectInput'
import { ContextService } from '../../../../../contexts/serviceGralContext'

const EmployeesInfo = () => {

    const { currentService = {}, dinamicDataService = {}, editDinamicServiceDataHandler } = useContext(ContextService)

    const [employeesData, setEmployeesData] = useState([])

    const valueForSelected = !!dinamicDataService?.ClosingDate ? currentService?.TechnicianCode : dinamicDataService?.TechnicianCode

    useEffect(() => {
        let isSubscribed = true
        if (employeesData.length === 0) {
            (async () => {
                try {
                    const result = await clientAxios.get('/api/v1/EmployeesInfo')
                    // isSubscribed ?
                    setEmployeesData(result.data)
                    // : null
                } catch (error) {
                    return isSubscribed = false
                }
            })()
        }
        return () => isSubscribed = false
    }, [])

    return (
        <SelectInput
            label="Técnico"
            type="number"
            name="TechnicianCode"
            value={valueForSelected ?? ""}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
        >
            <option hidden selected>Elegir Técnico</option>
            {
                employeesData?.sort((a, b) => (a.LastName.localeCompare(b.LastName)) || (a.FirstName.localeCompare(b.FirstName))).map(emp => <option key={'employee' + emp.EmployeeID}
                    selected={valueForSelected == emp.EmployeeID} value={emp.EmployeeID}>{emp.LastName + ' ' + emp.FirstName}</option>)
            }
        </SelectInput>
    )

}

export default React.memo(EmployeesInfo)