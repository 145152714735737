import React, { useContext, useEffect, useRef, useState, memo } from 'react'
import { ContextService } from '../../../../../contexts/serviceGralContext'
import { MotorBikesContext } from '../../../../../contexts/motorBikesContext'
import SelectInput from '../../../../../elements/SelectInput'
import clientAxios from '../../../../../utils/clientAxios'

function ProblemType() {

    const prevPage = window.localStorage.getItem("prevPage")
    const condition = window.localStorage.getItem("condition")

    const [problemTypeData, setProblemTypeData] = useState([])

    const { editStatesToFilter, stateToFilter = {} } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    const problemTypeSelect = useRef(null)
    const valueForSelected = !!dinamicDataService?.ClosingDate ? currentService?.ProblemType : dinamicDataService?.ProblemType

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async function getProblemData() {
        try {
            const resultServiceCallProblemTypes = await clientAxios.get('/api/v1/ServiceCallProblemTypes', {
                params: {
                    conditions: `(contains(Description, '${condition}'))`
                }
            })
            setProblemTypeData(resultServiceCallProblemTypes.data)
        } catch (error) {
            console.log(error && !!error.response ? error.response : error)
        }
    }

    useEffect(() => getProblemData(), [])

    useEffect(() => {
        editStatesToFilter({
            problemTypeSelect: problemTypeSelect?.current?.options?.[problemTypeSelect?.current?.selectedIndex].text
        })
    }, [problemTypeSelect?.current?.selectedIndex, stateToFilter?.originSelect, problemTypeData, currentService?.Origin])

    return (
        <SelectInput
            refProps={problemTypeSelect}
            label="Tipo de Problema"
            name="ProblemType"
            id="ProblemType"
            value={valueForSelected ?? ''}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => {
                !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })
                editStatesToFilter({
                    problemTypeSelect: problemTypeSelect?.current?.options?.[problemTypeSelect?.current?.selectedIndex].text
                })
            }}
        >
            <option hidden>Elegir Problema</option>
            {
                (problemTypeData.length > 0 && Object.keys(stateToFilter).length > 0) &&
                problemTypeData.sort((a, b) => a.Name.localeCompare(b.Name))
                    .filter(desc => prevPage || desc.Description?.includes(stateToFilter.originSelect) )
                    .map(array1 => <option key={'problemType' + array1.ProblemTypeID }
                        selected={valueForSelected == array1.ProblemTypeID}
                        value={array1.ProblemTypeID} >
                        {array1.Name.replace(/-\s?INT\s?/gmi, "")}
                        </option>
               
                    )



            }
         
        </SelectInput>
    )
}

export default memo(ProblemType)