import React from 'react'
import AlarmaSelect from './AlarmaSelect'
import CascoSelect from './CascoSelect'
import CombustibleSelect from './CombustibleSelect'
import FaltanteSelect from './FaltanteSelect'
import KitHerramientaSelect from './KitHerramientaSelect'
import ManchadoSelect from './ManchadoSelect'
import RayadoSelect from './RayadoSelect'
import RoturaSelect from './RoturaSelect'
import { options } from './options'
import { useHistory } from 'react-router-dom'

const ObsVehicle = ({ editHandler, currentService }) => {

    const history = useHistory()

    return <div className="row mx-0">
        <div className="col-12 col-md-6 px-1">
            {
                history?.location?.state?.prevPage?.includes("bikes")
                    ?
                    null
                    :
                    <AlarmaSelect currentService={currentService} editHandler={editHandler} values={options.YESORNO} />
            }
            <KitHerramientaSelect currentService={currentService} editHandler={editHandler} values={options.YESORNO} />
            <RayadoSelect currentService={currentService} editHandler={editHandler} values={options.YESORNO} />
            <ManchadoSelect currentService={currentService} editHandler={editHandler} values={options.YESORNO} />
        </div>
        <div className="col-12 col-md-6 px-1">
            <CascoSelect currentService={currentService} editHandler={editHandler} values={options.YESORNO} />
            <FaltanteSelect currentService={currentService} editHandler={editHandler} values={options.YESORNO} />
            <RoturaSelect currentService={currentService} editHandler={editHandler} values={options.YESORNO} />
            {
                history?.location?.state?.prevPage?.includes("bikes")
                    ?
                    null
                    :
                    <CombustibleSelect currentService={currentService} editHandler={editHandler} values={options.COMBUSTIBLE[0]} />
            }
        </div>
    </div>
}

export default React.memo(ObsVehicle)