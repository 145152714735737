import React, { createContext, useCallback, useEffect, useState } from 'react'
import useReducerMotorBikes from './useReducerMotorBikes'
import { initialStateAllServices } from '../initialStateAllServices'
import { servicesMotorBikeActions } from '../actions'

export default ({ children }) => {

    const { currentService = {}, newService = {}, currentVehicleData, servicesCurrentVehicle, stateToFilter, dispatchMotorBikes } = useReducerMotorBikes(initialStateAllServices)

    const [canEdit, setCanEdit] = useState(false)

    const editNewServiceHandler = useCallback(content => {
        dispatchMotorBikes({
            type: servicesMotorBikeActions.EDIT_NEWSERVICE,
            payload: content
        })
    }, [dispatchMotorBikes])

    const setNewService = useCallback(content => {
        dispatchMotorBikes({
            type: servicesMotorBikeActions.SET_NEWSERVICE,
            payload: content
        })
    }, [dispatchMotorBikes])

    const setCurrentService = useCallback(content => {
        dispatchMotorBikes({
            type: servicesMotorBikeActions.SET_CURRENTSERVICE,
            payload: content
        })
    }, [dispatchMotorBikes])

    const clearCurrentService = useCallback(() => {
        dispatchMotorBikes({
            type: servicesMotorBikeActions.CLEAR_CURRENTSERVICE,
            payload: {}
        })
    }, [dispatchMotorBikes])

    const setServicesCurrentVehicle = useCallback(content => {
        dispatchMotorBikes({
            type: servicesMotorBikeActions.SET_SERVICESCURRENTVEHICLE,
            payload: content
        })
    }, [dispatchMotorBikes])

    const clearServicesCurrentVehicle = useCallback(() => {
        dispatchMotorBikes({
            type: servicesMotorBikeActions.CLEAR_SERVICESCURRENTVEHICLE,
            payload: []
        })
    }, [dispatchMotorBikes])

    const editStatesToFilter = useCallback(content => {
        dispatchMotorBikes({
            type: servicesMotorBikeActions.EDIT_STATETOFILTER,
            payload: content
        })
    }, [dispatchMotorBikes])

    useEffect(() => {
        setCanEdit(currentService.Status != "-1")
    }, [currentService])

    return (
        <MotorBikesContext.Provider value={{
            currentService,
            newService,
            currentVehicleData,
            servicesCurrentVehicle,
            canEdit,
            stateToFilter,
            setCanEdit,
            setNewService,
            editNewServiceHandler,
            setCurrentService,
            clearCurrentService,
            setServicesCurrentVehicle,
            clearServicesCurrentVehicle,
            editStatesToFilter,
        }}>
            {children}
        </MotorBikesContext.Provider>
    )
}

export const MotorBikesContext = createContext()