import React, { useEffect, useState } from 'react'
import clientAxios from '../../utils/clientAxios'
import auth from '../../utils/auth'
import '../../css/navbar.css'
import ModalLogin from './ModalLogin'

import ModalOffices from './ModalOffices'

import '../../css/modalServices.css'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom'


export default function Login({ setSucursal, setCompanyDB }) {

    const [cookies] = useCookies()
    const [logedin, setLogedin] = useState(false)

    const history = useHistory()

    const logout = async () => {
        try {
            await auth.logOut()
            await clientAxios.post('/api/v1/Logout')
            setLogedin(false)
            localStorage.removeItem('lastDNI')
            localStorage.removeItem('lastChasis')
        } catch (error) {
            console.log('Error Logout ->', error)
        }
    }

    useEffect(() => {
        (cookies.B1SESSION && cookies.WareHouse) && setLogedin(true)
    }, [cookies.B1SESSION, cookies.WareHouse])

    useEffect(() => {
        (logedin && history.location.pathname === '/') && history.push('/inicio')
    }, [logedin])

    return (
        <>
            {
                logedin ?
                    <button className="btn btn-link text-decoration-none px-2 text-white" onClick={logout}>Salir</button>
                    :
                    <>
                        <ModalLogin setCompanyDB={setCompanyDB} />

                        <button className="btn btn-link text-decoration-none px-2 text-white" data-toggle="modal" data-target="#loginModal">Ingresar</button>
                    </>
            }
            <ModalOffices setSucursal={setSucursal} />
        </>
    )
}