import React, { useContext } from 'react'
import { ContextService } from '../../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../../elements/SelectInput'
import { options } from './options'

const CombustibleSelect = () => {

    const { currentService = {}, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)
    const valueForSelected = !!dinamicDataService?.ClosingDate ? currentService?.U_Nivel_Combustible : dinamicDataService?.U_Nivel_Combustible

    return (
        <SelectInput
            label="Nivel Combustible"
            name="U_Nivel_Combustible"
            value={valueForSelected ?? ''}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
        >
            <option hidden>Elegir...</option>
            {Object.keys(options.COMBUSTIBLE[0]).map(val => <option key={options.COMBUSTIBLE[0][val]}
                selected={valueForSelected == val}
                value={val}>{options.COMBUSTIBLE[0][val]}</option>
            )}
        </SelectInput>
    )
}

export default React.memo(CombustibleSelect)