import React from 'react'
import ReactDOM from "react-dom"
import './index.css'
import App from './App'
import auth from './utils/auth'
import clientAxios from './utils/clientAxios'
import Swal from 'sweetalert2'
import Notiflix from 'notiflix'

clientAxios.interceptors.request.use(config => {
  if (auth.isAuthenticated()) {
    config.headers.Authorization = document.cookie
  }
  return config
})

clientAxios.interceptors.response.use(
  response => {
    return response
  }, error => {
    const { response } = error
    if (response.status === 301 && response.data.message.includes('Invalid session')) {
      Swal.fire({
        title: 'Uuups',
        text: 'Tu sesión expiró, por favor vuelve a ingresar',
        showConfirmButton: true,
        confirmButtonText: 'Volver a Ingresar',
        showClass: {
          popup: '',
          icon: ''
        },
        hideClass: {
          popup: '',
        }
      }).then((result) => {
        if (result.isConfirmed) {
          auth.logOut()
          // window.location = '/'
        }
      })
      return
    }
    return Promise.reject(error)
  }
)

Notiflix.Loading.init({
  messageColor: 'white',
  svgColor: '#354a5f',
  backgroundColor: 'rgba(0,0,0,0.3)',
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
