import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../../elements/SelectInput'
import { options } from './options'

function RayadoSelect() {

    const { editNewServiceHandler, newService } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        label="Rayado"
                        name="U_Rayado"
                        value={!!newService.U_Rayado ? newService.U_Rayado : ''}
                        inputState={true}
                        functEdit={e => editNewServiceHandler(e)}
                    >
                        <option hidden>Elegir...</option>
                        {options?.YESORNO.map(val => <option key={'rayado' + val}
                            selected={!!newService.U_Rayado && newService.U_Rayado === val}
                            value={val}>{val}</option>)}
                    </SelectInput>
                    :
                    <SelectInput
                        label="Rayado"
                        name="U_Rayado"
                        value={!!currentService.U_Rayado ? currentService.U_Rayado : ''}
                        inputState={true}
                    >
                        <option hidden>Elegir...</option>
                        {options?.YESORNO.map(val => <option key={'rayado' + val}
                            selected={!!currentService.U_Rayado && currentService.U_Rayado === val}
                            value={val}>{val}</option>)}
                    </SelectInput>
            }
        </>
    )
}

export default React.memo(RayadoSelect)