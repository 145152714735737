import React from 'react';
import TableOfCombos from './TableOfCombos';

const DocRelacionados = () => {
    return (
        <div className="tab-pane fade" id="nav-Doc-Relacionados" role="tabpanel" aria-labelledby="nav-Doc-Relacionados-tab">
            <div className="p-2">
                <TableOfCombos />
            </div>
        </div>
    );
}

export default React.memo(DocRelacionados)