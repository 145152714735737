import React, { useEffect, useRef, useState, useContext, memo } from 'react'
import { MotorBikesContext } from '../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../elements/SelectInput'
import clientAxios from '../../../../../utils/clientAxios'

function Origin() {

    const { editStatesToFilter } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    const valueForSelected = !!dinamicDataService?.ClosingDate ? currentService?.Origin : dinamicDataService?.Origin

    const originSelect = useRef(null)

    const condition = window.localStorage.getItem("condition")

    const [originData, setOriginData] = useState([])

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async function getOriginData() {
        try {
            if (originData.length === 0) {
                const resultServiceCallOrigins = await clientAxios.get('/api/v1/ServiceCallOrigins', {
                    params: {
                        conditions: condition
                    }
                })
                console.log('Resp Ok', resultServiceCallOrigins)
                setOriginData(resultServiceCallOrigins.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => getOriginData(), [])

    useEffect(() => {
        editStatesToFilter({ originSelect: originSelect?.current?.options?.[originSelect?.current?.selectedIndex].text })
    }, [originSelect?.current?.selectedIndex, originData, currentService?.Origin])

    return (

        <SelectInput
            refProps={originSelect}
            label="Origen"
            name="Origin"
            value={valueForSelected ?? ''}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => {
                !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })
                editStatesToFilter({ originSelect: originSelect?.current?.options?.[originSelect?.current?.selectedIndex].text })
            }}
        >
            <option hidden>Elegir Origen</option>
            {
                
                originData?.sort((a, b) => a.Name.localeCompare(b.Name))
                    .map(o => <option key={'origin' + o.OriginID}
                        selected={valueForSelected == o.OriginID}
                        value={o.OriginID}
                    >
                        {o.Name}
                    </option>)
            }
        </SelectInput>
    )
}

export default memo(Origin)