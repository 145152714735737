export const actionsServices = {
    UPDATE_SERVICEDATA: 'SERVICE',
    REPLACE_SERVICEDATA: 'replace SERVICE',
    UPDATE_INTERNALSERVICEDATA: 'INTERNALSERVICE',
    REPLACE_INTERNALSERVICEDATA: 'replace INTERNALSERVICE',
    UPDATE_BUSINESSDATA: 'BUSINESS',
    UPDATE_INVENTORYEXIT: 'INVENTORY',
    REMOVE_ALL_ITEMS: 'remove ITEMS',
    REMOVE_INVENTORYEXIT: 'remove UNIQUE ITEMS',
    REMOVE_ALL_VALUES: 'remove VALUES',
    REPLACE_INVENTORYEXIT: 'replace ITEMS',
    UPDATE_ORIGININPUT: 'origin',
    UPDATE_PROBLEMTYPE: 'problemType',
    UPDATE_PROBLEMSUBTYPE: 'problemSubType',
    UPDATE_INPUTSNAME: 'update-inputsName'
}