export const businessActions = {
    SET_BUSINESS: 'set-business',
    CLEAR_BUSINESS: 'clear-business',
    SET_BUSINESSVEHICLES: 'set-businessVehicles',
    CLEAR_BUSINESSVEHICLES: 'clear-businessVehicles',
    SET_INTERNALVEHICLES: 'set-internalVehicles',
    SET_CURRENTVEHICLEDATA: 'set-currentVehicleData',
    CLEAR_CURRENTVEHICLEDATA: 'clear-currentVehicleData',
    SET_BUSINESSVEHICLESNOTFROMYUHMAK: 'set-businessVehiclesNotFromYuhmak',
    CLEAR_BUSINESSVEHICLESNOTFROMYUHMAK: 'clear-businessVehiclesNotFromYuhmak',
    // bikes Actions
    SET_BUSINESSBIKES: 'set-businessBikes',
    CLEAR_BUSINESSBIKES: 'clear-businessBikes',
    SET_CURRENTBIKEDATA: 'set-currentBikeData',
    CLEAR_CURRENTBIKEDATA: 'clear-currentBikeData',
    SET_BUSINESSBIKESNOTFROMYUHMAK: 'set-businessBikesNotFromYuhmak',
    CLEAR_BUSINESSBIKESNOTFROMYUHMAK: 'clear-businessBikesNotFromYuhmak',
}

export const servicesMotorBikeActions = {
    SET_NEWSERVICE: 'set-newService',
    EDIT_NEWSERVICE: 'edit-newService',
    EDIT_STATETOFILTER: 'edit-stateToFilter',
    CLEAR_NEWSERVICE: 'clear-newService',
    SET_CURRENTSERVICE: 'set-currentService',
    SET_CURRENTSERVICESELECTCOMBO: 'set-currentServiceSelectCombo',
    CLEAR_CURRENTSERVICE: 'clear-currentService',
    SET_SERVICESCURRENTVEHICLE: 'set-servicesCurrentVehicle',
    CLEAR_SERVICESCURRENTVEHICLE: 'clear-servicesCurrentVehicle',
}

export const servicesBikesActions = {
    UPDATE_OPTIONSTEXT: 'update-optionsText',
    EDIT_NEWSERVICEBIKES: 'edit_newServiceBikes'
}

export const currentServiceActions = {
    SET_NEWSERVICE: 'set-newService',
    EDIT_STATETOFILTER: 'edit-stateToFilter',
    SET_INVENTORYEXIT: 'set-inventoryExit',
    EDIT_INVENTORYEXIT: 'edit-inventoryExit',
    SET_CURRENTSERVICE: 'set-currentService',
    CLEAR_CURRENTSERVICE: 'clear-currentService',
    SET_INTERNALVEHICLES: 'set-internalVehicles',
    SET_STATICDATASERVICE: 'set-staticDataService',
    EDIT_DINAMICDATASERVICE: 'edit-dinamicDataService',
    CLEAR_STATICDATASERVICE: 'clear-staticDataService',
    CLEAR_DINAMICDATASERVICE: 'clear-dinamicDataService',
    SET_SERVICESCURRENTVEHICLE: 'set-servicesCurrentVehicle',
    SET_CURRENTSERVICESELECTCOMBO: 'set-currentServiceSelectComboActions',
    CLEAR_SERVICESCURRENTVEHICLE: 'clear-servicesCurrentVehicle',
}
