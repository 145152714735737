import React, { useCallback, useContext, useEffect } from 'react'
import { MotorBikesContext } from '../../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../../elements/SelectInput'
import { options } from './options'

const AlarmaSelect = () => {

    const { editNewServiceHandler, newService } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <>
            {
                !dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        label="Alarma"
                        name="U_Alarma"
                        value={newService.U_Alarma ?? ''}
                        inputState={true}
                        functEdit={e => editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
                    >
                        <option hidden>Elegir...</option>
                        {options?.YESORNO.map(val => <option key={'alarma' + val} selected={!!newService.U_Alarma && newService.U_Alarma === val} value={val}>{val}</option>)}
                    </SelectInput>
                    :
                    <SelectInput
                        label="Alarma"
                        name="U_Alarma"
                        value={currentService.U_Alarma ?? ''}
                        inputState={true}
                    >
                        <option hidden>Elegir...</option>
                        {options?.YESORNO.map(val => <option key={'alarma' + val} selected={!!currentService.U_Alarma && currentService.U_Alarma === val} value={val}>{val}</option>
                        )}
                    </SelectInput>
            }
        </>
    )
}

export default React.memo(AlarmaSelect)