import { useContext, useReducer } from "react"
import { useCookies } from "react-cookie"
import { ContextService } from "../../../contexts/serviceGralContext"

export const useControllersService = ({ reducer }) => {
    const { businessData = [], currentVehiclesData = {} } = useContext(ContextService)
    const [cookies] = useCookies([])

    const [state, dispatch] = useReducer(reducer, {
        servicio: {
            CustomerCode: businessData.length > 0 ? businessData[0].CardCode : '',
            Status: -3,
            Priority: "scp_Low",
            CallType: 1,
            BPCellular: businessData.length > 0 ? businessData[0].Cellular : '',
            BPeMail: businessData.length > 0 ? businessData[0].EmailAddress : '',
            BPBillToCode: businessData.length > 0 ? businessData[0].BilltoDefault : '',
            AssigneeCode: (cookies && cookies.user) ? cookies.user : '',
            InternalSerialNum: currentVehiclesData.InternalSerialNumber,
            ItemDescription: currentVehiclesData.ItemDescription,
            U_Chasis: currentVehiclesData.U_Chasis,
            U_Motor: currentVehiclesData.U_Motor,
            U_Almacen: (cookies && cookies.WareHouse) ? cookies.WareHouse : '',
            ItemCode: currentVehiclesData.ItemCode,
            remitoDocEntry: currentVehiclesData.remitoDocEntry
        },
        inventoryExit: [],
        servicioInterno: {
            CustomerCode: 'P30623893096',
            CustomerName: 'YUHMAK S.A.',
            BPBillToCode: "Bill to",
            BPCellular: 3816111111,
            ServiceBPType: "srvcPurchasing",
            Status: -3,
            Priority: "scp_Low",
            CallType: 1,
            AssigneeCode: (cookies && cookies.user) ? cookies.user : '',
            U_Almacen: (cookies && cookies.WareHouse) ? cookies.WareHouse : '',
            InternalSerialNum: currentVehiclesData.InternalSerialNumber,
            ItemDescription: currentVehiclesData.ItemDescription,
            U_Chasis: currentVehiclesData.U_Chasis,
            U_Motor: currentVehiclesData.U_Motor,
            U_Almacen: (cookies && cookies.WareHouse) ? cookies.WareHouse : '',
            ItemCode: currentVehiclesData.ItemCode
        },
        filterState: {}
    })

    const { servicio, inventoryExit, servicioInterno, filterState } = state

    return { servicio, inventoryExit, servicioInterno, filterState, dispatch }
}