import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { getUserData } from '../components/login/getUserData'
import Notiflix from 'notiflix'
import debaunce from 'just-debounce-it'

export function useGetUserData({ user = '', internalKey = '' }) {
    const [cookies, setCookie, removeCookies] = useCookies()
    const [userData, setUserData] = useState()

    async function getData() {
        if (!!user && !!cookies.WareHouse === false) {
            Notiflix.Loading.dots('Cargando Sucursales...')
            const data = await getUserData({ user })
            if (data?.status === 401 && data?.statusText === "Unauthorized") {
                Object.keys(cookies).forEach(cookie => removeCookies(cookie))
                Notiflix.Loading.remove()
                return
            }
            if (!!data) {
                setCookie('user', data.user, { path: '/' })
                setUserData(data)
                localStorage.setItem('userData', data)
                Notiflix.Loading.remove()
            }
        } else if (!!internalKey) {
            const data = await getUserData({ internalKey })
            if (!!data) {
                setUserData(data.UserName)
            }
            Notiflix.Loading.remove()
        }
    }

    useEffect(debaunce(getData, 200), [user, internalKey])

    return { userData }
}