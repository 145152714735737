import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { BusinessContext } from '../../contexts/businessContext'
import TableComponent from '../../elements/TableComponent'

function TableOfBikes() {

    const history = useHistory()

    const { businessData = {}, businessBikes = [], businessBikesNorFromYuhmak } = useContext(BusinessContext)

    const rows = businessBikes?.map(({ FolioNumberFrom, PointOfIssueCode, ItemDescription, AliasName, SerialNumbers, }) => {
        const { U_Chasis = '' } = SerialNumbers[0]
        return <tr key={PointOfIssueCode + '-' + `${FolioNumberFrom}`.padStart(8, "0")}>
            <td className="text-nowrap text-center">{PointOfIssueCode + '-' + `${FolioNumberFrom}`.padStart(8, "0")}</td>
            <td className="text-nowrap px-0 text-truncate" title={ItemDescription}>{ItemDescription}</td>
            <td className="text-center">{U_Chasis}</td>
            <td className="text-center text-truncate" title={AliasName ?? ''} >{AliasName ?? ''}</td>
            <td className="text-center"><i className="fas fa-edit" role="button" onClick={() => {
                // setChasisForSearchServices(U_Chasis)
                history.push(`/servicio/${businessData.CustomerCode ?? businessData.CardCode}/${U_Chasis}/`, { prevPage: history.location.pathname })
            }}></i></td>
        </tr>
    })

    const columns = ["N° Remito", "Descripción", "N° Chasis", "Sucursal de Venta", "Acción"].map(col => <th className="text-center text-nowrap" key={col}>{col}</th>)

    return (
        <div className="my-3 w-100">
            {
                rows.length > 0 ?
                    <TableComponent columns={columns}>
                        {rows}
                    </TableComponent>
                    :
                    <div className="text-center mt-3">
                        No existen datos que mostrar
                    </div>
            }
        </div>
    )
}

export default TableOfBikes