import React, { useContext } from 'react'
import { ContextService } from '../../../../../contexts/serviceGralContext'

const BPBillToAddressInput = ({ editHandler }) => {

    const { businessData = [] } = useContext(ContextService)
    const { BPAddresses = [] } = businessData.length > 0 ? businessData[0] : []

    return (
        <div className="my-4">
            <div className="row mx-0 justify-content-between">
                <div className="input-group input-group-sm mb-1">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id=""><small>Destino de Factura</small></span>
                    </div>
                    <textarea className="form-control form-control-sm" rows="2" name="BPBillToAddress"
                        onChange={editHandler}
                        title={(businessData.length > 0 && BPAddresses.length > 0) ? businessData[0].BPAddresses[0].Street + '\r' + BPAddresses[0].City : ''}
                        disabled={businessData.length > 0 ? false : true}
                        defaultValue={(businessData.length > 0 && BPAddresses.length > 0) ? businessData[0].BPAddresses[0].Street + '\r' + BPAddresses[0].City : ''}>
                    </textarea>
                </div>
            </div>
        </div>
    )
}

export default React.memo(BPBillToAddressInput)