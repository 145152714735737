import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function ResolutionInput() {

    const { currentService = {}, dinamicDataService = {}, editDinamicServiceDataHandler } = useContext(ContextService)

    const params = useParams()
    const history = useHistory()

    const isServiceInterno = history?.location?.pathname?.includes('servicio-interno')
    const isNewService = !(!!params?.serviceID)

    const value = (!!dinamicDataService?.ClosingDate === false || !!params?.serviceID === false) ? dinamicDataService?.Resolution : currentService?.Resolution

    return (
        <InputsElements
            label="Resolución"
            name="Resolution"
            value={value ?? ''}
            functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
            inputState={!!dinamicDataService?.ClosingDate ? !!dinamicDataService?.ClosingDate : !!params?.serviceID ? dinamicDataService?.Status != "-1" : (isNewService || isServiceInterno) && dinamicDataService?.Status != "-1"}
        />
    )
}

export default ResolutionInput