import React, { useContext } from 'react'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function SubjectInput() {

    const { currentService = {}, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <InputsElements
            label="Asunto"
            name="Subject"
            value={!!dinamicDataService?.ClosingDate ? currentService?.Subject : dinamicDataService?.Subject ?? ''}
            functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
            inputState={!!dinamicDataService?.ClosingDate}
        />
    )
}

export default SubjectInput