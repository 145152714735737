import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useGetBusinessHandler } from '../../hooks/useGetBusinessHandler'
import Swal from 'sweetalert2'
import utils from '../../utils/utils'
import AddBusiness from '../AddBusiness'

const BusinessInput = () => {

    const history = useHistory()
    const params = useParams()

    const inputRef = useRef(null)

    const [dniUser, setDniUser] = useState('')

    const dni = !!params?.dni ? params?.dni : !!window.localStorage.getItem('lastDNI') ? window.localStorage.getItem('lastDNI') : ''

    useGetBusinessHandler(dni)

    const handlerSumbit = (e) => {
        e.preventDefault()
        if (!utils.validateDNI(dniUser)) {
            Swal.fire({
                toast: true,
                position: 'top-end',
                text: `El ${/^[0-9.-]{9,10}$/.test(dniUser) ? 'DNI' : 'CUIL/CUIT'} no debe contener puntos ni guiones`,
                icon: 'error',
                showConfirmButton: false,
                timer: 2000,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                },
            })
            return
        }
        const indexBars = history.location.pathname.lastIndexOf("/")
        history.replace(history.location.pathname.slice(0, (indexBars + 1)))
        setTimeout(() => {
            history.replace(`${dniUser}`)
        }, 100)
        localStorage.setItem('lastDNI', dniUser)
    }

    useEffect(() => {
        setDniUser(!!localStorage.getItem('lastDNI') ? localStorage.getItem('lastDNI').trim() : '')
        setTimeout(() => {
            const len = inputRef?.current?.value?.length
            if (inputRef?.current?.setSelectionRange) {
                inputRef?.current?.focus()
                inputRef?.current?.setSelectionRange(len, len)
            } else if (inputRef?.current?.createTextRange) {
                var t = inputRef?.current?.createTextRange()
                t.collapse(true)
                t.moveEnd('character', len)
                t.moveStart('character', len)
                t.select()
            }
        }, 100)
    }, [])

    useEffect(() => {
        if (!!dniUser && !!params?.dni === false) {
            history.replace(dniUser)
        }
    }, [dniUser])

    return (
        <>
            <form className="col-12 col-md-4 col-lg-2" onSubmit={handlerSumbit}>
                <input type="search"
                    className="form-control form-control-sm text-center mt-4 mt-md-0"
                    minLength="7"
                    maxLength="13"
                    name="dni"
                    placeholder="CUIL/CUIT o DNI"
                    title="El n° CUIL/CUIT o DNI no debe contener puntos ni guiones. Ej: 99999999"
                    ref={inputRef}
                    onChange={e => setDniUser(e.target.value)} defaultValue={dniUser}
                    required
                />
            </form>
            <AddBusiness />
        </>
    )
}

export default React.memo(BusinessInput)