import React from 'react'
import EmployeesInfo from './EmployeesInfo'
import UserLogged from '../../../../UserLogged'
import ProblemType from './ProblemType'
import SubProblemType from './SubProblemType'
import TypeOperation from './TypeOperation'
import Origins from './Origins'
import ObsVehicle from './ObsVehicle'

const General = () => {

    return (
        <div className="tab-pane fade show active" id="nav-General" role="tabpanel" aria-labelledby="nav-General-tab">
            <div className="row justify-content-between mx-0 p-2">
                <div className="col-12 col-md-6">
                    <Origins />
                    <ProblemType />
                    <SubProblemType />
                    <TypeOperation />
                    <EmployeesInfo />
                </div>
                <div className="col-12 col-md-6 align-self-center">
                    <UserLogged />
                    <ObsVehicle />
                </div>
            </div>
        </div>
    )
}

export default React.memo(General)