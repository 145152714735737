import React, { useContext } from 'react'
import { ContextService } from '../../../../../contexts/serviceGralContext'
import InputsElements from '../../../../../elements/InputsElements'

const BPEmailInput = ({ editHandler, servicio }) => {

    const { businessData = [] } = useContext(ContextService)

    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <InputsElements
            label="Correo Electrónico"
            name="BPeMail"
            value={(businessData.length > 0 && !!businessData[0].EmailAddress) ? businessData[0].EmailAddress : !!servicio && !!servicio.BPeMail ? servicio.BPeMail : ''}
            inputState={currentService && ((currentService.Status == "-1") &&
                !!currentService.ServiceCallID && !!currentService.ClosingDate)}
            functEdit={e => editHandler(e)}
        />
    )
}

export default React.memo(BPEmailInput)