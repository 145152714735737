import Notiflix from "notiflix";
import SweetAlert from "../../../../elements/SweetAlert";
import clientAxios from "../../../../utils/clientAxios";
import utils from "../../../../utils/utils";
import { changeCellular } from '../../services/changeCellular';
import { postActivity } from "./postActivity";
import { postInventoryExit } from "./postInventoryExit";
import { alertServices } from "../../../../utils/alertMessages.js";


export const sendService = async ({
  


  servicio = {},
  inventoryExit = [],
  currentServiceSelectCombo = {},
}) => {
  const prevPage = window.localStorage.getItem("prevPage");
  const validateMotorBikesService = utils.validationMotorBikesService(
    servicio,
    prevPage
  );
  if (validateMotorBikesService) {
    
    SweetAlert({
      icon: "error",
      title: validateMotorBikesService,
      text: alertServices?.default,
      showConfirmButton: true,
      showCancelButton: false,
    });
    return;
  }
  if (prevPage !== "interno") {
    const validatePhone = utils.validatePhone(servicio.BPCellular);
    if (!validatePhone) {
      SweetAlert({
        icon: "error",
        title: alertServices?.invalidPhoneNumber?.title,
        text: alertServices?.invalidPhoneNumber?.text,
        showConfirmButton: true,
        showCancelButton: false,
      });
      return;
    }
  }
  if (servicio?.Status == -1 && !!servicio?.Resolution === false) {
    SweetAlert({
      icon: "error",
      title: alertServices?.resolution,
      text: alertServices?.default,
      showConfirmButton: true,
      showCancelButton: false,
    });
    return;
  }
  if (!!servicio?.Subject === false) {
    SweetAlert({
      icon: "error",
      title: alertServices?.asunto,
      text: alertServices?.default,
      showConfirmButton: true,
      showCancelButton: false,
    });
    return;
  }

  Notiflix.Loading.dots(
    `${
      "ServiceCallID" in servicio && !!servicio?.ServiceCallID
        ? "Editando"
        : "Cargando"
    } Llamada de Servicio`
  );

  try {
    // chefk if new Service
    if (!("ServiceCallID" in servicio && !!servicio?.ServiceCallID)) {
      if (inventoryExit?.DocumentLines?.length > 0) {

        const resultSendService = await clientAxios.post(
          "/api/v1/ServiceCalls",
          {
            ...servicio,
            ServiceBPType:
              prevPage === "interno" ? "srvcPurchasing" : undefined,
          }
        );
        const newServiceID = resultSendService.data?.ServiceCallID;
        const resultPostInventory = await postInventoryExit({ inventoryExit });

        if (!!resultPostInventory.status && !!resultPostInventory.message) {
          
          const misCookies = document.cookie
          const listaCookies = misCookies.split(";")   
          const userName = []
          for (let index = 0; index < listaCookies.length; index++) {
            let element = listaCookies[index];
            if(element.includes('userName'))
            userName.push(element)
          }
          const encodedParams = new URLSearchParams();
encodedParams.append("service_id", "service_w2tgb2c");
encodedParams.append("template_id", "llamada_servicios");
encodedParams.append("user_id", "FKZpt6sqa9siseiem");
encodedParams.append("accessToken", "rB1BMc6F4AxnK0E4wmeQh");
encodedParams.append("from_name", "Llamada de Servicio");
encodedParams.append("MENSAJE_ERROR", resultPostInventory.message);
encodedParams.append("SERVICE_ID", servicio.ServiceCallID);
encodedParams.append("USERNAME", userName);





const options = {
  method: 'POST',
  url: 'https://emailjs.p.rapidapi.com/api/v1.0/email/send-form',
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
    'X-RapidAPI-Host': 'emailjs.p.rapidapi.com',
    'X-RapidAPI-Key': '707ff6f34fmshd5dcfcff0c47679p145183jsnfd698459f86a'
  },
  data: encodedParams
};
clientAxios.request(options).then(function (response) {
	console.log(response.data);
}).catch(function (error) {
	console.error(error);
});

          clientAxios.request(options).then(function (response) {
            console.log(response.data);
            // alert("EMAIL ENVIADO")
            console.log(resultPostInventory.message)
          }).catch(function (error) {
            console.error(error);
            alert("FALLO ENVIO DE MAIL")

          });


          SweetAlert({
            html: `<p className="text-center">Error al intentar cargar la salida de Mercancía</p><br /> Error: ${
              resultPostInventory.code.code
            }, Descripción: ${
              resultPostInventory.message.includes(
                "Quantity falls into negative inventory  [DocumentLines.ItemCode][line: 1]"
              )
                ? "La cantidad de stock del producto seleccionado es menor a la cantidad que intenta retirar"
                : resultPostInventory.message
            }`,
            icon: "error",
            showConfirmButton: true,
          });
          return;
        }
        const resultPostActivity = await postActivity({
          DocEntry: resultPostInventory,
          CardCode: servicio.CustomerCode,
        });
        const ActivityCode = resultPostActivity;
        if (!!resultPostActivity.status && !!resultPostActivity.message) {
          const misCookies = document.cookie
          const listaCookies = misCookies.split(";")   
          const userName = []
          for (let index = 0; index < listaCookies.length; index++) {
            let element = listaCookies[index];
            if(element.includes('userName'))
            userName.push(element)
          }
          const encodedParams = new URLSearchParams();
          
          encodedParams.append("service_id", "service_w2tgb2c");
          encodedParams.append("template_id", "llamada_servicios");
          encodedParams.append("user_id", "FKZpt6sqa9siseiem");
          encodedParams.append("accessToken", "rB1BMc6F4AxnK0E4wmeQh");
          encodedParams.append("from_name", "Llamada de Servicio");
          encodedParams.append("MENSAJE_ERROR", resultPostInventory.message);
          encodedParams.append("SERVICE_ID", servicio.ServiceCallID);
          encodedParams.append("USERNAME", userName);

          
          
          const options = {
            method: 'POST',
            url: 'https://emailjs.p.rapidapi.com/api/v1.0/email/send-form',
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
              'X-RapidAPI-Host': 'emailjs.p.rapidapi.com',
              'X-RapidAPI-Key': '707ff6f34fmshd5dcfcff0c47679p145183jsnfd698459f86a'
            },
            data: encodedParams
          };
                    clientAxios.request(options).then(function (response) {
                      console.log(response.data);
                      // alert("EMAIL ENVIADO")
                      console.log(resultPostInventory.message)
          
                    }).catch(function (error) {
                      console.error(error);
                      alert("FALLO ENVIO DE MAIL")
          
                    });
          SweetAlert({
            html: `<p className="text-center">Error al intentar cargar una nueva Actividad</p><br /> Error: ${resultPostActivity?.code?.code}, Descripción: ${resultPostActivity?.message}`,
            icon: "error",
            showConfirmButton: true,
          });
          return;
        }
        const resultEditServiceAftherSaveInventoryExit =
          await clientAxios.patch("/api/v1/ServiceCalls", {
            ...servicio,
            ServiceCallID: newServiceID,
            ServiceBPType:
              prevPage === "interno" ? "srvcPurchasing" : undefined,
            ActivityCode: ActivityCode,
          });
      
        return  resultEditServiceAftherSaveInventoryExit.status;
      } else {
        const resultSendService = await clientAxios.post(
          "/api/v1/ServiceCalls",
          {
            ...servicio,
            ServiceBPType:
              prevPage === "interno" ? "srvcPurchasing" : undefined,
          }
        );
        Notiflix.Loading.remove();
        changeCellular({ Cellular: servicio.BPCellular, CardCode: servicio.CustomerCode })
        return resultSendService.status;
      }
    } else {
      // is not a new Service
      if (inventoryExit?.DocumentLines?.length > 0) {
        const resultPostInventory = await postInventoryExit({ inventoryExit });
            if (!!resultPostInventory.status && !!resultPostInventory.message) {
              const misCookies = document.cookie
              const listaCookies = misCookies.split(";")   
              const userName = []
              for (let index = 0; index < listaCookies.length; index++) {
                let element = listaCookies[index];
                if(element.includes('userName'))
                userName.push(element)
              }
          const encodedParams = new URLSearchParams();
encodedParams.append("service_id", "service_w2tgb2c");
encodedParams.append("template_id", "llamada_servicios");
encodedParams.append("user_id", "FKZpt6sqa9siseiem");
encodedParams.append("accessToken", "rB1BMc6F4AxnK0E4wmeQh");
encodedParams.append("from_name", "Llamada de Servicio");
encodedParams.append("MENSAJE_ERROR", resultPostInventory.message);
encodedParams.append("SERVICE_ID", servicio.ServiceCallID);
encodedParams.append("USERNAME", userName);




const options = {
  method: 'POST',
  url: 'https://emailjs.p.rapidapi.com/api/v1.0/email/send-form',
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
    'X-RapidAPI-Host': 'emailjs.p.rapidapi.com',
    'X-RapidAPI-Key': '707ff6f34fmshd5dcfcff0c47679p145183jsnfd698459f86a'
  },
  data: encodedParams
};
          clientAxios.request(options).then(function (response) {
            console.log(response.data);
            // alert("EMAIL ENVIADO")
            
            console.log(resultPostInventory.message)

          }).catch(function (error) {
            console.error(error);
            alert("FALLO ENVIO DE MAIL")

          });

          SweetAlert({
            html: `<p className="text-center">Error al intentar cargar la salida de Mercancía</p><br /> Error: ${
              resultPostInventory.code.code
            }, Descripción: ${
              resultPostInventory.message.includes(
                "Quantity falls into negative inventory  [DocumentLines.ItemCode][line: 1]"
              )
                ? "La cantidad de stock del producto seleccionado es menor a la cantidad que intenta retirar"
                : resultPostInventory.message
            }`,
            icon: "error",
            showConfirmButton: true,
          });
          return;
        }
        const resultPostActivity = await postActivity({
          DocEntry: resultPostInventory,
          CardCode: servicio.CustomerCode,
        });
        const ActivityCode = resultPostActivity;
        if (!!resultPostActivity.status && !!resultPostActivity.message) {
          const misCookies = document.cookie
          const listaCookies = misCookies.split(";")   
          const userName = []
          for (let index = 0; index < listaCookies.length; index++) {
            let element = listaCookies[index];
            if(element.includes('userName'))
            userName.push(element)
          }
          const encodedParams = new URLSearchParams();
          encodedParams.append("service_id", "service_w2tgb2c");
          encodedParams.append("template_id", "llamada_servicios");
          encodedParams.append("user_id", "FKZpt6sqa9siseiem");
          encodedParams.append("accessToken", "rB1BMc6F4AxnK0E4wmeQh");
          encodedParams.append("from_name", "Llamada de Servicio");
          encodedParams.append("MENSAJE_ERROR", resultPostInventory.message);
          encodedParams.append("SERVICE_ID", servicio.ServiceCallID);
          encodedParams.append("USERNAME", userName);

          
          
          const options = {
            method: 'POST',
            url: 'https://emailjs.p.rapidapi.com/api/v1.0/email/send-form',
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
              'X-RapidAPI-Host': 'emailjs.p.rapidapi.com',
              'X-RapidAPI-Key': '707ff6f34fmshd5dcfcff0c47679p145183jsnfd698459f86a'
            },
            data: encodedParams
          };
                    clientAxios.request(options).then(function (response) {
                      console.log(response.data);
                      // alert("EMAIL ENVIADO")
                      console.log(resultPostInventory.message)
          
                    }).catch(function (error) {
                      console.error(error);
                      alert("FALLO ENVIO DE MAIL")
          
                    });
          SweetAlert({
            html: `<p className="text-center">Error al intentar cargar una nueva Actividad</p><br /> Error: ${resultPostActivity?.code?.code}, Descripción: ${resultPostActivity?.message}`,
            icon: "error",
            showConfirmButton: true,
          });
        } else {
          const resultPostEntrega = await clientAxios.post(
            "/api/v1/PostEntregaCS",
            { ...currentServiceSelectCombo }
          );

          const DocEntry = resultPostEntrega?.data?.DocEntry;
          const DocumentNumber = resultPostEntrega?.data?.DocNum;

          servicio.ServiceCallInventoryExpenses = [
            {
              LineNum: 0,
              PartType: "sep_NonInventory",
              DocumentType: "edt_Delivery",
              DocumentNumber: DocumentNumber,
              DocEntry: DocEntry,
            },
          ];
        }

        const resultSendService = await clientAxios.patch(
          "/api/v1/ServiceCalls",
          {
            ...servicio,
            ServiceBPType:
              prevPage === "interno" ? "srvcPurchasing" : undefined,
            ActivityCode: ActivityCode,
          }
        );

        Notiflix.Loading.remove();
        return resultSendService.status;
      } else {
        const resultSendService = await clientAxios.patch(
          "/api/v1/ServiceCalls",
          {
            ...servicio,
            ServiceBPType:
              prevPage === "interno" ? "srvcPurchasing" : undefined,
          }
        );
        changeCellular({ Cellular: servicio.BPCellular, CardCode: servicio.CustomerCode })


        Notiflix.Loading.remove();
        return resultSendService.status;
      }
    }
  } catch (error) {
    const misCookies = document.cookie
    const listaCookies = misCookies.split(";")   
    const userName = []
    for (let index = 0; index < listaCookies.length; index++) {
      let element = listaCookies[index];
      if(element.includes('userName'))
      userName.push(element)
    }
    const encodedParams = new URLSearchParams();
    encodedParams.append("service_id", "service_w2tgb2c");
    encodedParams.append("template_id", "llamada_servicios");
    encodedParams.append("user_id", "FKZpt6sqa9siseiem");
    encodedParams.append("accessToken", "rB1BMc6F4AxnK0E4wmeQh");
    encodedParams.append("from_name", "Llamada de Servicio");
    encodedParams.append("MENSAJE_ERROR", error.response.data.code.message.value);
    encodedParams.append("SERVICE_ID", servicio.ServiceCallID);
    encodedParams.append("USERNAME", userName);

    
    
    const options = {
      method: 'POST',
      url: 'https://emailjs.p.rapidapi.com/api/v1.0/email/send-form',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'X-RapidAPI-Host': 'emailjs.p.rapidapi.com',
        'X-RapidAPI-Key': '707ff6f34fmshd5dcfcff0c47679p145183jsnfd698459f86a'
      },
      data: encodedParams
    };
              clientAxios.request(options).then(function (response) {
                console.log(response.data);
                // alert("EMAIL ENVIADO")
                console.log(error.response.data.code.message.value)
    
              }).catch(function (error) {
                console.error(error);
                alert("FALLO ENVIO DE MAIL")
    
              });
    console.log(error && !!error.response ? error.response : error);
    Notiflix.Loading.remove();
    return {
      status: error.response.status,
      message: error.response.data.code.message.value,
      code: error.response.data.code,
    };
  }
};
