import React, { useCallback, useState } from 'react'
import ModalCentered from '../../elements/ModalCentered'
import InputsElements from '../../elements/InputsElements'
import { changePassService } from './changePassService';
import SweetAlert from '../../elements/SweetAlert';
import { useCookies } from 'react-cookie';

const ChangePassword = () => {

    const [cookies] = useCookies()
    const [newPass, setNewPass] = useState({})

    const handlerChangePass = useCallback((e) => {
        e.preventDefault()
        if (newPass.UserPassword !== newPass.RepeatUserPassword) {
            SweetAlert({ icon: 'error', title: 'Las contraseñas no coinciden', showConfirmButton: true })
            return
        }
        if (!!newPass.UserPassword === false || !!newPass.RepeatUserPassword === false) {

            SweetAlert({
                icon: 'error',
                title: `${!!newPass.UserPassword === false ?
                    'El campo Contraseña no puede estar vacio'
                    :
                    'El campo Repetir Contraseña no puede estar vacio'
                    }`,
                showConfirmButton: true
            })
            return
        }
        changePassService({ newPassword: newPass.UserPassword, userID: cookies.user })
    }, [changePassService, newPass])

    return (
        <ModalCentered
            id="changePass"
            title="Cambiar Contraseña"
            confirmText="Enviar"
            funct={(e) => handlerChangePass(e)}
        >
            <form onSubmit={handlerChangePass}>
                <InputsElements
                    label="Contraseña Nueva"
                    name="UserPassword"
                    value=""
                    typeInput="password"
                    maxLimit={4}
                    minLimit={4}
                    functEdit={(e) => setNewPass({ ...newPass, [e.target.name]: e.target.value })}
                />
                <InputsElements
                    label="Repetir Contraseña"
                    name="RepeatUserPassword"
                    value=""
                    typeInput="password"
                    maxLimit={4}
                    minLimit={4}
                    functEdit={(e) => setNewPass({ ...newPass, [e.target.name]: e.target.value })}
                />
                <input type="submit" hidden></input>
            </form>
        </ModalCentered>
    );
}

export default React.memo(ChangePassword)