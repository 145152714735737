import React, { useContext } from 'react'
import { ContextService } from '../../../../../contexts/serviceGralContext'

const Programation = () => {

    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    let diffDays
    let diffHrs
    let diffMins
    if (currentService) {
        const diffMs = ((currentService && new Date(`${currentService.ClosingDate + ' ' + currentService.ClosingTime}`)) - (currentService && new Date(`${currentService.CreationDate + ' ' + currentService.CreationTime}`)))
        diffDays = Math.floor(diffMs / 86400000) // days
        diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
        diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) // minutes
    }

    return (
        <div className="tab-pane fade" id="nav-Programacion" role="tabpanel" aria-labelledby="nav-Programacion-tab">
            <div className="row justify-content-center mx-0 p-2">
                {
                    !!currentService ?
                        <>
                            <div className="mx-1">
                                <p className="mb-0"><strong>Fecha Inicio</strong> {(currentService.CreationDate) && new Date(currentService.CreationDate.replaceAll("-", "/")).toLocaleString("default", { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
                                <p><strong>Hora Inicio</strong> {(currentService.CreationTime) && currentService.CreationTime.replace(":00", "")}</p>
                                {
                                    currentService.ClosingDate &&
                                    <>
                                        <p className="mb-0"><strong>Fecha Fin</strong> {(currentService.ClosingDate) && new Date(currentService.ClosingDate.replaceAll("-", "/")).toLocaleString("default", { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
                                        <p ><strong>Hora Final</strong> {(currentService.ClosingTime) && currentService.ClosingTime.replace(":00", "")}</p>
                                    </>
                                }
                            </div>
                            <div className="mx-5">
                                {
                                    (currentService.ClosingDate) ?
                                        <>
                                            <p className="mb-0"><strong>Duración Día/s: </strong>{diffDays}</p>
                                            <p className="mb-0"><strong>Duración Hora/s: </strong>{diffHrs}</p>
                                            <p><strong>Duración Minuto/s: </strong>{diffMins}</p>
                                        </>
                                        :
                                        <p>La llamada aún no está Cerrada</p>
                                }
                            </div>
                        </>
                        :
                        <p className="text-center">Debés seleccionar un service para ver la duración</p>
                }
            </div>
        </div>
    )
}

export default React.memo(Programation)