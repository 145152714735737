import { useEffect, useState } from "react"
import { hashByPrevPage } from '../utils/hashByPrevPage'

export function useGetPrevPage({ prevPage = "" }) {
    const [previousPage, setPreviousPage] = useState({})
    useEffect(() => {
        if (!!prevPage) {
            const page = prevPage
            const prevPageCleaned = page.split("/")[1]
            setPreviousPage({ prevPage: prevPageCleaned, condition: hashByPrevPage[prevPageCleaned] })
            localStorage.setItem("prevPage", prevPageCleaned)
            localStorage.setItem("condition", hashByPrevPage[prevPageCleaned])
        }
    }, [prevPage])

    return { previousPage }
}