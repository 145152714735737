import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function InternalSerialNumbrer() {

    const { currentVehiclesData = {} } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return <InputsElements
        label="N° de Serie de Fabricante"
        name="InternalSerialNum"
        value={!!currentVehiclesData.InternalSerialNum ? currentVehiclesData.InternalSerialNum : currentService?.InternalSerialNum ? currentService.InternalSerialNum : ''}
        inputState={true}
    />
}

export default React.memo(InternalSerialNumbrer)