import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { BusinessContext } from '../../contexts/businessContext'
import { getVehiclesInStock } from './services/getVehiclesInStock'

const VehicleInput = () => {

    const [cookies] = useCookies()
    const inputRef = useRef(null)
    const [chasis, setChasis] = useState('')
    const { setInternalVehicles, clearBusinessData } = useContext(BusinessContext)

    const getVehiclesHandler = useCallback(async (e) => {
        e.preventDefault()
        const { vehicles = [], error, status, response } = await getVehiclesInStock({ WareHouse: cookies.WareHouse, Chasis: chasis })
        setInternalVehicles(vehicles)
        localStorage.setItem('lastChasis', chasis)
    }, [getVehiclesInStock, chasis])

    const handerChange = e => {
        setChasis(e.target.value)
    }

    useEffect(() => {
        setChasis(!!localStorage.getItem('lastChasis') ? localStorage.getItem('lastChasis').trim() : '')
        setTimeout(() => {
            const len = inputRef?.current?.value?.length
            if (inputRef?.current?.setSelectionRange) {
                inputRef?.current?.focus()
                inputRef?.current?.setSelectionRange(len, len)
            } else if (inputRef?.current?.createTextRange) {
                var t = inputRef?.current?.createTextRange()
                t.collapse(true)
                t.moveEnd('character', len)
                t.moveStart('character', len)
                t.select()
            }
        }, 100)
        clearBusinessData()
    }, [])

    return (
        <form className="col-12 col-md-4 col-lg-3" onSubmit={getVehiclesHandler}>
            <input
                ref={inputRef}
                type="search"
                className="form-control form-control-sm text-center mt-4 mt-md-0"
                minLength="5"
                maxLength="20"
                name="dni"
                placeholder="N° Chasis Vehículo"
                required
                title="El N° de Chasis del Vehículo es requerido"
                value={chasis}
                onChange={e => handerChange(e)}
            />
        </form>
    )
}

export default React.memo(VehicleInput)