import React, { useCallback } from 'react'
import ShowService from '../components/motorbikes/FormService'
import { useControllersService } from '../components/motorbikes/hooks/useControllersService'
import { actionsServices } from '../components/motorbikes/actionsServices'

const ServiceBuyTypePage = () => {

    const reducer = (state, action) => {
        switch (action.type) {
            case actionsServices.UPDATE_INTERNALSERVICEDATA:
                return {
                    ...state,
                    servicioInterno: { ...state.servicioInterno, [action.name]: action.payLoad }
                }
            case actionsServices.REPLACE_INTERNALSERVICEDATA:
                return {
                    ...state,
                    servicioInterno: { ...state.servicioInterno, [action.name]: action.payLoad }
                }
            case actionsServices.UPDATE_BUSINESSDATA:
                return {
                    ...state,
                    [action.name]: action.payLoad
                }
            case actionsServices.UPDATE_INVENTORYEXIT:
                return {
                    ...state,
                    [action.name]: [...state.inventoryExit, action.payLoad]
                }
            case actionsServices.REMOVE_ALL_ITEMS:
                return {
                    ...state,
                    [action.name]: []
                }
            case actionsServices.REPLACE_INVENTORYEXIT:
                return {
                    ...state,
                    [action.name]: action.payLoad
                }
            case actionsServices.REMOVE_INVENTORYEXIT:
                return {
                    ...state,
                    [action.name]: action.payLoad
                }
            case actionsServices.UPDATE_ORIGININPUT:
                return {
                    ...state,
                    filterState: { ...state.filterState, originSelect: action.payLoad }
                }
            case actionsServices.UPDATE_PROBLEMTYPE:
                return {
                    ...state,
                    filterState: { ...state.filterState, problemTypeSelect: action.payLoad }
                }
            case actionsServices.UPDATE_PROBLEMSUBTYPE:
                return {
                    ...state,
                    filterState: { ...state.filterState, problemsSubTypeSelect: action.payLoad }

                }
            default:
                return state
        }
    }

    const { servicioInterno, inventoryExit, filterState, dispatch } = useControllersService({ reducer })

    const editHandler = useCallback((e) => {
        dispatch({ type: actionsServices.UPDATE_INTERNALSERVICEDATA, payLoad: e.target.value, name: e.target.name })
    }, [dispatch])

    const replacePropertyHandler = useCallback(({ name, value }) => {
        dispatch({ type: actionsServices.REPLACE_INTERNALSERVICEDATA, payLoad: value, name: name })
    }, [dispatch])

    const removeItemInventory = useCallback((array) => {
        const newArray = Array.isArray(array) ? array : []
        dispatch({ type: actionsServices.REMOVE_INVENTORYEXIT, payLoad: newArray, name: 'inventoryExit' })
    }, [dispatch])

    const editInventoryOutPut = useCallback((newItem) => {
        dispatch({ type: actionsServices.UPDATE_INVENTORYEXIT, payLoad: newItem, name: 'inventoryExit' })
    }, [dispatch])

    const removeAllItems = useCallback(() => {
        dispatch({ type: actionsServices.REMOVE_ALL_ITEMS, name: 'inventoryExit' })
    }, [dispatch])

    const editStatesToFilter = useCallback((e) => {
        if (!!e.current) {
            if (e.current.name === 'Origin') {
                dispatch({ type: actionsServices.UPDATE_ORIGININPUT, payLoad: (e.current.selectedOptions && e.current.selectedOptions && e.current.selectedOptions.length > 0) ? e.current.selectedOptions[0].innerText : '', name: e.current.name })
            } else if (e.current.name === 'ProblemType') {
                dispatch({ type: actionsServices.UPDATE_PROBLEMTYPE, payLoad: (e.current.selectedOptions && e.current.selectedOptions && e.current.selectedOptions.length > 0) ? e.current.selectedOptions[0].innerText : '', name: e.current.name })
            } else if (e.current.name === 'ProblemSubType') {
                dispatch({ type: actionsServices.UPDATE_PROBLEMSUBTYPE, payLoad: (e.current.selectedOptions && e.current.selectedOptions && e.current.selectedOptions.length > 0) ? e.current.selectedOptions[0].innerText : '', name: e.current.name })
            }
        }
    }, [dispatch])

    return (
        <div className="container mt-5">
            <ShowService
                editHandler={editHandler}
                replacePropertyHandler={replacePropertyHandler}
                editInventoryOutPut={editInventoryOutPut}
                removeItemInventory={removeItemInventory}
                removeAllItems={removeAllItems}
                editStatesToFilter={editStatesToFilter}
                inventoryExit={inventoryExit}
                servicio={servicioInterno}
                filterState={filterState}
            />
        </div>
    )
}

export default React.memo(ServiceBuyTypePage)