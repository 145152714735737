import React, { useContext,useState } from 'react'
import { useParams } from 'react-router'
import { ContextService } from '../../../../contexts/serviceGralContext'
import SelectInput from '../../../../elements/SelectInput'
import ModalStatus from './ModalStatus'

;function StatusSelectInput() {
    const { currentService = {}, dinamicDataService = {}, editDinamicServiceDataHandler } = useContext(ContextService)

    const params = useParams()

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const value = (!!dinamicDataService?.ClosingDate || !!params?.serviceID === false) ? dinamicDataService?.Status : currentService?.Status




if(currentService?.Status?.toString() == "-2" ||currentService?.Status?.toString() == "-3"  && dinamicDataService?.Status?.toString() === "-1" && (dinamicDataService?.ProblemType?.toString() === "37" || dinamicDataService?.ProblemType?.toString() === "34" )) {
}

    return (
        <>
        {
            currentService?.Status?.toString() == "-2" ||currentService?.Status?.toString() == "-3"  && dinamicDataService?.Status?.toString() === "-1" && (dinamicDataService?.ProblemType?.toString() === "37" || dinamicDataService?.ProblemType?.toString() === "34" ) ?
            <ModalStatus  show={setShow}/> : ''
        }
 

            <SelectInput
                label="Estatus de Llamada"
                name="Status"
                value={value ?? ""}
                inputState={!!dinamicDataService?.ClosingDate}
                functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })
                }
            >
                <option hidden selected>Elegir Estado...</option>
                <option selected={value == "-3"} value="-3">Abierto</option>
                <option selected={value == "-1"} value="-1">Cerrada</option>
                <option selected={value == "-2"} value="-2">Pendiente</option>
            </SelectInput>

        </>
    )
}

export default StatusSelectInput