import { useEffect, useState } from 'react'
import { getProductOutput } from '../components/motorbikes/services/getProductOutput'

export const useGetProductOutput = ({ currentService = {} }) => {

    const [productOutput, setProductOutput] = useState({
        loading: false,
        items: []
    })

    const { items = [], loading } = productOutput

    useEffect(() => {
        if ((Object.keys(currentService).length > 0 && currentService?.ServiceCallActivities?.length > 0)) {
            setProductOutput({ loading: true, items: [] });
            (async () => {
                try {
                    const result = await getProductOutput({
                        ActivityCodes: !!currentService.ServiceCallActivities ? currentService.ServiceCallActivities : null
                    })
                    setProductOutput({ items: result, loading: false })
                } catch (error) {
                    console.log(error)
                    setProductOutput({ loading: false, items: [] })
                }
            })()
        } else {
            setProductOutput({ loading: false, items: [] })
        }
    }, [currentService])

    return { items, loading }
}

