import React from 'react'

const InputsElements = ({ label, name, value, type, functEdit, inputState, onlyNumber, comprovePasteHandler, minLimit = 800, maxLimit = 800, typeInput = 'text' }) => {
    return (
        <div className="input-group input-group-sm mb-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><small>{label}</small></span>
            </div>
            <input type="text"
                className="form-control form-control-sm"
                name={name}
                onKeyPress={e => onlyNumber ? onlyNumber(e) : null}
                onPaste={e => comprovePasteHandler ? comprovePasteHandler(e) : null}
                maxLength={type === "Number" ? 10 : maxLimit}
                minLength={type === "Number" ? 10 : minLimit}
                value={value}
                disabled={inputState}
                type={typeInput}
                onChange={e => functEdit(e)} />
        </div>
    )
}

export default InputsElements