import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ModalSearchBrand from '../../ModalSearchBrand'
import ServicePDF from '../ServicePDF'
import Swal from 'sweetalert2'
import Spinner from '../../Spinner'
import SubjectInput from './inputsServices/SubjectInput'
import CustomerCodeInput from './inputsServices/CustomerCodeInput'
import CustomerNameInput from './inputsServices/CustomerNameInput'
import BPCellularInput from './inputsServices/BPCellularInput'
import InternalSerialNumbrer from './inputsServices/InternalSerialNumbrer'
import ServiceCallIDInput from './inputsServices/ServiceCallIDInput'
import StatusSelectInput from './inputsServices/StatusSelectInput'
import ItemDescriptionInput from './inputsServices/ItemDescriptionInput'
import ItemCodeInput from './inputsServices/ItemCodeInput'
import U_PatenteInput from './inputsServices/U_PatenteInput'
import U_ChasisInput from './inputsServices/U_ChasisInput'
import U_MotorInput from './inputsServices/U_MotorInput'
import PriorityInput from './inputsServices/PriorityInput'
import U_KilometrajeInput from './inputsServices/U_KilometrajeInput'
import DescriptionInput from './inputsServices/DescriptionInput'
import ResolutionInput from './inputsServices/ResolutionInput'
import CreationTimeInput from './inputsServices/CreationTimeInput'
import CloseTimeInput from './inputsServices/CloseTimeInput'
import ButtonActions from './ButtonActions'
import MenuServices from './TabsMenu/MenuServices'
import ModalInventory from './TabsMenu/Operation/ModalInventory'
import TabsMenu from './TabsMenu'
import { useGetProductOutput } from '../../../hooks/useGetProductOutput'
import { useFilterCurrentService } from '../../../hooks/useFilterCurrentService'
import { MotorBikesContext } from '../../../contexts/motorBikesContext'
import { ContextService } from '../../../contexts/serviceGralContext'
import { BusinessContext } from '../../../contexts/businessContext'
import { useCookies } from 'react-cookie'

const ServiceForm = () => {

    const history = useHistory()
    const params = useParams()
    const [cookies] = useCookies([])

    const { servicesCurrentVehicle = [], clearCurrentService, clearServicesCurrentVehicle, stateToFilter = {} } = useContext(MotorBikesContext)
    const { currentService = {}, dinamicDataService = {}, setStaticDataService, setCurrentService, editDinamicServiceDataHandler, clearDinamicDataService, editInventoryExit } = useContext(ContextService)
    const { businessData = {}, currentVehicleData, setCurrentVehicleData } = useContext(BusinessContext)

    useFilterCurrentService({ serviceID: params.serviceID })

    const { items = [], loading } = useGetProductOutput({ currentService })

    const onlyNumber = (charcode) => {
        if (charcode <= 47) return Swal.fire({ icon: 'error', title: 'Solo puede ingresar números' })
        if (charcode >= 58) return Swal.fire({ icon: 'error', title: 'Solo puede ingresar números' })
    }

    const comprovePasteHandler = (e) => {
        isNaN(e.clipboardData.getData('Text')) && Swal.fire({
            icon: 'error', title: 'Solo puede ingresar números',
        }) && e.preventDefault()
    }

    useEffect(() => {
        setStaticDataService({
            CustomerCode: businessData?.CardCode ?? currentService?.CustomerCode ?? "P30623893096",
            CustomerName: businessData?.CardName ?? currentService?.CustomerName ?? "YUHMAK S.A.",
            BPCellular: businessData?.Cellular ?? currentService?.BPCellular,
            BPeMail: businessData?.EmailAddress ?? currentService?.BPeMail,
            BPBillToCode: businessData?.BilltoDefault ?? currentService?.BPBillToCode,
            AssigneeCode: cookies?.user,
            InternalSerialNum: currentVehicleData?.InternalSerialNumber ?? currentService?.InternalSerialNum,
            ItemDescription: currentVehicleData?.ItemDescription ?? currentService?.ItemDescription,
            U_Chasis: currentVehicleData?.U_Chasis ?? currentService?.U_Chasis,
            U_Motor: currentVehicleData?.U_Motor ?? currentService?.U_Motor,
            U_Almacen: cookies?.WareHouse,
            ItemCode: currentVehicleData?.ItemCode ?? currentService?.ItemCode,
            remitoDocEntry: currentVehicleData?.remitoDocEntry
        })
        if (!!params.serviceID) editDinamicServiceDataHandler({
            Status: currentService?.Status,
            Priority: currentService?.Priority,
            U_Kilometraje: currentService?.U_Kilometraje,
            Subject: currentService?.Subject,
            Description: currentService?.Description,
            Resolution: currentService?.Resolution,
            Origin: currentService?.Origin,
            ProblemType: currentService?.ProblemType,
            ProblemSubType: currentService?.ProblemSubType,
            CallType: currentService?.CallType,
            TechnicianCode: currentService?.TechnicianCode,
            U_Alarma: currentService?.U_Alarma,
            U_Casco: currentService?.U_Casco,
            U_Nivel_Combustible: currentService?.U_Nivel_Combustible,
            U_Manchado: currentService?.U_Manchado,
            U_Faltante: currentService?.U_Faltante,
            U_Kit_Herramientas: currentService?.U_Kit_Herramientas,
            U_Rayado: currentService?.U_Rayado,
            U_Rotura: currentService?.U_Rotura,
            U_Marca: currentService?.U_Marca,
            U_Modelo: currentService?.U_Modelo,
            ServiceCallID: currentService?.ServiceCallID,
            ClosingDate: currentService?.ClosingDate,
            ClosingTime: currentService?.ClosingTime,
            CreationDate: currentService?.CreationDate,
            CreationTime: currentService?.CreationTime,
        })
    }, [currentService, businessData, currentVehicleData, servicesCurrentVehicle, params.serviceID])

    useEffect(() => {
        editInventoryExit({ DocumentLines: [] })
    }, [])

    return (
        <div className="container my-5">
            <div className="row mx-0 justify-content-between align-items-center mb-4">
                <div>
                    {
                        (servicesCurrentVehicle.length > 1 && !!params?.serviceID) &&
                        <select className="custom-select custom-select-sm mx-md-2" onChange={(e) => history.replace(`/servicio/${params.clientCode}/${params.chasis}/${servicesCurrentVehicle[e.target.value].ServiceCallID}`, { ...history?.location?.state })}>
                            {
                                servicesCurrentVehicle.map((s, i) => <option key={s.ServiceCallID} selected={params.serviceID == s.ServiceCallID} value={i}>{' N°' + s.ServiceCallID}</option>)
                            }
                        </select>
                    }
                </div>
                {!!params?.serviceID ?
                    Object.keys(currentService).length > 0 && Object.keys(stateToFilter).length !== 3 ?
                        <Spinner color="text-primary" texto="Cargando Orden..." />
                        :
                        Object.keys(currentService).length > 0 &&
                        <ServicePDF items={items} />
                    :
                    null
                }
                <div>
                    <i className="fas fa-times" role="button" onClick={() => {
                        clearServicesCurrentVehicle()
                        clearCurrentService()
                        setCurrentVehicleData({})
                        clearDinamicDataService({})
                        setCurrentService({})
                        history.goBack()
                    }}></i>
                </div>
            </div>
            <form>
                <div className="row justify-content-center w-100 mx-0">
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <CustomerCodeInput />
                        <CustomerNameInput />
                        {
                            !history.location.pathname.includes('/servicio-interno') &&
                            <BPCellularInput onlyNumber={onlyNumber} comprovePasteHandler={comprovePasteHandler} />
                        }
                        {
                            (!!currentService?.U_Modelo && !!currentService?.InternalSerialNum) ?
                                <InternalSerialNumbrer />
                                :
                                null
                        }
                        <U_ChasisInput />
                        {
                            !(history?.location?.state?.prevPage?.includes("bikes") || window.localStorage.getItem("prevPage")?.includes("bikes")) &&
                            <>
                                <U_MotorInput />
                                <U_PatenteInput />
                            </>
                        }
                        {
                            (!!currentService?.ItemCode || !!dinamicDataService?.ItemCode || !!currentVehicleData?.ItemCode) && <ItemCodeInput />
                        }
                        {
                            (!!currentService?.ItemDescription || !!currentService?.U_Modelo || !!dinamicDataService?.U_Modelo || !!currentVehicleData?.ItemDescription) && <ItemDescriptionInput />
                        }
                        {
                            (Object.keys(currentService).length === 0 && (!!dinamicDataService?.U_Modelo && !!dinamicDataService?.ItemDescription) == false && !!currentVehicleData?.ItemCode === false) &&
                            < ModalSearchBrand />
                        }
                    </div>
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <div className="mx-0 pb-1 h-50 pl-md-5">
                            <StatusSelectInput />
                            <ServiceCallIDInput />
                            <PriorityInput />
                        </div>
                        <div className="mx-0 pl-md-5 pt-1 mt-3 mt-md-0 h-50">
                            <CreationTimeInput />
                            <CloseTimeInput />
                            {
                                !(history?.location?.state?.prevPage?.includes("-bikes") || window.localStorage.getItem("prevPage")?.includes("-bikes")) &&
                                <U_KilometrajeInput onlyNumber={onlyNumber} comprovePasteHandler={comprovePasteHandler} />
                            }
                        </div>
                    </div>
                    <div className="col-12 my-3 mb-md-0 ">
                        <SubjectInput />
                        <DescriptionInput />
                        <ResolutionInput />
                    </div>
                    <div className="tab-content mx-md-4 w-100" id="nav-tabContent">
                        <MenuServices />
                        <TabsMenu
                            items={items}
                            loading={loading}
                        />
                    </div>
                </div>
                <ButtonActions />
            </form>
            <ModalInventory />
        </div >
    )
}

export default React.memo(ServiceForm)