import Notiflix from "notiflix"
import clientAxios from "../../../../utils/clientAxios"

export const postInventoryExit = async ({ inventoryExit = {} }) => {
    Notiflix.Loading.dots('Cargando Salida de Mercancía/s')
    try {
        if (inventoryExit?.DocumentLines?.length > 0) {
            const resultPostInventoryExit = await clientAxios.post('/api/v1/InventoryGenExits', inventoryExit)
            // Notiflix.Loading.remove()
            return resultPostInventoryExit.data.DocEntry
        }
    } catch (error) {
        Notiflix.Loading.remove()
        return { status: error.response.status, message: error.response.data.code.message.value, code: error.response.data.code }

    }
}