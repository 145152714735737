import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { ContextService } from '../../../../../contexts/serviceGralContext'
import Table from '../../../../../elements/TableComponent'
import Spinner from '../../../../Spinner'

const Operation = ({ items, loading }) => {

    const { currentService = {}, dinamicDataService = {}, inventoryExit = {}, editInventoryExit } = useContext(ContextService)

    const params = useParams()

    return (
        <div className="tab-pane fade" id="nav-Operaciones" role="tabpanel" aria-labelledby="nav-Operaciones-tab">
            {
                (!!dinamicDataService?.ClosingDate === false && (dinamicDataService?.ProblemSubType != "77" && dinamicDataService?.ProblemSubType != "12")) &&
                <div className="row justify-content-between mx-0 p-2">
                    <button type="button" className="btn-bgSAP mx-1 my-3" data-toggle="modal" data-target="#modalInventory">Cargar Salidas</button>
                </div>
            }
            {
                loading ?
                    <div className="row mx-0 justify-content-center mt-5">
                        <Spinner texto="Cargando Saldidas..." color="text-primary" />
                    </div>
                    :
                    <>
                        <div className="mt-3">
                            {
                                (items.length > 0 && !!params?.serviceID) ?
                                    <>
                                        <p className="text-center"><u>Salida/s de Mercancia/s</u></p>
                                        <Table columns={
                                            <>
                                                <th className="text-nowrap">Num. Salida</th>
                                                <th>Código</th>
                                                <th>Descripción</th>
                                                <th>Cantidad</th>
                                                <th>Unidad</th>
                                                <th>Almacen</th>
                                            </>
                                        }>
                                            {
                                                items?.map((items, i) =>
                                                    <tr key={'output' + items?.DocEntry + i}>
                                                        <td>{items?.DocEntry}</td>
                                                        <td>{items?.ItemCode}</td>
                                                        <td title={items?.ItemDescription ? items?.ItemDescription : ''} className="text-truncate">{items?.ItemDescription && items?.ItemDescription.substring(0, 30)}</td>
                                                        <td>{items?.Quantity}</td>
                                                        <td>{items?.MeasureUnit}</td>
                                                        <td>{items?.WarehouseCode}</td>
                                                    </tr>
                                                )
                                            }
                                        </Table>
                                    </>
                                    :
                                    Object.keys(currentService).length !== 0 &&
                                    <p className="text-center">No existe/n salida/s para el service seleccionado</p>
                            }
                        </div>
                        {
                            (Object.keys(currentService).length === 0 && inventoryExit?.DocumentLines?.length === 0 && items.length === 0) &&
                            <p className="text-center">Debes completar el service para corroborar la salida de Mercancias</p>
                        }
                        {
                            inventoryExit?.DocumentLines?.length > 0 &&
                            <div>
                                <p className="text-center"><u>Item/s a Agregar</u></p>
                                <table className="table border overflow-auto d-block d-md-table">
                                    <thead className="thead-light text-center">
                                        <tr>
                                            <th>Código</th>
                                            <th>Descripción</th>
                                            <th>Cantidad</th>
                                            <th className="text-nowrap">Unidad de Medida</th>
                                            <th>Almacen</th>
                                            <th>Acción</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center">
                                        {
                                            inventoryExit?.DocumentLines?.map((d, i) =>
                                                <tr key={'salida' + d?.ItemCode}>
                                                    <td>{d?.ItemCode}</td>
                                                    <td title={!!d?.ItemName ? d.ItemName : ''} className="text-truncate">{d?.ItemName && d?.ItemName?.substring(0, 30)}</td>
                                                    <td>{Number(d?.Quantity)}</td>
                                                    <td>{d?.MeasureUnit}</td>
                                                    <td>{d?.WarehouseCode}</td>
                                                    <td><p role="button" className="btn btn-outline-secondary py-1 mb-0" onClick={() =>
                                                        editInventoryExit({ ...inventoryExit, DocumentLines: inventoryExit?.DocumentLines?.filter((items, index) => i !== index) })
                                                    }>Eliminar</p></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </>
            }
        </div >
    )
}

export default React.memo(Operation)