import React, { useContext } from 'react'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function SubjectInput() {

    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <>
            {dinamicDataService?.Status != "-1" ?
                <InputsElements
                    label="Asunto"
                    name="Subject"
                    value={dinamicDataService.Subject ?? currentService?.Subject ?? ''}
                    functEdit={e => editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
                    inputState={true}
                />
                :
                <InputsElements
                    label="Asunto"
                    name="Subject"
                    value={currentService.Subject ?? ''}
                    inputState={true}
                />
            }
        </>
    )
}

export default React.memo(SubjectInput)