import React, { useContext, useState } from 'react'
import InputSearchItems from './inputSearchItems'
import { useGetItems } from '../../../../../hooks/useGetItems'
import ShowItems from './ShowItems'
import { ContextService } from '../../../../../contexts/serviceGralContext'
import EachItem from './EachItem'

const ModalInventory = () => {

    const { inventoryExit = [] } = useContext(ContextService)

    const [identifierItem, setIdentifierItem] = useState({
        item: '',
        typeSearch: 'Código'
    })

    const { items = [], setItems } = useGetItems({ item: identifierItem?.item, searchType: identifierItem?.typeSearch })

    return (
        <div className="modal" id="modalInventory" tabIndex="-1" role="dialog" aria-labelledby="modalInventoryLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalInventoryLabel">Inventario</h5>
                        <div>
                            <InputSearchItems identifierItem={identifierItem} setIdentifierItem={({ typeSearch, item }) => setIdentifierItem({ typeSearch, item })} />
                        </div>
                        <div></div>
                    </div>
                    <div className="modal-body">
                        <div className="row mx-0 justify-content-center">
                            {
                                inventoryExit?.DocumentLines?.length > 0 &&
                                <div className="w-100">
                                    <p className="text-center">Item/s Seleccionados</p>
                                    <div className="overflow-auto border" style={{ maxHeight: '200px' }}>
                                        {inventoryExit?.DocumentLines?.map((i) => <EachItem key={'item' + i?.ItemCode} datos={i} />
                                        )}
                                    </div>
                                </div>
                            }
                            <div className="row justify-content-center mx-0 w-100">
                                {
                                    items?.length > 0 && <p className="mt-3">Item/s Encontrados</p>
                                }
                                <div className="overflow-auto w-100 border" style={{ maxHeight: '200px' }}>
                                    {items?.map(i => <EachItem key={'item' + i?.ItemCode} datos={i} searchType={identifierItem?.typeSearch} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => {
                            setItems([])
                            setIdentifierItem({
                                ...identifierItem,
                                item: ''
                            })
                        }
                        }>
                            Cerrar
                        </button>
                        <button type="button" className="btn-bgSAP mx-1" data-dismiss="modal">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ModalInventory)