import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import { ContextService } from "../../../../contexts/serviceGralContext";
import { useGetCombosQuantity } from "../../../../hooks/useGetCombosQuantity";
import Notiflix from 'notiflix'
import clientAxios from '../../../../utils/clientAxios'

const ModalStatus = (props) => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selected, setSelected] = useState({})
  // const [showAlert, setShowAlert] = useState(true);

  const { currentService = {}, currentServiceSelectCombo= {}, setCurrentServiceSelectCombo } = useContext(ContextService);
  

  const { combosQuantity = [] } = useGetCombosQuantity({
    currentService,
    CardCode: currentService?.CustomerCode,
  });


  console.log('SelectCombo', selected)

  console.log('currentServiceSelectCombo', currentServiceSelectCombo)





  function Selected(selected) {
    switch (selected?.TreeType) {
      case "I":
       const getCombosQuantityI = async () => {
          try {
              const resultCombos = await clientAxios.get('api/v1/CombosQuantityI', {
                  params: {
                      CardCode: selected?.CardCode,
                      DocEntry: selected?.DocEntry
                  }
              }
      )  

      setCurrentServiceSelectCombo({
        "CardCode": selected?.CardCode,
        "BPLName": selected?.BPLName,
        "PointOfIssueCode": '0'  + (parseInt(selected?.PTICode) +200).toString(),
        "DocumentLines": resultCombos.data.result.map((el, index) =>{
           if(el.Dscription !== selected.Dscription && index > 0) {
             return {
                      "LineNum": index,
                      "ItemCode": el?.ItemCode,
                      "Quantity": 0,
                       "BaseType": 13,
                       "BaseLine": el?.TargetType,
                       "BaseEntry": el?.DocEntry     
          }
           }else{
             return { "LineNum": index,
             "ItemCode": el?.ItemCode,
             "Quantity": el?.OpenQty,
              "BaseType": 13,
              "BaseLine": el?.TargetType,
              "BaseEntry": el?.DocEntry }
           }
        })
    } )

          } catch (error) {
                      return error
          }
      }
      getCombosQuantityI()

      
        break;

        case "N":
          setCurrentServiceSelectCombo({
            "CardCode": selected?.CardCode,
            "PointOfIssueCode": "0" + (parseInt(selected?.PTICode) +200).toString(),
            "BPLName": selected?.BPLName,
            "DocumentLines": [
                {
                    "LineNum": 0,
                    "ItemCode": selected?.ItemCode,
                    "Quantity": 1,
                    "BaseLine": selected?.TargetType,
                     "BaseType": 13,
                     "BaseEntry": selected?.DocEntry
                }
            ]
        } )
        break;
    
      default:
        break;
    }
 }

  return (
    <>
      {combosQuantity.length > 0 ? (
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title className="tex">SELECCIONAR SERVICE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ItemCode</th>
                  <th>Descripción</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                {combosQuantity.map((el) => {
                  return (
                    <tr key={el.DocNum}>
                      <td>
                        <Form.Check
                          inline
                          name="Group1"
                          type="radio"
                          id={`${el.DocNum}`}
                          onChange={() =>setSelected(el)
                          }
                        />
                      </td>
                      <td>{el?.ItemCode}</td>
                      <td>{el?.Dscription}</td>
                      <td>{el?.OpenQty}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => Selected(selected) & handleClose()}>
              Seleccionar
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <div className="container">
          <Alert variant="danger">
            <Alert.Heading>EL CLIENTE NO POSEE SERVICES!</Alert.Heading>
          </Alert>
        </div>
      )}
    </>
  );
};

export default ModalStatus;
