import { useReducer } from "react"
import { servicesMotorBikeActions } from '../actions'

function useReducerMotorBikes(initialState) {
    function reducer(state, action) {
        const { type, payload } = action
        switch (type) {
            case servicesMotorBikeActions.SET_CURRENTSERVICE:
                return {
                    ...state,
                    currentService: payload
                }
            case servicesMotorBikeActions.CLEAR_CURRENTSERVICE:
                return {
                    ...state,
                    currentService: {}
                }
            case servicesMotorBikeActions.SET_NEWSERVICE:
                return {
                    ...state,
                    newService: payload
                }
            case servicesMotorBikeActions.EDIT_NEWSERVICE:
                return {
                    ...state,
                    newService: { ...state.newService, payload }
                }
            case servicesMotorBikeActions.CLEAR_NEWSERVICE:
                return {
                    ...state,
                    newService: {}
                }
            case servicesMotorBikeActions.SET_SERVICESCURRENTVEHICLE:
                return {
                    ...state,
                    servicesCurrentVehicle: payload
                }
            case servicesMotorBikeActions.CLEAR_SERVICESCURRENTVEHICLE:
                return {
                    ...state,
                    servicesCurrentVehicle: []
                }
            case servicesMotorBikeActions.EDIT_STATETOFILTER:
                return {
                    ...state,
                    stateToFilter: { ...state.stateToFilter, ...payload }
                }
            default:
                return state
        }
    }

    const [state, dispatchMotorBikes] = useReducer(reducer, initialState)

    const { currentService, newService, currentVehicleData, servicesCurrentVehicle, stateToFilter } = state

    return { currentService, newService, currentVehicleData, servicesCurrentVehicle, stateToFilter, dispatchMotorBikes }
}

export default useReducerMotorBikes