import React, { useEffect, useRef, useState, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { MotorBikesContext } from '../../../../../contexts/motorBikesContext'
import SelectInput from '../../../../../elements/SelectInput'
import clientAxios from '../../../../../utils/clientAxios'
import useReducerServiceBikeHandlers from '../../../handlers/useReducerServiceBikeHandlers'
import { actionsServices } from '../../../../motorbikes/actionsServices'
import { initialState } from '../../../initialState'
import { BikesContext } from '../../../../../contexts/bikesContext'

function Origin() {

    const { currentService, editNewServiceHandler, newService, canEdit } = useContext(MotorBikesContext)

    // const { filterState, dispatchHandlerServiceBikes } = useReducerServiceBikeHandlers(initialState)
    const { editStatesToFilter } = useContext(BikesContext)

    // const editStatesToFilter = useCallback(({ Origin }) => {
    //     dispatchHandlerServiceBikes({ type: actionsServices.UPDATE_INPUTSNAME, payLoad: { Origin } })
    // }, [dispatchHandlerServiceBikes])

    const history = useHistory()
    const originSelect = useRef(null)

    const [originData, setOriginnData] = useState([])

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async function getOriginData() {
        try {
            const resultServiceCallOrigins = await clientAxios.get('/api/v1/ServiceCallOrigins', {
                params: {
                    conditions: 'ACT-B'
                }
            })
            setOriginnData(resultServiceCallOrigins.data)
            console.log('Respo OK', resultServiceCallOrigins)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => getOriginData(), [])

    useEffect(() => {
        sleep(500).then(() =>
            editStatesToFilter({ [originSelect?.current?.name]: originData?.current?.selectedOptions.length > 0 ? originData?.current?.selectedOptions[0].innerText : '' })
        )
    }, [originData])

    return (
        <>
            {
                currentService?.Status != "-1"
                    ?
                    <SelectInput
                        refProps={originSelect}
                        label="Origen"
                        name="Origin"
                        value={newService.Origin ?? ''}
                        inputState={true}
                        functEdit={e => {
                            editNewServiceHandler({ [e.target.name]: e.target.value })
                            editStatesToFilter({ [e.target.name]: e.target.selectedOptions?.length > 0 ? e.target.selectedOptions[0]?.innerText : '' })
                        }}
                    >
                        <option hidden>Elegir Origen</option>
                        {
                            (originData) && originData.sort((a, b) => a.Name.localeCompare(b.Name))
                                .map(o =>
                                    <option key={'origin' + o.OriginID} selected={newService.Origin == o.OriginID} value={o.OriginID}>{o.Name}</option>
                                )

                        }
                    </SelectInput>
                    :
                    <SelectInput
                        refProps={originSelect}
                        label="Origen"
                        name="Origin"
                        value={currentService.Origin ?? ''}
                        inputState={true}
                        functEdit={(e) => editStatesToFilter({ [e.target.name]: e.target.selectedOptions?.length > 0 ? e.terget.selectedOptions[0]?.innerText : '' })
                        }
                    >
                        <option hidden>Elegir Origen</option>
                        {
                            (originData) && originData.sort((a, b) => a.Name.localeCompare(b.Name))
                                .filter(o => history.location.pathname.includes('/servicio-interno') ? o.Name.includes('PNC') : !o.Name.includes('PNC')
                                )
                                .map(o =>
                                    <option key={'origin' + o.OriginID} selected={(currentService.Origin == o.OriginID)} value={o.OriginID}>{o.Name}</option>
                                )

                        }
                    </SelectInput >
            }
        </>
    )
}

export default React.memo(Origin)




// const replacePropertyHandler = useCallback(({ name, value }) => {
//     dispatchHandlerServiceBikes({ type: actionsServices.REPLACE_SERVICEDATA, payLoad: value, name: name })
// }, [dispatchHandlerServiceBikes])

// const removeItemInventory = useCallback((array) => {
//     const newArray = Array.isArray(array) ? array : []
//     dispatchHandlerServiceBikes({ type: actionsServices.REMOVE_INVENTORYEXIT, payLoad: newArray, name: 'inventoryExit' })
// }, [dispatchHandlerServiceBikes])

// const editInventoryOutPut = useCallback((newItem) => {
//     dispatchHandlerServiceBikes({ type: actionsServices.UPDATE_INVENTORYEXIT, payLoad: newItem, name: 'inventoryExit' })
// }, [dispatchHandlerServiceBikes])

// const removeAllItems = useCallback(() => {
//     dispatchHandlerServiceBikes({ type: actionsServices.REMOVE_ALL_ITEMS, name: 'inventoryExit' })
// }, [dispatchHandlerServiceBikes])

