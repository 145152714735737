import React from 'react';

function Spinner({ color, texto }) {
    return (
        <div>
            <small>{texto}</small>
            <div className={`spinner-border spinner-border-sm ${color}`} role="status"></div>
        </div>
    )
}

export default React.memo(Spinner)