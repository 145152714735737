import React, { useContext, useEffect, useRef, useState } from 'react'
import { MotorBikesContext } from '../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../elements/SelectInput'
import clientAxios from '../../../../../utils/clientAxios'

const SubProblemType = () => {

    const prevPage = window.localStorage.getItem("prevPage")
    const condition = window.localStorage.getItem("condition")

    const { editStatesToFilter, stateToFilter = {}
    } = useContext(MotorBikesContext)

    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)
    const valueForSelected = !!dinamicDataService?.ClosingDate ? currentService?.ProblemSubType : dinamicDataService?.ProblemSubType

    const [subProblemTypeData, setSubProblemTypeData] = useState([])

    const problemSubTypeSelect = useRef(null)

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async function getSubProblemType() {
        try {
            const resultServiceCallSubProblemTypes = await clientAxios.get('/api/v1/ServiceCallProblemSubTypes', {
                params: {
                    conditions: `contains(Description, '${condition}')`
                }
            })
            setSubProblemTypeData(resultServiceCallSubProblemTypes.data)
        } catch (error) {
            console.log(error && !!error.response ? error.response : error)
        }
    }

    useEffect(() => {
        getSubProblemType()
    }, [])

    useEffect(() => {
        editStatesToFilter({
            problemSubTypeSelect: problemSubTypeSelect?.current?.options?.[problemSubTypeSelect?.current?.selectedIndex].text
        })
    }, [problemSubTypeSelect?.current?.selectedIndex, stateToFilter?.problemTypeSelect, subProblemTypeData, currentService?.ProblemType])

    return (
        <SelectInput
            label="Subtipo de Problema"
            name="ProblemSubType"
            id="ProblemSubType"
            refProps={problemSubTypeSelect}
            value={valueForSelected ?? ''}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => {
                !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })
                editStatesToFilter({
                    problemSubTypeSelect: problemSubTypeSelect?.current?.options?.[problemSubTypeSelect?.current?.selectedIndex].text
                })
            }}
        >
            <option hidden>Elegir Subtipo de Problema</option>
            {
                (subProblemTypeData.length > 0 && Object.keys(stateToFilter).length > 0) &&
                subProblemTypeData.sort((a, b) => a.Name.localeCompare(b.Name))
                    .filter(problem => problem.Description.includes(stateToFilter.problemTypeSelect))
                    .map(array1 =>
                        <option key={'problemSubType' + array1.ProblemSubTypeID}
                            selected={valueForSelected == array1.ProblemSubTypeID} value={array1.ProblemSubTypeID}>
                                {array1.Name.replace(/-\s?INT\s?/gmi, "")}</option>
                    )

            }
        </SelectInput>
    )
}

export default React.memo(SubProblemType)