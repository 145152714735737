import clientAxios from '../../utils/clientAxios'
import Notiflix from 'notiflix'

export async function getBusinessData({ dni, codCliente }) {
    console.log(`getBusinessData ~ dni`, dni)
    Notiflix.Loading.dots('Cargando Datos del Cliente...')
    try {
        const partner = await clientAxios.get('/api/v1/BusinessPartners', {
            params: {
                dni: dni,
                codCliente
            }
        })
        Notiflix.Loading.remove()
        if (!!partner) {
            return partner.data
        } else {
            return
        }
    } catch (error) {
        console.log(error)
        const { response } = error
        Notiflix.Loading.remove()
        return { status: response.status, message: response.data.message }
    }

}


