import React, { useContext } from 'react'
import { ContextService } from '../../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../../elements/SelectInput'
import { options } from './options'

const AlarmaSelect = () => {

    const { currentService = {}, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)
    const valueForSelected = !!dinamicDataService?.ClosingDate ? currentService?.U_Alarma : dinamicDataService?.U_Alarma

    return (
        <SelectInput
            label="Alarma"
            name="U_Alarma"
            value={valueForSelected ?? ''}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
        >
            <option hidden>Elegir...</option>
            {options?.YESORNO.map(val => <option key={'alarma' + val}
                selected={valueForSelected == val} value={val}>{val}</option>)}
        </SelectInput>
    )
}

export default React.memo(AlarmaSelect)