import React, { useContext } from 'react'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function ItemDescriptionInput() {

    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return <InputsElements
        label="Descripción"
        name="ItemDescription"
        value={currentService.ItemDescription ?? currentService.U_Modelo ?? ''}
        inputState={true}
    />
}

export default React.memo(ItemDescriptionInput)