import React, { useContext, useEffect, useState } from 'react'
import clientAxios from '../../../../../utils/clientAxios'
import SelectInput from '../../../../../elements/SelectInput'
import { MotorBikesContext } from '../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../contexts/serviceGralContext'

const EmployeesInfo = () => {

    const { editNewServiceHandler, newService } = useContext(MotorBikesContext)
    const { currentService = {}, dinamicDataService = {} } = useContext(ContextService)

    const [employeesData, setEmployeesData] = useState()

    useEffect(() => {
        if (!employeesData) {
            (async () => {
                try {
                    const result = await clientAxios.get('/api/v1/EmployeesInfo')
                    setEmployeesData(result.data)
                } catch (error) {
                    console.log('An error has ocurred when try to get EmployeesInfo ->', error)
                }
            })()
        }
    }, [])

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        label="Técnico"
                        type="number"
                        name="TechnicianCode"
                        value={!!newService.TechnicianCode ? newService.TechnicianCode : ''}
                        inputState={true}
                        functEdit={e => editNewServiceHandler(e)}
                    >
                        <option hidden selected>Elegir Técnico</option>
                        {
                            employeesData && employeesData.sort((a, b) => (a.LastName.localeCompare(b.LastName)) || (a.FirstName.localeCompare(b.FirstName))).map(emp => <option key={'employee' + emp.EmployeeID} selected={(newService.TechnicianCode === emp.EmployeeID)} value={emp.EmployeeID}>{emp.LastName + ' ' + emp.FirstName}</option>)
                        }
                    </SelectInput>
                    :
                    <SelectInput
                        label="Técnico"
                        type="number"
                        name="TechnicianCode"
                        value={!!currentService.TechnicianCode ? currentService.TechnicianCode : ''}
                        inputState={true}
                    >
                        <option hidden selected>Elegir Técnico</option>
                        {
                            employeesData && employeesData.sort((a, b) => (a.LastName.localeCompare(b.LastName)) || (a.FirstName.localeCompare(b.FirstName))).map(emp => <option key={'employee' + emp.EmployeeID} selected={(currentService.TechnicianCode === emp.EmployeeID)} value={emp.EmployeeID}>{emp.LastName + ' ' + emp.FirstName}</option>)
                        }
                    </SelectInput >
            }
        </>
    )

}

export default React.memo(EmployeesInfo)