import { memo } from 'react'
import Table from '../../../../../elements/TableComponent'
import { useGetCombos } from '../../../../../hooks/useGetCombos'

const TableOfCombos = () => {

    const { combos = [] } = useGetCombos()

    return <div className="text-center">
        {
            combos.length > 0 ?
                <Table columns={
                    <>
                        <th>Cod. Factura</th>
                        <th>Producto</th>
                        <th>Descripción</th>
                        <th>Fecha</th>
                        <th>Pendiente</th>
                    </>
                }
                    id="docRelTalbe"
                >
                    {
                        combos.sort((a, b) => a.DocDate.localeCompare(b.DocDate) || b.Dscription - a.Dscription).map((c, i) =>
                            <tr key={i}>
                                <td>{c.FolNumFrom}</td>
                                <td>{c.ItemCode}</td>
                                <td className="text-truncate" title={c.Dscription ? c.Dscription : ''}>{c.Dscription}</td>
                                <td>{c.DocDate}</td>
                                <td>{c.OpenQty + '/' + c.Quantity}</td>
                            </tr>
                        )
                    }
                </Table>
                :

                <p>El cliente no posee Combos ni services Facturados</p>
        }
    </div>
}

export default memo(TableOfCombos)