import { useReducer } from "react"
import { currentServiceActions, currentServiceSelectComboActions } from '../actions'

function useReducerServiceGralContext(initialState) {
    function reducer(state, action) {
        const { type, payload } = action
        switch (type) {

            case currentServiceActions.SET_CURRENTSERVICE:
                return {
                    ...state,
                    currentService: payload
                }

                case currentServiceActions.SET_CURRENTSERVICESELECTCOMBO:
                    return {
                        ...state,
                        currentServiceSelectCombo: payload
                    }


            case currentServiceActions.SET_STATICDATASERVICE:
                return {
                    ...state,
                    staticDataService: { ...state.staticDataService, ...payload }
                }
            case currentServiceActions.SET_STATICDATASERVICE:
                return {
                    ...state,
                    staticDataService: payload
                }
            case currentServiceActions.CLEAR_CURRENTSERVICE:
                return {
                    ...state,
                    currentService: {}
                }
            case currentServiceActions.SET_NEWSERVICE:
                return {
                    ...state,
                    newService: payload
                }
            case currentServiceActions.EDIT_DINAMICDATASERVICE:
                return {
                    ...state,
                    dinamicDataService: { ...state.dinamicDataService, ...payload }
                }
            case currentServiceActions.CLEAR_DINAMICDATASERVICE:
                return {
                    ...state,
                    dinamicDataService: {}
                }
            case currentServiceActions.SET_SERVICESCURRENTVEHICLE:
                return {
                    ...state,
                    servicesCurrentVehicle: payload
                }
            case currentServiceActions.CLEAR_SERVICESCURRENTVEHICLE:
                return {
                    ...state,
                    servicesCurrentVehicle: []
                }
            case currentServiceActions.EDIT_STATETOFILTER:
                return {
                    ...state,
                    stateToFilter: { ...state.stateToFilter, ...payload }
                }
            case currentServiceActions.EDIT_INVENTORYEXIT:
                return {
                    ...state,
                    inventoryExit: { ...payload }
                }
            case currentServiceActions.SET_INVENTORYEXIT:
                const { BPL_IDAssignedToInvoice, U_Tipo_Operacion, GroupNumber, Line } = payload
                return {
                    ...state,
                    inventoryExit: {
                        BPL_IDAssignedToInvoice,
                        U_Tipo_Operacion,
                        GroupNumber,
                        DocumentLines: (state?.inventoryExit?.DocumentLines || []).concat(Line)
                    }
                }
            case currentServiceActions.SET_INTERNALVEHICLES:
                return {
                    ...state,
                    internalVehicles: payload
                }
            default:
                return state
        }
    }

    const [state, dispatchServiceGral] = useReducer(reducer, initialState)

    const { currentService,currentServiceSelectCombo, newService, currentVehicleData, servicesCurrentVehicle, stateToFilter, staticDataService, dinamicDataService, inventoryExit, internalVehicles } = state

    return { currentService,currentServiceSelectCombo, newService, currentVehicleData, servicesCurrentVehicle, stateToFilter, staticDataService, dinamicDataService, inventoryExit, internalVehicles, dispatchServiceGral }
}

export default useReducerServiceGralContext