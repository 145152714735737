import React, { useContext } from 'react'
import { BusinessContext } from '../../../../contexts/businessContext'
import { MotorBikesContext } from '../../../../contexts/motorBikesContext'
import InputsElements from '../../../../elements/InputsElements'

function BPCellularInput({ onlyNumber, comprovePasteHandler }) {

    const { businessData = {} } = useContext(BusinessContext)
    const { currentService, newService, editServiceHandler, canEdit } = useContext(MotorBikesContext)

    return (
        <>
            {
                canEdit ?
                    <InputsElements
                        label="N° de Tel"
                        name="BPCellular"
                        type="Number"
                        value={(!!businessData.Cellular && !!newService.BPCellular === false) ? businessData.Cellular : newService.BPCellular ?? currentService.BPCellular}
                        inputState={true}
                        functEdit={e => editServiceHandler({ [e.target.name]: e.target.value })}
                        onlyNumber={(e) => onlyNumber(e.charCode)}
                        comprovePasteHandler={(e) => comprovePasteHandler(e)}
                    />
                    :
                    <InputsElements
                        label="N° de Tel"
                        name="BPCellular"
                        type="Number"
                        value={(!!businessData.Cellular && !!newService.BPCellular === false) ? businessData.Cellular : currentService.BPCellular ?? currentService.BPCellular}
                        inputState={true}
                        onlyNumber={(e) => onlyNumber(e.charCode)}
                        comprovePasteHandler={(e) => comprovePasteHandler(e)}
                    />
            }
        </>
    )
}

export default React.memo(BPCellularInput)