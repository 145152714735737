import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import clientAxios from '../utils/clientAxios'
import utils from '../utils/utils'
import SweetAlert from '../elements/SweetAlert'
import axios from 'axios'
import Notiflix from 'notiflix'
import { useCookies } from 'react-cookie'
import './VehiclesInStock/moda.css'
function AddBusiness() {
    const [cookies, setCookie] = useCookies()

    const modalNewBusiness = useRef()
    const [numberStateAPI, setNumberStateAPI] = useState()
    const [locationAPI, setLocationAPI] = useState()
    const stateSelect = useRef()

    const provincias = [{ id: "54", nombre: "Misiones" }, { id: "74", nombre: "San Luis" }, { id: "70", nombre: "San Juan" }, { id: "30", nombre: "Entre Ríos" }, { id: "78", nombre: "Santa Cruz" }, { id: "62", nombre: "Río Negro" }, { id: "26", nombre: "Chubut" }, { id: "14", nombre: "Córdoba" }, { id: "50", nombre: "Mendoza" }, { id: "46", nombre: "La Rioja" }, { id: "10", nombre: "Catamarca" }, { id: "42", nombre: "La Pampa" }, { id: "86", nombre: "Santiago del Estero" }, { id: "18", nombre: "Corrientes" }, { id: "82", nombre: "Santa Fe" }, { id: "90", nombre: "Tucumán" }, { id: "58", nombre: "Neuquén" }, { id: "66", nombre: "Salta" }, { id: "22", nombre: "Chaco" }, { id: "34", nombre: "Formosa" }, { id: "38", nombre: "Jujuy" }, { id: "02", nombre: "Ciudad Autónoma de Buenos Aires" }, { id: "06", nombre: "Buenos Aires" }, { id: "94", nombre: "Tierra del Fuego" }]

    const getLocations = async () => {
        try {
            const locations = await axios.get(`https://apis.datos.gob.ar/georef/api/localidades?formato=json&max=5000&provincia=${numberStateAPI}&campos=departamento`)
            setLocationAPI(locations.data.localidades)
        } catch (error) {
            console.log(error)
        }
    }
    
    const addClientForm = useRef()
    const [states, setStates] = useState([])
    const [newClientData, setNewClientData] = useState({
        U_B1SYS_FiscIdType: '96',
        U_B1SYS_VATCtg: 'CF',
        Series: "146",
        CardType: 'cCustomer',
        
    })
    console.log('newClientData', newClientData);
    const [rulesInput, setRulesInput] = useState({
        name: true,
        identificationID: true,
        phoneNumber: true,
        email: true,
        clientTipe: true
    })

    const getStates = async () => {
        try {
            const result = await clientAxios.get('/api/v1/getStates')
            setStates(result.data)
        } catch (error) {
            const { response } = error
            console.log('Response on getStates ->', response)
        }
    }

    const onChangeHandler = (e) => {
        let texto = e.target.value.toUpperCase()
        if (e.target.name === 'State') {
            setNewClientData({ ...newClientData, City: e.target.selectedOptions[0].text.toUpperCase(), [e.target.name]: texto })
            const city = stateSelect.current.selectedOptions[0].text
            provincias.map(p => p.nombre.includes(city) && setNumberStateAPI(p.id))
        } else {
            setNewClientData({ ...newClientData, [e.target.name]: texto })
        }
    }

    const validateDNI = () => {
        const validationIdentification = utils.validateDNI(newClientData.FederalTaxID)
        setRulesInput({ ...rulesInput, 'identificationID': validationIdentification })
    }

    const validateName = () => {
        const validationName = /^[a-zA-Z\s]{4,32}$/.test(newClientData.CardName)
        setRulesInput({ ...rulesInput, 'name': validationName })
    }

    const validatePhone = () => {
        const validationPhone = utils.validatePhone(newClientData.Cellular)
        setRulesInput({ ...rulesInput, 'phoneNumber': validationPhone })
    }

    const validateEmail = () => {
        const validationEmail = utils.validateEmail(newClientData.EmailAddress)
        setRulesInput({ ...rulesInput, 'email': validationEmail })
    }

    const CloseFormHandler = () => {
        addClientForm.current.reset()
        setNewClientData({
            U_B1SYS_FiscIdType: '96',
            U_B1SYS_VATCtg: 'CF',
            Series: "146",
            CardType: 'cCustomer',
            BPAddresses: []
        })
        setRulesInput({
            name: true,
            identificationID: true,
            phoneNumber: true,
            email: true,
            clientTipe: true
        })
    }

    function LoginNew() {
   
    
        try {
            clientAxios.post('/api/v1/loginAgain')   
            .then(response => {
                console.log('response', response.data)
                for (const cookie of response.data["set-cookie"]) {
                    const cookieName = cookie.split("=")[0]
                    const cookieValue = cookie.split("=")[1].split(";")[0]
                    setCookie(cookieName, cookieValue, { path: '' })
                    window.location.replace('/inicio')
                }
                
            })
    
        } catch (error) {
            console.log(error)
        }
    }

    const submitHandler = async (e) => {
        e.preventDefault()
        newClientData[''] && delete newClientData['']
        if ((!rulesInput.name || (newClientData && !newClientData.CardName) || (newClientData && newClientData.CardName === ''))) {
            SweetAlert({ icon: 'error', text: 'Por favor, ingrese un nombre correcto', showConfirmButton: true })
            return
        }
        if ((!rulesInput.identificationID || (newClientData && !newClientData.FederalTaxID) || (newClientData && newClientData.FederalTaxID === ''))) {
            SweetAlert({ icon: 'error', text: 'DNI o CUIL/CUIT incorrecto', showConfirmButton: true })
            return
        }
        if ((!rulesInput.email || (newClientData && !newClientData.EmailAddress) || (newClientData && newClientData.EmailAddress === ''))) {
            SweetAlert({ icon: 'error', text: 'El email ingresado es invalido', showConfirmButton: true })
            return
        }
        if ((!rulesInput.phoneNumber || (newClientData && !newClientData.Cellular) || (newClientData && newClientData.Cellular === ''))) {
            SweetAlert({ icon: 'error', text: 'Formato de número invalido. Ej: 3816132486', showConfirmButton: true })
            return
        }
        if (((newClientData && !newClientData.ZipCode) || (newClientData && newClientData.ZipCode === ''))) {
            SweetAlert({ icon: 'error', text: 'El campo Código Postal es requerido', showConfirmButton: true })
            return
        }
        if (((newClientData && !newClientData.State) || (newClientData && newClientData.State === ''))) {
            SweetAlert({ icon: 'error', text: 'El campo Provincia es requerido', showConfirmButton: true })
            return
        }
        if (((newClientData && !newClientData.County) || (newClientData && newClientData.County === ''))) {
            SweetAlert({ icon: 'error', text: 'El campo Localidad es requerido', showConfirmButton: true })
            return
        }


        try {
            Notiflix.Loading.dots('Cargando Nuevo Cliente...')
            const result = await clientAxios.post('/api/v1/BusinessPartners', newClientData)

            Swal.fire({
                icon: 'success',
                title: 'Cliente creado con Exito',
                text: `Código de Cliente: ${result.data.CardCode}`,
                confirmButtonText: 'Ok',
                showCloseButton: false,
                allowOutsideClick: false,
                cancelButtonText: 'Cerrar',
                showClass: {
                    popup: '',
                    icon: ''
                },
                hideClass: {
                    popup: '',
                }
            }).then((result) => {
                if(result.isConfirmed){
                    LoginNew() 
                }
            })
            setNewClientData({
                U_B1SYS_FiscIdType: '96',
                U_B1SYS_VATCtg: 'CF',
                Series: "146",
                CardType: 'cCustomer',
                BPAddresses: []
            })
            Notiflix.Loading.remove()
            console.log('newClientData', newClientData);

            CloseFormHandler()
        } catch (error) {
            const { response } = error
            console.log(response)
            Notiflix.Loading.remove()
            if (response.status === 401 && response.data.message.includes('Existe otro socio')) {
                Swal.fire({
                    icon: 'error',
                    title: 'No se pudo crear el cliente',
                    text: `El DNI ingresado: ${newClientData.FederalTaxID} existe para otro Socio`,
                    showConfirmButton: true,
                    showClass: {
                        popup: '',
                        icon: ''
                    },
                    hideClass: {
                        popup: '',
                    }
                })
            }
        }
    }

    useEffect(() => {
        (newClientData.U_B1SYS_VATCtg === 'RI' || newClientData.U_B1SYS_VATCtg === 'EX' || newClientData.U_B1SYS_VATCtg === 'MT') ?
            setNewClientData({ ...newClientData, U_B1SYS_FiscIdType: '80' })
            :
            setNewClientData({ ...newClientData, U_B1SYS_FiscIdType: '96' })
    }, [newClientData.U_B1SYS_VATCtg])

    useEffect(() => {
        !!newClientData.State && getLocations()
    }, [newClientData.State])




    return (
        <>
            <button hidden data-toggle="modal" data-target="#addNewClient" onClick={() => getStates()} id="openModalNewClient"></button>
            <div className="modal fade" data-backdrop="static" data-keyboard="false"  tabindex="-1"  id="addNewClient"  aria-labelledby="addNewClientLabel" aria-hidden="true" ref={modalNewBusiness}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" data-keyboard="false" data-backdrop="static" id="addNewClientLabel">Cargar Nuevo Cliente</h5>
                            {/* <button type="button" className="close" data-dismiss="modal" onClick={() => addClientForm.current.reset()} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button> */}
                        </div>
                        <div className="modal-body my-3 px-3">
                            <form className="businessForm" ref={addClientForm}>
                                <div className="row mx-0">
                                    <div className="col-12 col-md-6">
                                        <label className={`${!rulesInput.name && 'text-danger'}`} htmlFor="Nombre">Apellido y Nombre</label>
                                        <input required className="form-control form-control-sm mb-1" type="text" id="Nombre" name="CardName" onChange={onChangeHandler} onKeyUp={validateName} />
                                        {
                                            !rulesInput.name &&
                                            <p><small className="text-danger">Debés completar correctamente el Nombre del Cliente</small></p>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className={`${!rulesInput.identificationID && 'text-danger'}`} htmlFor="dni">DNI o CUIL/CUIT</label>
                                        <input required className="form-control form-control-sm mb-1" type="text" id="dni" name="FederalTaxID" onChange={onChangeHandler} onKeyUp={validateDNI} title="El CUIL/CUIT o DNI no debe contener puntos ni guiones. Ej: 99999999" />
                                        {
                                            !rulesInput.identificationID &&
                                            <p><small className="text-danger">El CUIL/CUIT o DNI no debe contener puntos ni guiones. Ej: DNI:99999999 CUIL/CUIT: 20999999990</small></p>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className={`${!rulesInput.phoneNumber && 'text-danger'}`} htmlFor="Celular">Celular</label>
                                        <input required className="form-control form-control-sm mb-1" type="text" id="Celular" name="Cellular" onChange={onChangeHandler} onKeyUp={validatePhone} />
                                        {
                                            !rulesInput.phoneNumber &&
                                            <p><small className="text-danger">El Número de telefono debe contener código de área sin 0 ni 15</small></p>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className={`${!rulesInput.email && 'text-danger'}`} htmlFor="Correo">Email</label>
                                        <input required className="form-control form-control-sm mb-1" type="email" id="Correo" name="EmailAddress" onChange={onChangeHandler} onKeyUp={validateEmail} />
                                        {
                                            !rulesInput.email &&
                                            <p><small className="text-danger">Es necesario que ingrese un email valido</small></p>
                                        }
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-12 col-md-6">
                                        <label>Tipo Cliente</label>
                                        <select className="custom-select custom-select-sm mb-1" defaultValue={{ value: "CF" }} id="U_B1SYS_VATCtg" name="U_B1SYS_VATCtg" onChange={onChangeHandler}>
                                            <option hidden selected>Tipo Cliente...</option>
                                            <option value="CF">Consumidor Final</option>
                                            <option value="EX">Exento</option>
                                            <option value="RI">Responsable Inscripto</option>
                                            <option value="MT">Monotributo</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label>Provincia</label>
                                        <select className="custom-select custom-select-sm mb-3" defaultValue={{ value: "CF" }} name="State" ref={stateSelect} onChange={onChangeHandler}>
                                            <option hidden selected>Provincia...</option>
                                            {
                                                states.length > 0 &&
                                                states.map(s => <option key={s.Code} value={s.Code}>{s.Name}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label>Localidad</label>
                                        <select className="custom-select custom-select-sm mb-3" defaultValue={{ value: "CF" }} name="County" onChange={onChangeHandler}>
                                            <option hidden selected>Localidad...</option>
                                            {
                                                locationAPI &&
                                                locationAPI.map(s => <option key={s.id} value={s.nombre.toUpperCase()}>{s.nombre}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label>Código Postal</label>
                                        <input type="text" name="ZipCode" required title="Este campo es requerido" className="form-control form-control-sm mb-1" onChange={onChangeHandler} />
                                    </div>
                                    <div className="col-12">
                                        <label>Dirección</label>
                                        <input type="text" name="Address" required title="Este campo es requerido" className="form-control form-control-sm mb-1" onChange={onChangeHandler} />
                                    </div>
                                </div>
                                <div className="modal-footer mt-4">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={ () => CloseFormHandler && LoginNew()}>Descartar</button>
                                    <button type="submit" className="btn btn-primary" onClick={submitHandler}>Guardar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddBusiness