import React, { useContext } from 'react'
import { ContextService } from '../../../../contexts/serviceGralContext'
import SelectInput from '../../../../elements/SelectInput'

function PriorityInput() {

    const { currentService = {}, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    const value = !!dinamicDataService?.ClosingDate ? dinamicDataService?.Priority : currentService?.Priority

    return (
        <SelectInput
            label="Prioridad"
            name="Priority"
            value={value ?? ''}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
        >
            <option hidden selected>Elegir Prioridad...</option>
            <option selected={value === "scp_Low"} value="scp_Low">Baja</option>
            <option selected={value === "scp_Medium"} value="scp_Medium">Media</option>
            <option selected={value === "scp_High"} value="scp_High">Alta</option>
        </SelectInput>
    )
}

export default PriorityInput