import { useContext, useEffect, useState } from "react"
import { getCombos } from "../components/motorbikes/services/getCombos"
import { BusinessContext } from '../contexts/businessContext'
import { ContextService } from "../contexts/serviceGralContext"

export const useGetCombos = () => {
    const [combos, setCombos] = useState([])

    const { currentService = {} } = useContext(ContextService)

    const { businessData = {} } = useContext(BusinessContext)

    useEffect(() => {
        if (Object.keys(currentService).length > 0) {
            const getData = async () => {
                const { message = "", result = [] } = await getCombos({ cardCode: businessData?.CardCode })
                if (result.length > 0) setCombos(result)
                else setCombos([])
            }
            getData()
        }
    }, [currentService, businessData])
    return { combos }
}

