import Notiflix from "notiflix"
import SweetAlert from "../../elements/SweetAlert"
import clientAxios from "../../utils/clientAxios"

export const changePassService = async ({ newPassword, userID }) => {
    Notiflix.Loading.dots('Cambiando Contraseña')
    try {
        const resultChangePass = await clientAxios.patch('/api/v1/changepassword', { newPassword, userID })
        Notiflix.Loading.remove()
        SweetAlert({ icon: 'success', title: resultChangePass.data.message })
    } catch (error) {
        Notiflix.Loading.remove()
        console.log(error)
        SweetAlert({ icon: 'error', title: 'Ocurrió un error y no se pudo cambiar la contraseña', text: 'Intenta de nuevo más tarde' })
    }
}