import React, { useContext } from 'react'
import { ContextService } from '../../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../../elements/SelectInput'
import { options } from './options'

function RayadoSelect() {

    const { currentService = {}, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)
    const valueForSelected = !!dinamicDataService?.ClosingDate ? currentService?.U_Rayado : dinamicDataService?.U_Rayado

    return (
        <SelectInput
            label="Rayado"
            name="U_Rayado"
            value={valueForSelected ?? ''}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
        >
            <option hidden>Elegir...</option>
            {
                options?.YESORNO.map(val => <option key={'rayado' + val}
                    selected={valueForSelected == val}
                    value={val}>{val}</option>)
            }
        </SelectInput>
    )
}

export default React.memo(RayadoSelect)