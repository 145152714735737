import { useState, memo, useContext } from 'react'
import '../css/navbar.css'
import Login from './login'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'


const Navbar = () => {




    const [cookies] = useCookies([])
    const [companyDB, setCompanyDB] = useState()
    const [sucursal, setSucursal] = useState()

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-navbar py-2 py-lg-0">
            <div className="container">
                <div className="row mx-0 w-100 justify-content-between aling-items-center">
                    <div id="BrandBase">
                        <p className="mb-0 text-white"> <small>{companyDB ? 'BASE: ' + companyDB : (cookies && cookies.CompanyDB) && 'BASE: ' + cookies.CompanyDB}</small></p>
                        <p className="mb-0 text-white"><small>{sucursal ? 'SUCURSAL: ' + sucursal : (cookies && cookies.WareHouse) && 'SUCURSAL: ' + cookies.WareHouse + ' - ' + cookies.AliasName}</small></p>
                    </div>
                    <Link className="navbar-brand" onClick={() => window.location.replace('/')} to='/'>Llamada de Servicios</Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {
                        ((window.innerWidth <= 800) && (window.innerHeight <= 600)) ? <div id="navbarNavAltMarkup" className="collapse navbar-collapse">
                            <div className="navbar-nav">
                                <Login setCompanyDB={setCompanyDB} setSucursal={setSucursal} />
                            </div>
                        </div>
                            :
                            <Login setCompanyDB={setCompanyDB} setSucursal={setSucursal} />
                    }

                </div>
            </div>
        </nav>
    )
}

export default memo(Navbar)