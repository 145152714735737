import clientAxios from "../../../utils/clientAxios"

export const getProductOutput = async ({ ActivityCodes, cancelToken }) => {
    try {
        const InventoryExit = await clientAxios.get('api/v1/Activities', {
            params: {
                ActivityCodes: ActivityCodes,
                cancelToken: cancelToken
            }
        })
        return InventoryExit.data
    } catch (error) {
        console.log(error && !!error.response ? error.response : error)
        if (error.response.data.errorMessage.includes('Session restore error.')) {
            console.log(ActivityCodes)
            getProductOutput({ ActivityCodes })
        }
        return error
    }
}