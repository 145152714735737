import React from 'react'
import BusinessPartner from './BusinessPartner'
import DocRelacionados from './DocRelacionados'
import General from './General'
import Operation from './Operation'
import History from './History'
import Programation from './Programation'

const TabsMenu = ({ editInventoryOutPut, inventoryExit, removeItemInventory, servicio, items, loading }) => {

    return (
        <>
            <General />
            <BusinessPartner servicio={servicio} />
            <Operation editInventoryOutPut={editInventoryOutPut} inventoryExit={inventoryExit} removeItemInventory={removeItemInventory} items={items} loading={loading} />
            <DocRelacionados />
            <History />
            <Programation />
        </>
    )
}

export default React.memo(TabsMenu)