import React, { useContext, memo } from 'react'
import jsPDF from 'jspdf'
import { useCookies } from 'react-cookie'
import { useLocation } from 'react-router-dom'
import logo from '../../assets/marca.jpg'
import motoIzq from '../../assets/moto-1.jpg'
import motoDer from '../../assets/moto-2.jpg'
import combustible from '../../assets/combustible.jpg'
import aceite from '../../assets/aceite.jpg'
import { BusinessContext } from '../../contexts/businessContext'
import { MotorBikesContext } from '../../contexts/motorBikesContext'
import { ContextService } from '../../contexts/serviceGralContext'

const ServicePDF = ({ items = [] }) => {

    const { businessData = {} } = useContext(BusinessContext)
    const { currentService = {} } = useContext(ContextService)
    const { stateToFilter = {} } = useContext(MotorBikesContext)

    const [cookies] = useCookies()

    const itemDescription = (!!currentService.ItemDescription) && currentService.ItemDescription.split(/^([\w\-]+)\W/)

    const location = useLocation()
    const initialHorizontalLine = 8
    const initialHorizontalTextRigth = 15
    const secondHorizontalTextRigth = 65
    const initialHorizontalTextLeft = 115
    const endHorizontalLine = 208

    const pdfService = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'legal',
        putOnlyUsedFonts: true,
        floatPrecision: 16, // or "smart", default is 16
    })

    const pageWidth = pdfService.internal.pageSize.width || pdfService.internal.pageSize.getWidth()
    const fullPageWidth = pageWidth * 0.90 /* width will 90% of the page width. */
    const LeftMargin = (pageWidth - fullPageWidth) / 2/* Left margin will be half of the remaining space*/

    const generatePDF = () => {
        pdfService.rect(initialHorizontalLine, 8, 200, 340)
        pdfService.setFontSize(8)
        // pdfService.rect(5, 70, 35, 7.5, "FD");
        // pdfService.triangle(40, 70, 40, 77.3, 50, 70, "FD");
        pdfService.setFont("times", "bold")
        pdfService.text("Nombre del Concesionario:", initialHorizontalTextRigth, 13)
        pdfService.text("Dirección:", initialHorizontalTextRigth, 18)
        pdfService.text("Provincia:", initialHorizontalTextRigth, 23)
        pdfService.text("Cod Postal:", initialHorizontalTextRigth, 28)
        pdfService.text("CUIT:", secondHorizontalTextRigth, 18)
        pdfService.text("Localidad:", secondHorizontalTextRigth, 23)
        pdfService.text("Tel:", secondHorizontalTextRigth, 28)
        pdfService.setFont("times", "normal")
        pdfService.setFontSize(6.5)
        pdfService.text("YUHMAK S.A.", 50, 13)
        pdfService.text(`${(cookies && !!cookies.Street) && cookies.Street.toUpperCase()}`, 29, 18)
        pdfService.text(``, 29, 23)
        pdfService.text(`${cookies && cookies.ZipCode}`, 30, 28)
        pdfService.text("30-62389309-6", 74.5, 18)
        pdfService.text(`${(cookies.City.toUpperCase())}`, 79, 23)
        // pdfService.text("Tel", 71, 28);
        pdfService.setLineWidth(0.1)
        pdfService.line(110, 8, 110, 60) // vertical Line
        pdfService.setFont("times", "bold")
        pdfService.text("Estado General del Vehículo:", secondHorizontalTextRigth + 29, 64)
        pdfService.text("Orden del Taller N°:", initialHorizontalTextLeft, 13)
        pdfService.text(`Llamada N°: ${currentService?.ServiceCallID}`, initialHorizontalTextLeft, 17)
        pdfService.text("Fecha:", initialHorizontalTextLeft, 25)
        pdfService.setFont("times", "normal")
        pdfService.setFontSize(7)
        pdfService.text(`${!!currentService.DocNum ? currentService.DocNum : ''}`, 136, 13)
        pdfService.addImage(logo, "JPEG", 157, 11, 40, 13)
        pdfService.text(`${!!currentService.CreationDate ? new Date(currentService.CreationDate.replaceAll("-", "/")).toLocaleString('default', { day: '2-digit', month: '2-digit', year: 'numeric' }) : ''}`, 122.5, 25)
        pdfService.setFont("times", "bold")
        pdfService.setFontSize(8)
        pdfService.text("Datos del Cliente:", initialHorizontalTextRigth, 34)
        pdfService.text("Datos del Vehículo:", initialHorizontalTextLeft, 34)
        pdfService.text("Nombre y Apellido:", initialHorizontalTextRigth, 41)
        pdfService.text("D.N.I:", initialHorizontalTextRigth, 45)
        pdfService.text("C.P:", secondHorizontalTextRigth - 7, 45)
        pdfService.text("Dirección:", initialHorizontalTextRigth, 49)
        pdfService.text("Localidad:", initialHorizontalTextRigth, 52.5)
        pdfService.text("Teléfono:", initialHorizontalTextRigth, 57)
        pdfService.text("Email:", (secondHorizontalTextRigth - 7), 57)
        pdfService.text("Marca:", initialHorizontalTextLeft, 41)
        pdfService.text("Modelo:", initialHorizontalTextLeft, 45)
        pdfService.text("N° Motor:", initialHorizontalTextLeft, 49)
        pdfService.text("N° Chasis:", initialHorizontalTextLeft, 53)
        pdfService.text("Patente:", initialHorizontalTextLeft, 57)
        pdfService.setFont("times", "normal")
        pdfService.setFontSize(7)
        pdfService.text(`${!!businessData.CardName ? businessData.CardName.toUpperCase() : ''}`, 39.5, 41)
        pdfService.text(`${!!businessData.FederalTaxID ? businessData.FederalTaxID : ''}`, 26.5, 45)
        pdfService.text(`${!!businessData.ZipCode ? businessData.ZipCode : ''}`, 64.5, 45)
        pdfService.text(`${businessData.Address.toUpperCase()}`, 28, 49.5)
        // pdfService.text(`${!!businessData.City ? businessData.City.toUpperCase() : ''}`, 30.5, 53)
        pdfService.text(`${!!currentService.BPCellular ? currentService.BPCellular : ''}`, 27.5, 57)
        pdfService.text(`${!!currentService.BPeMail ? currentService.BPeMail.toUpperCase() : ''}`, secondHorizontalTextRigth + 2.5, 57)
        pdfService.text(`${!!currentService.ItemDescription ? itemDescription && itemDescription.length > 0 && itemDescription[1] : ''}`, 125, 41)
        pdfService.text(`${!!currentService.ItemDescription ? itemDescription && itemDescription.length > 0 && itemDescription[2] : ''}`, 126, 45)
        pdfService.text(`${!!currentService.U_Motor ? currentService.U_Motor : ''}`, 128.5, 49)
        pdfService.text(`${!!currentService.U_Chasis ? currentService.U_Chasis : ''}`, 128.5, 53)
        pdfService.text(`${!!currentService.U_Patente ? currentService.U_Patente : ''}`, 126, 57)
        pdfService.line(initialHorizontalLine, 30, endHorizontalLine, 30)
        pdfService.line(initialHorizontalLine, 37, endHorizontalLine, 37)
        pdfService.line(initialHorizontalLine, 60, endHorizontalLine, 60)
        pdfService.line(initialHorizontalLine, 67, endHorizontalLine, 67)
        pdfService.setFontSize(8)
        pdfService.text("Kilometraje/Horas:", initialHorizontalTextRigth + 20, 80)
        pdfService.setFontSize(7)
        pdfService.rect(initialHorizontalLine + 50, 76.5, initialHorizontalLine + 10, 5)
        pdfService.text(`${!!currentService.U_Kilometraje ? currentService.U_Kilometraje : ''}`, initialHorizontalTextRigth + 45, 80)
        pdfService.setFontSize(8)
        pdfService.text("Motocicleta sin Averias:", initialHorizontalTextRigth + 68, 80)
        pdfService.rect(initialHorizontalLine + 104, 76.5, initialHorizontalLine + 10, 5)
        pdfService.text("Fluido \nRadiador:", initialHorizontalTextRigth, 80)
        pdfService.rect(initialHorizontalLine + 6, 85, initialHorizontalLine + 5, 15)
        pdfService.text("Max:", initialHorizontalTextRigth, 89)
        pdfService.rect(initialHorizontalLine + 14, 87, initialHorizontalLine - 5, 3)
        pdfService.line(initialHorizontalLine + 15.5, 90, initialHorizontalLine + 15.5, 91.5) // vertical Line
        pdfService.rect(initialHorizontalLine + 14, 91.5, initialHorizontalLine - 5, 3)
        pdfService.line(initialHorizontalLine + 15.5, 94.5, initialHorizontalLine + 15.5, 95.8) // vertical Line
        pdfService.rect(initialHorizontalLine + 14, 96, initialHorizontalLine - 5, 3)
        pdfService.text("Min:", initialHorizontalTextRigth, 98)
        pdfService.addImage(motoIzq, "JPEG", 28, 90, 70, 40)
        pdfService.addImage(motoDer, "JPEG", 100, 90, 70, 40)
        pdfService.rect(initialHorizontalLine + 130, 77, initialHorizontalLine + 30, 20)
        pdfService.text("Nivel Combustible", initialHorizontalTextLeft + 32, 81.5)
        pdfService.rect(initialHorizontalLine + 172, 77, initialHorizontalLine + 15, 20)
        pdfService.addImage(combustible, "JPEG", 138.5, 81.8, 37, 14.5)
        pdfService.text("Nivel Aceite", initialHorizontalTextLeft + 69, 81.5)
        pdfService.addImage(aceite, "JPEG", 181, 83, 10, 13)
        pdfService.text("Max:", initialHorizontalTextRigth + 177, 85)
        pdfService.rect(initialHorizontalLine + 191, 82.5, initialHorizontalLine - 5, 3)
        pdfService.line(initialHorizontalLine + 192.5, 85.5, initialHorizontalLine + 192.5, 87) // vertical Line
        pdfService.rect(initialHorizontalLine + 191, 87, initialHorizontalLine - 5, 3)
        pdfService.line(initialHorizontalLine + 192.5, 90, initialHorizontalLine + 192.5, 91.5) // vertical Line
        pdfService.rect(initialHorizontalLine + 191, 91.5, initialHorizontalLine - 5, 3)
        pdfService.text("Min:", initialHorizontalTextRigth + 177.5, 94)
        pdfService.text("Kit de Herramientas", initialHorizontalTextRigth + 157, 108)
        pdfService.text(!!currentService.U_Kit_Herramientas ? currentService.U_Kit_Herramientas : '', initialHorizontalTextRigth + 182, 108)
        pdfService.text("Casco", initialHorizontalTextRigth + 157, 111)
        pdfService.text(!!currentService.U_Casco ? currentService.U_Casco : '', initialHorizontalTextRigth + 182, 111)
        pdfService.text("Alarma", initialHorizontalTextRigth + 157, 114)
        pdfService.text(!!currentService.U_Alarma ? currentService.U_Alarma : '', initialHorizontalTextRigth + 182, 114)
        pdfService.text("Manchado", initialHorizontalTextRigth + 157, 117)
        pdfService.text(!!currentService.U_Manchado ? currentService.U_Manchado : '', initialHorizontalTextRigth + 182, 117)
        pdfService.text("Rayado", initialHorizontalTextRigth + 157, 120)
        pdfService.text(!!currentService.U_Rayado ? currentService.U_Rayado : '', initialHorizontalTextRigth + 182, 120)
        pdfService.text("Rotura", initialHorizontalTextRigth + 157, 123)
        pdfService.text(!!currentService.U_Rotura ? currentService.U_Rotura : '', initialHorizontalTextRigth + 182, 123)
        pdfService.text("Faltante", initialHorizontalTextRigth + 157, 126)
        pdfService.text(!!currentService.U_Faltante ? currentService.U_Faltante : '', initialHorizontalTextRigth + 182, 126)
        pdfService.text("Fluido \nFrenos:", initialHorizontalTextRigth, 110)
        pdfService.rect(initialHorizontalLine + 6, 115, initialHorizontalLine + 5, 15)
        pdfService.text("Max:", initialHorizontalTextRigth, 119)
        pdfService.rect(initialHorizontalLine + 14, 117, initialHorizontalLine - 5, 3)
        pdfService.line(initialHorizontalLine + 15.5, 120, initialHorizontalLine + 15.5, 121.5) // vertical Line
        pdfService.rect(initialHorizontalLine + 14, 121.5, initialHorizontalLine - 5, 3)
        pdfService.line(initialHorizontalLine + 15.5, 124.5, initialHorizontalLine + 15.5, 125.8) // vertical Line
        pdfService.rect(initialHorizontalLine + 14, 125.8, initialHorizontalLine - 5, 3)
        pdfService.text("Min:", initialHorizontalTextRigth, 128)
        pdfService.line(initialHorizontalLine, 140, endHorizontalLine, 140)
        pdfService.line(initialHorizontalLine, 147, endHorizontalLine, 147)
        pdfService.setLineWidth(.6)
        pdfService.line(initialHorizontalLine, 154, endHorizontalLine, 154)
        pdfService.setFont("times", "bold")
        pdfService.text("Descripción pedido del Cliente:", initialHorizontalTextRigth, 159)
        pdfService.text(`${Object.keys(stateToFilter).length > 0 ? stateToFilter.problemTypeSelect : ''}`, initialHorizontalTextRigth + 40, 159)
        pdfService.text(`${Object.keys(stateToFilter).length > 0 ? stateToFilter.problemSubTypeSelect : ''}`, initialHorizontalTextRigth + 100, 159)
        pdfService.setFont("times", "bold")
        pdfService.text("Observaciones Cliente:", initialHorizontalTextRigth - 2, 135.5)
        const lines = pdfService.splitTextToSize(!!currentService.Description ? currentService.Description : '', fullPageWidth)
        pdfService.setLineHeightFactor(2.4)
        pdfService.text(LeftMargin, 139, lines)
        pdfService.setLineHeightFactor(1.3)
        pdfService.line(initialHorizontalLine, 161, endHorizontalLine, 161)
        pdfService.text("Me declaro en conocimiento de la condición en la que se encuentra la unidad, afIrmando que los daños en la carrocería detectados en el momento de la recepción,\nson los indicados en la figura. Autorizo a realizar todos los trabajos descritos a mi exclusiva cuenta, y a efectuar las pruebas necesarias de la unidad (Incluidas en \nruta).", initialHorizontalTextRigth - 5, 165)
        pdfService.line(20, 177, 70, 177)
        pdfService.setFont("times", "normal")
        pdfService.text("Fecha", initialHorizontalTextRigth + 25, 180)
        pdfService.line(85, 177, 135, 177)
        pdfService.text("Firma y Aclaración Cliente", initialHorizontalTextRigth + 80, 180)
        pdfService.line(150, 177, 200, 177)
        pdfService.text("Firma del Técnico", initialHorizontalTextRigth + 150, 180)
        // Cuadro repuestos
        pdfService.setLineWidth(.6)
        pdfService.line(initialHorizontalLine, 182, endHorizontalLine, 182)
        pdfService.line(initialHorizontalLine, 190, endHorizontalLine, 190)
        pdfService.setLineWidth(.1)
        pdfService.line(initialHorizontalLine, 197, endHorizontalLine, 197)
        pdfService.line(initialHorizontalLine, 204, endHorizontalLine, 204)
        pdfService.line(initialHorizontalLine, 211, endHorizontalLine, 211)
        pdfService.line(initialHorizontalLine, 218, endHorizontalLine, 218)
        pdfService.setLineWidth(.6)
        pdfService.line(initialHorizontalLine, 230, endHorizontalLine, 230)
        // Fin Cuadro repuestos (lineas)
        pdfService.setFont("times", "normal")
        pdfService.text("Cant.", initialHorizontalTextRigth - 5, 187)
        pdfService.text("Repuestos Utilizados", initialHorizontalTextRigth + 33, 187)
        pdfService.text("Instrumentos de medición utilizados", initialHorizontalTextRigth + 125, 187)
        if (items.length > 0) {
            // First item
            pdfService.text(`${items[0].Quantity !== undefined ? items[0].Quantity : ''}`, initialHorizontalTextRigth - 3, 193.5)
            pdfService.text(`${items[0].ItemDescription !== undefined ? items[0].ItemDescription.substring(0, 20) : ''}`, initialHorizontalTextRigth + 3.5, 193.5)
            pdfService.text(`${items[0].MeasureUnit !== undefined ? items[0].MeasureUnit.substring(0, 4) : ''}`, initialHorizontalTextRigth + 41.7, 193.5)
            pdfService.text(`${items[0].ItemCode !== undefined ? items[0].ItemCode : ''}`, initialHorizontalTextRigth + 52, 193.5)
        }
        if (items.length > 1) {
            // Second Item
            pdfService.text(`${items[1].Quantity !== undefined ? items[1].Quantity : ''}`, initialHorizontalTextRigth - 3, 200.5)
            pdfService.text(`${items[1].ItemDescription !== undefined ? items[1].ItemDescription.substring(0, 20) : ''}`, initialHorizontalTextRigth + 3.5, 200.5)
            pdfService.text(`${items[1].MeasureUnit !== undefined ? items[1].MeasureUnit.substring(0, 4) : ''}`, initialHorizontalTextRigth + 41.7, 200.5)
            pdfService.text(`${items[1].ItemCode !== undefined ? items[1].ItemCode : ''}`, initialHorizontalTextRigth + 52, 200.5)
        }
        if (items.length > 2) {
            // Third Item
            pdfService.text(`${items[2].Quantity !== undefined ? items[2].Quantity : ''}`, initialHorizontalTextRigth - 3, 207.5)
            pdfService.text(`${items[2].ItemDescription !== undefined ? items[2].ItemDescription.substring(0, 20) : ''}`, initialHorizontalTextRigth + 3.5, 207.5)
            pdfService.text(`${items[2].MeasureUnit !== undefined ? items[2].MeasureUnit.substring(0, 4) : ''}`, initialHorizontalTextRigth + 41.7, 207.5)
            pdfService.text(`${items[2].ItemCode !== undefined ? items[2].ItemCode : ''}`, initialHorizontalTextRigth + 52, 207.5)
        }
        if (items.length > 3) {
            // Four Item
            pdfService.text(`${items[3].Quantity !== undefined ? items[3].Quantity : ''}`, initialHorizontalTextRigth - 3, 214.5)
            pdfService.text(`${items[3].ItemDescription !== undefined ? items[3].ItemDescription.substring(0, 20) : ''}`, initialHorizontalTextRigth + 3.5, 214.5)
            pdfService.text(`${items[3].MeasureUnit !== undefined ? items[3].MeasureUnit.substring(0, 4) : ''}`, initialHorizontalTextRigth + 41.7, 214.5)
            pdfService.text(`${items[3].ItemCode !== undefined ? items[3].ItemCode : ''}`, initialHorizontalTextRigth + 52, 214.5)
        }
        pdfService.line(17, 182, 17, 218) // vertical Line
        pdfService.line(55, 190, 55, 218) // vertical Line
        pdfService.line(65, 190, 65, 218) // vertical Line
        pdfService.line(160, 218, 160, 230) // vertical Line
        pdfService.line(108, 182, 108, 230) // vertical Line
        pdfService.setFont("times", "normal")
        pdfService.text("Fecha compromiso de entrega", initialHorizontalTextRigth - 5, 225).text('________________________', initialHorizontalTextRigth - 5, 225)
        pdfService.setLineWidth(.1)
        pdfService.line(68, 225, 75, 221) // vertical Line
        pdfService.line(81, 225, 88, 221) // vertical Line
        pdfService.line(60, 225, 95, 225)
        pdfService.text("Tiempo mano de Obra Total", initialHorizontalTextRigth + 95, 221).text('_______________________', initialHorizontalTextRigth + 95, 221)
        pdfService.text("Costo M.O.", initialHorizontalTextRigth + 147, 221).text('_________', initialHorizontalTextRigth + 147, 221)
        pdfService.setFont("times", "bold")
        pdfService.text("Diagnostico y Reparación:", initialHorizontalTextRigth, 235)
        pdfService.line(initialHorizontalLine, 241, endHorizontalLine, 241)
        pdfService.line(initialHorizontalLine, 249, endHorizontalLine, 249)
        pdfService.line(initialHorizontalLine, 258, endHorizontalLine, 258)
        pdfService.setFont("times", "normal")
        const resolucitionLines = pdfService.splitTextToSize(!!currentService.Resolution ? currentService.Resolution : '', fullPageWidth)
        pdfService.setLineHeightFactor(2.8)
        pdfService.text(initialHorizontalTextRigth, 240, resolucitionLines)
        pdfService.setLineHeightFactor(1.3)
        pdfService.setFont("times", "bold")
        pdfService.text("Dejo expresa constancia que luego de haber sido probada, retiro la unidad antes mencionada con los trabajos de reparación realizados, en perfecto estado y normal \nfuncionamiento declarando conocer y aceptar el estado en que retro la misma. La unidad será retirada por el titular, en caso de no poder asistir, el responsable \ndeberá acreditar la titularidad de la misma (fotocopia de DNI, Remito, Cedula).", initialHorizontalTextRigth - 5, 261)
        pdfService.text("Vehículo retirado el:", initialHorizontalTextRigth - 5, 273)
        pdfService.setFont("times", "normal")
        pdfService.setLineWidth(.1)
        pdfService.line(20, 281, 70, 281)
        pdfService.text("Fecha", initialHorizontalTextRigth + 25, 284)
        pdfService.line(85, 281, 135, 281)
        pdfService.text("Firma y Aclaración Cliente", initialHorizontalTextRigth + 80, 284)
        pdfService.line(150, 281, 200, 281)
        pdfService.text("Firma del Técnico", initialHorizontalTextRigth + 150, 284)
        pdfService.setLineWidth(0.1)
        pdfService.setLineDash([1])
        pdfService.line(initialHorizontalLine, 286, endHorizontalLine, 286)
        pdfService.setLineDash()
        pdfService.setLineWidth(0.6)
        pdfService.line(initialHorizontalLine, 287, endHorizontalLine, 287)
        pdfService.addImage(logo, "JPEG", initialHorizontalTextRigth - 5, 290.5, 25, 8.25)
        pdfService.text(`${cookies && cookies.AliasName}`, initialHorizontalTextRigth + 22, 294)
        pdfService.text(`${cookies && cookies.Street}`, initialHorizontalTextRigth + 22, 299)
        pdfService.line(initialHorizontalLine + 90, 287, initialHorizontalLine + 90, 303) // vertical Line
        pdfService.line(initialHorizontalLine + 148, 287, initialHorizontalLine + 148, 295) // vertical Line
        pdfService.setFont("times", "bold")
        pdfService.text("Llamada N°:", initialHorizontalTextRigth + 88, 292)
        pdfService.setFont("times", "normal")
        pdfService.text(`${!!currentService.ServiceCallID ? currentService.ServiceCallID : ''}`, initialHorizontalTextRigth + 104.5, 292)
        pdfService.line(initialHorizontalLine + 90, 295, endHorizontalLine, 295)
        pdfService.setFont("times", "bold")
        pdfService.text("N° Motor:", initialHorizontalTextRigth + 145, 292)
        pdfService.setFont("times", "normal")
        pdfService.text(`${!!currentService.U_Motor ? currentService.U_Motor : ''}`, initialHorizontalTextRigth + 158.5, 292)
        pdfService.line(initialHorizontalLine, 303, endHorizontalLine, 303)
        pdfService.setFont("times", "bold")
        pdfService.text("Nombre y Apellido:", initialHorizontalTextRigth + 88, 300)
        pdfService.setFont("times", "normal")
        pdfService.text(`${!!currentService.CustomerName ? currentService.CustomerName : ''}`, initialHorizontalTextRigth + 113, 300)
        pdfService.setFont("times", "bold")
        pdfService.text("Estimado Cliente: presente éste cupón como comprobante al retrar su vehículo del concesionario el día:………../………../………… \n\nVehículo ingresado el día: ……………/………… /………………. \n\nFIRMA RECEPCIONISTA: …………………………………………………………………… \n\nVehículo Marca / Modelo / Color…………………………………………………………………………………………………………. \n\nSr.Cliente su Vehículo con respectiva llave y documentación solicitada permanecerá en el concesionario durante los días estimados a efectuar la reparación, \nuna vez concluida la misma se le informará para el retiro del vehículo. Cumplido dicho término, el concesionario podrá cobrar estadía según crea conveniente, \nla unidad será retirada por el titular en caso de no poder asistir, el responsable deberá acreditar la titularidad de la misma presentando este cupón, remito o copia \nde D.N.I", initialHorizontalTextRigth - 5, 308)
    }

    return (
        <div style={{ width: location.pathname === '/pdf' && '100vw', height: location.pathname === '/pdf' && '100vh' }}>
            {
                location.pathname === '/pdf' ?
                    <object data={pdfService.output('datauri')}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                    >
                    </object>
                    :
                    <button className="btn btn-primary" onClick={() => {
                        generatePDF()
                        pdfService.output('dataurlnewwindow')
                    }
                    }>Imprimir PDF</button>
                // < iframe src = "web/viewer.html?file=abcde.pdf" style = "visibility: hidden" >
            }
        </div>
    )
}

export default memo(ServicePDF)