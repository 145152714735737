import React, { useContext } from 'react'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function U_KilometrajeInput({ onlyNumber, comprovePasteHandler }) {

    const { dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <InputsElements
            label="Kilometros"
            name="U_Kilometraje"
            type="Number"
            value={dinamicDataService?.U_Kilometraje ?? ''}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => !!dinamicDataService?.ClosingDate === false &&
                editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
            onlyNumber={(e) => !!dinamicDataService?.ClosingDate === false &&
                onlyNumber(e.charCode)}
            comprovePasteHandler={(e) => !!dinamicDataService?.ClosingDate === false &&
                comprovePasteHandler(e)}
        />
    )
}

export default U_KilometrajeInput