import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../../elements/SelectInput'
import { options } from './options'

function FaltanteSelect() {

    const { editNewServiceHandler, newService } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        label="Faltante"
                        name="U_Faltante"
                        value={!!newService.U_Faltante ? newService.U_Faltante : ''}
                        inputState={true}
                        functEdit={e => editNewServiceHandler(e)}
                    >
                        <option hidden>Elegir...</option>
                        {options.YESORNO.map(val => <option key={'faltante' + val}
                            selected={!!newService.U_Faltante && newService.U_Faltante === val}
                            value={val}>{val}</option>)}
                    </SelectInput>
                    :
                    <SelectInput
                        label="Faltante"
                        name="U_Faltante"
                        value={!!currentService.U_Faltante ? currentService.U_Faltante : ''}
                        inputState={true}
                    >
                        <option hidden>Elegir...</option>
                        {options.YESORNO.map(val => <option key={'faltante' + val}
                            selected={!!currentService.U_Faltante && currentService.U_Faltante === val}
                            value={val}>{val}</option>)}
                    </SelectInput>
            }
        </>
    )
}

export default React.memo(FaltanteSelect)