import React, { useContext } from 'react'
import { BusinessContext } from '../../../../contexts/businessContext'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function U_ChasisInput() {

    const { currentService = {}, dinamicDataService = {}, staticDataService = {}, editDinamicServiceDataHandler } = useContext(ContextService)
    const { currentVehicleData } = useContext(BusinessContext)

    const isClosed = !!dinamicDataService?.ClosingDate
    const isYuhmakVehicle = !!currentVehicleData.InternalSerialNumb || !!staticDataService?.InternalSerialNum

    return (
        <InputsElements
            label="N° Chasis"
            name="U_Chasis"
            value={currentVehicleData?.U_Chasis ?? dinamicDataService?.U_Chasis ?? currentService?.U_Chasis ?? ''}
            inputState={(isClosed || isYuhmakVehicle) ? true : dinamicDataService?.Status == "-1" || (isYuhmakVehicle || !!currentVehicleData.U_Chasis)}
            functEdit={(e) => (isClosed === false) && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
        />
    )
}

export default U_ChasisInput