import React, { useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useGetUserData } from '../../hooks/useGetUserData'
import { useHistory } from 'react-router-dom'
import SweetAlert from '../../elements/SweetAlert'

const ModalOffices = ({ setSucursal }) => {
    const history = useHistory()
    const [cookies, setCookie] = useCookies()
    const [selectUserBranch, setSelectUserBranch] = useState({})
    const almacenModal = useRef(null)

    const { userData = {} } = useGetUserData({ user: cookies.userName })


    console.log('selectUserBranch', selectUserBranch);
    const saveBPLIDAndWareHouse = (e) => {
        e.preventDefault()
        if (!selectUserBranch?.Street) {
            SweetAlert({
                icon: 'warning',
                text: `La selección de sucursal es obligatoria`,
                width: 350,
                showConfirmButton: true,
            })
            return
        }
        setCookie('BPLID', selectUserBranch && selectUserBranch.BPLId, { path: '/' })
        setCookie('WareHouse', selectUserBranch && selectUserBranch.BPLName, { path: '/' })
        setCookie('AliasName', selectUserBranch && selectUserBranch.AliasName, { path: '/' })
        setCookie('City', selectUserBranch && selectUserBranch.City, { path: '/' })
        setCookie('Street', selectUserBranch && selectUserBranch.Street, { path: '/' })
        setCookie('ZipCode', selectUserBranch && selectUserBranch.ZipCode, { path: '/' })
        setCookie('State', selectUserBranch && selectUserBranch.State, { path: '/' })
        setSucursal(selectUserBranch ? selectUserBranch.BPLName + '-' + selectUserBranch.AliasName : '')
        almacenModal.current.classList.remove("show", "backModal", "d-block")
        history.replace((!!history.location.pathname && history.location.pathname !== '/') ? history.location.pathname : '/inicio')
    }

    return (
        <div className={`modal fade ${Object.keys(userData).length > 0 && !!cookies.WareHouse === false ? 'show backModal d-block' : ''}`} id="BPLIDModal" data-backdrop="static" ref={almacenModal} data-keyboard="false" tabIndex="-1" aria-labelledby="BPLIDModalLabel" aria-hidden="true" data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                    <div className="modal-body">
                        <form>
                            {selectUserBranch &&
                                <div className="form-group">
                                    <label>Info Sucursal: </label>
                                    <p><small><strong>Calle: </strong>{selectUserBranch.Street} <strong>Almacen N°: </strong>{selectUserBranch.DefaultWarehouseID}</small></p>
                                </div>
                            }
                            <div className="form-group">
                                <label><small>Sucursal</small></label>
                                <select className="custom-select custom-select-sm" defaultValue={Object.keys(selectUserBranch).length > 0 ? selectUserBranch.AliasName : ''} onChange={e => console.log(selectUserBranch) & setSelectUserBranch(JSON.parse(e.target.value))}>
                                    <option hidden selected>Elegir Sucursal</option>
                                    {
                                        (userData?.wareHouse?.length > 0) && userData?.wareHouse?.map((b, i) =>
                                            <option key={i} value={JSON.stringify(b)}>{b.AliasName}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <button type="submit" className="btn btn-primary" onClick={saveBPLIDAndWareHouse}>Guardar</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ModalOffices)