import React, { useState } from "react"

const MenuServices = () => {

    const [tabSelected, setTabSelected] = useState({
        'nav-General-tab': true
    })

    const TabMenuItem = ({ id, target, select, label }) => {
        return (
            <p type="button" onClick={() => setTabSelected({ [id]: true })} className={`nav-link mb-0 ${tabSelected?.[id] ? 'active' : ''}`} id={id} data-toggle="tab" data-target={target} role="tab" aria-controls={target.replace('#', '')} aria-selected={select}><small>{label}</small></p>
        )
    }

    return (
        <div className="nav nav-tabs row justify-content-center mt-3" id="nav-tab" role="tablist">
            <TabMenuItem id="nav-General-tab" target="#nav-General" select={tabSelected?.['nav-General-tab']} label="General" />
            <TabMenuItem id="nav-Socio-de-Negocios-tab" target="#nav-Socio-de-Negocios" select={tabSelected?.['nav-Socio-de-Negocios-tab']} label="Socio de Negocios" />
            <TabMenuItem id="nav-Operaciones-tab" target="#nav-Operaciones" select={tabSelected?.['nav-Operaciones-tab']} label="Operaciones" />
            <TabMenuItem id="nav-Doc-Relacionados-tab" target="#nav-Doc-Relacionados" select={tabSelected?.['nav-Doc-Relacionados-tab']} label="Doc. Relacionados" />
            <TabMenuItem id="nav-Historial-tab" target="#nav-Historial" select={tabSelected?.['nav-Historial-tab']} label="Historial" />
            <TabMenuItem id="nav-Programacion-tab" target="#nav-Programacion" select={tabSelected?.['nav-Programacion-tab']} label="Programación" />
        </div>
    )
}

export default React.memo(MenuServices)