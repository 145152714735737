import SweetAlert from "../../../elements/SweetAlert"
import clientAxios from "../../../utils/clientAxios"
import Notiflix from 'notiflix'


export const getItemService = async ({ item, searchType, almacen }) => {
    Notiflix.Loading.dots('Cargando Items...')
    try {
        const restulGetItems = await clientAxios.get(`/api/v1/Items`, {
            params: {
                item: item,
                searchType: searchType,
                warehouse: almacen
            }
        })
        Notiflix.Loading.remove()
        if (restulGetItems.data.message && restulGetItems.data.message.includes('No hay')) {
            SweetAlert({
                icon: 'info',
                title: 'No hay stock del item Seleccionado',
                width: 400,
                html: `<div className="mt-4">
            <p className="text-left mb-0"><small>CÓDIGO: ${restulGetItems.data.itemCode}<br />DESCRIPCIÓN: ${restulGetItems.data.itemDescrip}</small></p>
            </div>`,
                showConfirmButton: true
            })
            return
        }
        restulGetItems && restulGetItems.data.length === 0 &&
            SweetAlert({
                icon: 'info',
                title: 'No existen resultados para la búsqueda realizada',
                width: 400,
                html: `<div className="mt-4">
            <p className="text-left mb-0"><small>Por favor, corroborá que los filtros sean correctos</small></p>
            </div>`,
                showConfirmButton: true
            })
        return restulGetItems.data
    } catch (error) {
        const { response } = error
        console.log(response)
        Notiflix.Loading.remove()
        response.status === 404 && response.data.errorMessage.includes('No matching records found') &&
            SweetAlert({
                icon: 'info',
                title: 'No se encontró el item buscado',
                width: 400,
                html: `<div className="mt-4">
            <p className="text-left mb-0"><small>La búsqueda se hizo por <strong>${searchType}</strong>, recuerda que puedes cambiar el tipo de busqueda a "${searchType === 'Código' ? 'Descripción' : searchType}" </small></p>
            </div>`,
                showConfirmButton: true

            })
    }
}