import React, { useContext } from 'react'
import { BusinessContext } from '../../../../contexts/businessContext'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function ItemDescriptionInput() {

    const { currentService = {}, dinamicDataService = {} } = useContext(ContextService)
    const { currentVehicleData } = useContext(BusinessContext)

    return <InputsElements
        label="Descripción"
        name="ItemDescription"
        value={currentVehicleData?.ItemDescription ?? dinamicDataService?.ItemDescription ?? currentService?.ItemDescription ?? ''}
        inputState={true}
    />
}

export default ItemDescriptionInput