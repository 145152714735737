import React, { useContext, useEffect, useState } from 'react'
import { MotorBikesContext } from '../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../elements/SelectInput'
import clientAxios from '../../../../../utils/clientAxios'

function TypeOperation() {

    const { editNewServiceHandler, newService } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)


    const [typeOperationData, setTypeOperationData] = useState()

    useEffect(() => {
        async function getTypeOperation() {
            try {
                const resultServiceCallTypeOperations = await clientAxios.get('/api/v1/ServiceCallTypes')
                setTypeOperationData(resultServiceCallTypeOperations.data)
            } catch (error) {
                console.log(error && !!error.response ? error.response : error)
            }
        }
        !typeOperationData && getTypeOperation()
    }, [])

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        label="Tipo de Llamada"
                        name="CallType"
                        value={!!newService.CallType ? newService.CallType : ''}
                        inputState={true}
                        functEdit={e => editNewServiceHandler(e)}
                    >
                        <option hidden>Elegir Tipo de Llamada</option>
                        {
                            typeOperationData &&
                            typeOperationData.sort((a, b) => a.Name.localeCompare(b.Name))
                                .map(array1 =>
                                    <option key={'CallType' + array1.CallTypeID} selected={(newService.CallType == array1.CallTypeID)} value={array1.CallTypeID}>{array1.Name}</option>
                                )

                        }
                    </SelectInput>
                    :
                    <SelectInput
                        label="Tipo de Llamada"
                        name="CallType"
                        value={!!currentService.CallType ? currentService.CallType : ''}
                        inputState={true}
                    >
                        <option hidden>Elegir Tipo de Llamada</option>
                        {
                            typeOperationData &&
                            typeOperationData.sort((a, b) => a.Name.localeCompare(b.Name))
                                .map(array1 =>
                                    <option key={'CallType' + array1.CallTypeID} selected={(currentService.CallType == array1.CallTypeID)} value={array1.CallTypeID}>{array1.Name}</option>
                                )

                        }
                    </SelectInput >
            }
        </>
    )
}

export default React.memo(TypeOperation)