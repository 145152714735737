import { useCallback, useContext, useEffect } from 'react'
import debounce from 'just-debounce-it'
import getService from '../components/motorbikes/services/getService'
import { MotorBikesContext } from '../contexts/motorBikesContext'
import { useHistory, useParams } from 'react-router-dom'
import { ContextService } from '../contexts/serviceGralContext'
import SweetAlert from '../elements/SweetAlert'

export function useFilterCurrentService({ serviceID = "" }) {

    const { servicesCurrentVehicle = [], setServicesCurrentVehicle, clearServicesCurrentVehicle } = useContext(MotorBikesContext)
    const { currentService = {}, setCurrentService } = useContext(ContextService)

    const params = useParams()
    const history = useHistory()



    const checkIfServiceIDExistInServices = () => {
        const actualService = servicesCurrentVehicle.filter(serv => serv.ServiceCallID == serviceID)
        if (actualService.length > 0) {
            setCurrentService(actualService[0])
            return true
        } else {
            clearServicesCurrentVehicle()
            return false
        }
    }

    const getServicesCurrentVehicle = async () => {
        if (servicesCurrentVehicle.length === 0 || (!!serviceID && serviceID !== currentService?.ServiceCallID)) {
            try {
                const resultServicesCurrentVehicle = await getService({ vehicle: params.chasis, client: params.clientCode })
                console.log(`getServicesCurrentVehicle ~ resultServicesCurrentVehicle`, resultServicesCurrentVehicle)
                if (Array.isArray(resultServicesCurrentVehicle)) {
                    setCurrentService({})&&setServicesCurrentVehicle({})
                    if (resultServicesCurrentVehicle?.length > 0) {
                        setServicesCurrentVehicle(resultServicesCurrentVehicle)
                        resultServicesCurrentVehicle.forEach(serv => serv.ServiceCallID == serviceID && setCurrentService(serv))
                        if (!!serviceID === false) history.replace(`${resultServicesCurrentVehicle[0].ServiceCallID}`, { ...history?.location?.state })
                        // history.replace(currentPath, { ...history?.location?.state })
                        // setTimeout(() => {
                        // }, 500)
                    }
                } else {
                    SweetAlert({
                        icon: "question", title: "No se encontraron Services", text: "¿Desea cargar uno nuevo?", cancelButtonText: "Volver", showCancelButton: true, allowEscapeKey: false, functDenied: () => history.goBack()
                    })
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const setCurrentServiceHandler = useCallback(debounce(
        async () => {
            if (!checkIfServiceIDExistInServices() && !!params?.chasis) {
                getServicesCurrentVehicle()
            }
        }, 200), [serviceID])

    useEffect(setCurrentServiceHandler, [serviceID])
}