import React from 'react'
import BPCellularInput from '../../../FormService/inputsServices/BPCellularInput'
import BPBillToAddressInput from './BPBillToAddressInput'
import BPEmailInput from './BPEmailInput'

const BusinessPartner = ({ editHandler, servicio, currentService }) => {
    return (
        <div className="tab-pane fade" id="nav-Socio-de-Negocios" role="tabpanel" aria-labelledby="nav-Socio-de-Negocios-tab"
        >
            <div className="row mx-0 justify-content-between align-items-center">
                <div className="col-12 col-md-6 my-2">
                    <BPBillToAddressInput editHandler={editHandler} />
                </div>
                <div className="col-12 col-md-6 my-2">
                    <BPCellularInput editHandler={editHandler} servicio={servicio} currentService={currentService} />
                    <BPEmailInput editHandler={editHandler} servicio={servicio} />
                </div>
            </div>
        </div>
    )
}

export default React.memo(BusinessPartner)