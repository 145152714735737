import React, { useContext } from 'react'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function ItemCodeInput() {

    const { currentVehiclesData = {}, currentService = {} } = useContext(ContextService)

    return <InputsElements
        label="Artículo"
        name="ItemCode"
        value={!!currentVehiclesData.ItemCode ? currentVehiclesData.ItemCode : currentService?.ItemCode ? currentService.ItemCode : ''}
        inputState={true}
    />
}

export default React.memo(ItemCodeInput)