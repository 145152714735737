import { memo } from 'react'
import { BrowserRouter as Router, Switch,HashRouter } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import ClientPage from './page/ClientPage'
import HomePage from './page/HomePage'
import Navbar from './components/Navbar'
import ContextServiceProvider from './contexts/serviceGralContext'
import ServiceBuyTypePage from './page/ServiceBuyTypePage'
import MotorBikesContextProvider from './contexts/motorBikesContext'
import BikesContextProvider from './contexts/bikesContext'
import BusinessContextProvider from './contexts/businessContext'
import FormServiceBike from './components/bikes/FormServiceBike'
import FormService from './components/motorbikes/FormService'

function App() {
  return (
    <HashRouter basename="/">
    <BusinessContextProvider>
                  <MotorBikesContextProvider>
                <ContextServiceProvider>


      <Navbar />
      </ContextServiceProvider>
      </MotorBikesContextProvider>
      </BusinessContextProvider>
      <div style={{ display: 'none', visibility: "hidden" }}>
        <a href="/servicio-bikes"></a>
        <a href="/servicio-interno"></a>
        <a href="/servicio"></a>
        <a href="/inicio"></a>
        <a href="/motos"></a>
        <a href="/bikes"></a>
        <a href="/interno"></a>
      </div>
      <Switch>
        <BusinessContextProvider>
          <ContextServiceProvider>
            <BikesContextProvider>
              <PrivateRoute path="/servicio-bikes/:clientCode?/:chasis?/:serviceID?" component={FormServiceBike} />
            </BikesContextProvider>
            <MotorBikesContextProvider>
              <PrivateRoute path="/servicio-interno/:clientCode?/:chasis?/:serviceID?" exact component={ServiceBuyTypePage} />
              <PrivateRoute path="/servicio/:clientCode?/:chasis?/:serviceID?" exact component={FormService} />
            </MotorBikesContextProvider>
          <PrivateRoute path="/interno/" exact component={ClientPage} />
          <PrivateRoute path="/bikes/:dni?" component={ClientPage} />
          <PrivateRoute path="/motos/:dni?" component={ClientPage} />
          <PrivateRoute path="/inicio" component={HomePage} />
          </ContextServiceProvider>
        </BusinessContextProvider>
      </Switch>
    </HashRouter>

  )
}

export default memo(App)