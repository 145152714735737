import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../contexts/serviceGralContext'
import SelectInput from '../../../../elements/SelectInput'

function StatusSelectInput() {

    const { newService, editNewServiceHandler } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        label="Estatus de Llamada"
                        name="Status"
                        value={newService.Status ?? currentService.Status}
                        inputState={true}
                        functEdit={e => editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
                    >
                        <option hidden selected>Elegir Estado...</option>
                        <option selected={(!!newService.Status && newService.Status == "-3") || currentService.Status == "-3"} value="-3">Abierto</option>
                        <option selected={(!!newService.Status && newService.Status == "-1") || currentService.Status == "-1"} value="-1">Cerrada</option>
                        <option selected={(!!newService.Status && newService.Status == "-2") || currentService.Status == "-2"} value="-2">Pendiente</option>
                    </SelectInput>
                    :
                    <SelectInput
                        label="Estatus de Llamada"
                        name="Status"
                        value={currentService.Status ?? ''}
                        inputState={true}
                    >
                        <option hidden selected>Elegir Estado...</option>
                        <option selected={currentService.Status == "-3"} value="-3">Abierto</option>
                        <option selected={currentService.Status == "-1"} value="-1">Cerrada</option>
                        <option selected={currentService.Status == "-2"} value="-2">Pendiente</option>
                    </SelectInput >
            }
        </>
    )
}

export default React.memo(StatusSelectInput)