import React, { useContext } from 'react'
import { BusinessContext } from '../../../../contexts/businessContext'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function BPCellularInput({ onlyNumber, comprovePasteHandler }) {

    const { businessData = {} } = useContext(BusinessContext)
    const { currentService = {}, dinamicDataService = {}, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <InputsElements
            label="N° de Tel"
            name="BPCellular"
            type="Number"
            value={dinamicDataService?.BPCellular ?? businessData?.Cellular ?? currentService?.BPCellular ?? ''}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
            comprovePasteHandler={(e) => !!dinamicDataService?.ClosingDate === false && comprovePasteHandler(e)}
        />
    )
}

export default BPCellularInput