import { useContext, useEffect, useState } from "react"
import { ContextService } from '../contexts/serviceGralContext'
import {BusinessContext} from '../contexts/businessContext'
import {getCombosQuantity} from "../components/motorbikes/FormService/inputsServices/getCombosQuantity"

export const useGetCombosQuantity = () => {
    const [combosQuantity, setCombos] = useState([])

    const { currentService = {} } = useContext(ContextService)
    const { businessData = {} } = useContext(BusinessContext)



    useEffect(() => {
        if (Object.keys(currentService).length > 0) {
            const getData = async () => {
                const { message = "", result = [] } = await getCombosQuantity({ cardCode: businessData?.CardCode })
                if (result.length > 0) setCombos(result)
                else setCombos([])
            }
            getData()
        }
    }, [currentService])
    return { combosQuantity }
}


