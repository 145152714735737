import React from 'react'
import '../css/tables.css'

function TableComponent({ children, columns, id }) {
    return (
        <div className="overFlowTable">
            <table className="table border table-hover table-sm-responsive" id={id}>
                <thead className="thead-light text-center">
                    <tr>
                        {columns}
                    </tr>
                </thead>
                <tbody className="text-center">
                    {children}
                </tbody>
            </table>
        </div>
    )
}

export default React.memo(TableComponent)