import clientAxios from "../../utils/clientAxios"

export async function getUserData({ user, internalKey }) {
    try {
        const resultAssigneeName = await clientAxios.get(`/api/v1/users`, {
            params: {
                user: user,
                InternalKey: internalKey
            }
        })
        localStorage.removeItem('userName')
        return resultAssigneeName.data
    } catch (error) {
        const { response } = error
        // console.log(response)
        return response
    }
}