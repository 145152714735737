import React, { useCallback } from 'react'

function SelectInput({ children, label, name, value, type, refProps, functEdit, inputState, onlyNumber, comprovePasteHandler, id }) {

    const functCallback = useCallback((e) => {
        functEdit(e)
    }, [functEdit])

    return (
        <div className="input-group input-group-sm">
            <div className="input-group-prepend">
                <label className="input-group-text mb-1" htmlFor="inputGroupSelect01"><small>{label}</small></label>
            </div>
            <select className="custom-select custom-select-sm mb-1"
                ref={refProps}
                name={name}
                id={id}
                onKeyPress={(e) => onlyNumber ? onlyNumber(e) : null}
                onPaste={(e) => comprovePasteHandler ? comprovePasteHandler(e) : null}
                maxLength={type === "Number" ? 10 : 800}
                minLength={type === "Number" ? 10 : 800}
                defaultValue={value}
                disabled={inputState}
                onChange={e => functEdit(e)}
            >
                {children}
            </select>
        </div>
    )
}

export default React.memo(SelectInput)