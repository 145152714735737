import React, { useContext } from 'react'
import { BusinessContext } from '../../../../contexts/businessContext'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function U_PatenteInput() {

    const { currentService = {}, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)
    const { currentVehicleData } = useContext(BusinessContext)

    return (
        <InputsElements
            label="N° Patente"
            name="U_Patente"
            value={dinamicDataService.U_Patente ?? currentService.U_Patente ?? ''}
            inputState={!!dinamicDataService?.ClosingDate ? !!dinamicDataService?.ClosingDate : (!!currentVehicleData.InternalSerialNumber || !!currentService?.U_Patente)}
            functEdit={(e) => (!!dinamicDataService?.ClosingDate === false) && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
        />
    )
}

export default U_PatenteInput