import React, { useContext } from 'react'
import { ContextService } from '../../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../../elements/SelectInput'
import { options } from './options'

function ManchadoSelect() {

    const { currentService = {}, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)
    const valueForSelected = !!dinamicDataService?.ClosingDate ? currentService?.U_Manchado : dinamicDataService?.U_Manchado

    return (

        <SelectInput
            label="Manchado"
            name="U_Manchado"
            value={valueForSelected ?? ''}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
        >
            <option hidden>Elegir...</option>
            {
                options?.YESORNO.map(val => <option key={'manchado' + val}
                    selected={valueForSelected == val}
                    value={val}>{val}</option>)
            }
        </SelectInput>
    )
}

export default React.memo(ManchadoSelect)