import { useReducer } from "react"
import { servicesBikesActions } from '../actions'

function useReducerBikes(initialState) {
    function reducer(state, action) {

        const { type, payload } = action

        switch (type) {
            case servicesBikesActions.UPDATE_OPTIONSTEXT:
                return {
                    ...state,
                    filterState: { ...state.filterState, ...payload }
                }
            case servicesBikesActions.EDIT_NEWSERVICEBIKES:
                return {
                    ...state,
                    newService: { ...state.newService, payload }
                }
            default:
                return state
        }
    }

    const [state, dispatchBikes] = useReducer(reducer, initialState)

    const { currentService, filterState, newServiceBike } = state

    return { currentService, filterState, newServiceBike, dispatchBikes }
}

export default useReducerBikes