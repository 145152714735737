import Notiflix from "notiflix"
import SweetAlert from "../../../elements/SweetAlert"
import clientAxios from "../../../utils/clientAxios"

export const changeCellular = async ({ Cellular, CardCode }) => {
    try {
        const resultChangePass = await clientAxios.patch('/api/v1/PatchBusinessPartnersCel', { Cellular, CardCode })
        // SweetAlert({ icon: 'success', title: resultChangePass.data.message })
    } catch (error) {
        SweetAlert({ icon: 'error', title: 'Ocurrió un error y no se pudo cambiar el celular', text: 'Intenta de nuevo más tarde' })
    }
}