export const initialStateAllServices = {
    currentService: {},
    currentServiceSelectCombo: {},
    newService: {},
    currentVehicleData: {},
    servicesCurrentVehicle: [],
    inventoryExit: {},
    filterState: {},
    servicioInterno: {
        // CustomerCode: 'P30623893096',
        // CustomerName: 'YUHMAK S.A.',
        // BPBillToCode: "Bill to",
        // BPCellular: 3816111111,
        // ServiceBPType: "srvcPurchasing",
        // Status: -3,
        // Priority: "scp_Low",
        // CallType: 1,
        // AssigneeCode: (cookies && cookies.user) ? cookies.user : '',
        // U_Almacen: (cookies && cookies.WareHouse) ? cookies.WareHouse : '',
        // InternalSerialNum: currentVehiclesData.InternalSerialNumber,
        // ItemDescription: currentVehiclesData.ItemDescription,
        // U_Chasis: currentVehiclesData.U_Chasis,
        // U_Motor: currentVehiclesData.U_Motor,
        // U_Almacen: (cookies && cookies.WareHouse) ? cookies.WareHouse : '',
        // ItemCode: currentVehiclesData.ItemCode
    },
    staticDataService: {},
    dinamicDataService: {},
    internalVehicles: []
}