import React, { useContext, useEffect, useState } from 'react'
import Table from '../../elements/TableComponent'
import { getOwnServices } from './services'
import { useCookies } from 'react-cookie'
import { Link } from 'react-router-dom'
import Spinner from '../Spinner'
import { BusinessContext } from '../../contexts/businessContext'

function DashBoard() {

    const [cookies] = useCookies()
    const [servicesData, setServicesData] = useState([])
    const [loadingLastServices, setLoadingLastServices] = useState(false)

    const { clearBusinessData, clearBusinessVehicles, setCurrentVehicleData } = useContext(BusinessContext)

    const monthsNumbers = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    const date = new Date()
    const dateNumber = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()
    date.setDate(dateNumber - 20)

    useEffect(() => {
        setCurrentVehicleData({})
        clearBusinessData()
        clearBusinessVehicles([])
    }, [])

    useEffect(() => {
        (async () => {
            setLoadingLastServices(true)
            if (!!cookies.user) {
                const dataService = await getOwnServices({ user: cookies.user, frDate: `${date.getFullYear()}-${monthsNumbers[date.getMonth()]}-${date.getDate().toString().padStart(2, "0")}`, ltDate: `${year}-${monthsNumbers[month]}-${dateNumber.toString().padStart(2, "0")}` })
                setServicesData(dataService)
                setLoadingLastServices(false)
            }
        })()
    }, [cookies.user])

    const columns = ["Service", "Fecha", "Estado", "Acción"].map(val => <th key={val}>{val}</th>)

    const statesOfServices = servicesData.length > 0 ? servicesData.reduce((accum, val) => {
        return { ...accum, [val.Status]: (accum[val.Status] || 0) + 1 }
    }, {}) : {}

    return (
        <div className="row justify-content-center">
            {
                (servicesData.length === 0 && loadingLastServices) ? <Spinner texto="Buscando Services... " />
                    :
                    !loadingLastServices && servicesData.length === 0 ? 'No hay services en los últimos 20 días'
                        :
                        servicesData.length > 0 &&
                        <>
                            {
                                Object.keys(statesOfServices).length > 0 && Object.keys(statesOfServices).map(status => <div key={status} className="card text-center m-3">
                                    <div className="card-header">
                                        Services <strong>{status == '-1' ? 'Cerrados' : status == '-3' ? 'Abiertos' : status == '-2' ? 'Pendientes' : status == '1' && 'Desestimados'}</strong>
                                    </div>
                                    <div className="card-body">
                                        <blockquote className="blockquote mb-0">
                                            <p className="display-4">{statesOfServices[status]}</p>
                                            <footer className="blockquote-footer"><small title={`Desde: ${date.getDate()}/${monthsNumbers[date.getMonth()]}/${date.getFullYear()} Hasta ${dateNumber}/${monthsNumbers[month]}/${year}`}>20 dias atrás</small></footer>
                                        </blockquote>
                                    </div>
                                </div>)
                            }
                            {
                                servicesData.length > 0 &&
                                <Table columns={columns}>
                                    {
                                        servicesData
                                            .sort((a, b) => a.Status - b.Status || Date.parse(b.CreationDate) - Date.parse(a.CreationDate))
                                            .map(val => {
                                                const [year, month, date] = val.CreationDate.split('-')
                                                const formatedDate = `${date}/${month}/${year}`
                                                const dictionaryState = {
                                                    "-1": "Cerrado",
                                                    "-2": "Pendiente",
                                                    "-3": "Abierto",
                                                    "1": "Desestimado"
                                                }
                                                const state = dictionaryState[val.Status]
                                                return <tr key={val.ServiceCallID}>
                                                    <td>{val.ServiceCallID}</td>
                                                    <td>{formatedDate}</td>
                                                    <td>{state}</td>
                                                    <td><Link to={`/servicio/${val.CustomerCode}/${val.U_Chasis}/${val.ServiceCallID}`} onClick={() => {
                                                        const isInternalService = val.CustomerCode === "P30623893096"
                                                        window.localStorage.setItem("prevPage", isInternalService ? "interno" : "motos")
                                                        window.localStorage.setItem("condition", isInternalService ? "-INT" : "-M")
                                                    }}>Ver Service</Link></td>
                                                </tr>
                                            })
                                    }
                                </Table>
                            }
                        </>
            }
        </div >
    )
}

export default React.memo(DashBoard)