import React, { useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ModalSearchBrand from '../../ModalSearchBrand'
import ServicePDF from '../ServicePDF'
import Swal from 'sweetalert2'
import Spinner from '../../Spinner'
import SubjectInput from './InputsServicesBike/SubjectInput'
import CustomerCodeInput from './InputsServicesBike/CustomerCodeInput'
import CustomerNameInput from './InputsServicesBike/CustomerNameInput'
import BPCellularInput from './InputsServicesBike/BPCellularInput'
import InternalSerialNumbrer from './InputsServicesBike/InternalSerialNumbrer'
import ServiceCallIDInput from './InputsServicesBike/ServiceCallIDInput'
import StatusSelectInput from './InputsServicesBike/StatusSelectInput'
import ItemDescriptionInput from './InputsServicesBike/ItemDescriptionInput'
import ItemCodeInput from './InputsServicesBike/ItemCodeInput'
import U_ChasisInput from './InputsServicesBike/U_ChasisInput'
import PriorityInput from './InputsServicesBike/PriorityInput'
import DescriptionInput from './InputsServicesBike/DescriptionInput'
import ResolutionInput from './InputsServicesBike/ResolutionInput'
import CreationTimeInput from './InputsServicesBike/CreationTimeInput'
import CloseTimeInput from './InputsServicesBike/CloseTimeInput'
import ButtonActions from '../ButtonActions'
import MenuServices from './TabsMenu/MenuServices'
// import ModalInventory from './TabsMenu/Operation/ModalInventory'
import TabsMenu from './TabsMenu'
import { useGetProductOutput } from '../../../hooks/useGetProductOutput'
import { useFilterCurrentService } from '../../../hooks/useFilterCurrentService'
import { MotorBikesContext } from '../../../contexts/motorBikesContext'

const FormServiceBike = ({ editInventoryOutPut, inventoryExit = [], removeItemInventory, removeAllItems, replacePropertyHandler, filterState = {}, editStatesToFilter }) => {

    const history = useHistory()
    const params = useParams()

    const { currentService = {}, clearCurrentService, canEdit, servicesCurrentVehicle = [], clearServicesCurrentVehicle } = useContext(MotorBikesContext)

    useFilterCurrentService({ serviceID: params.serviceID })

    const { items = [], loading } = useGetProductOutput({ currentService })

    const onlyNumber = (charcode) => {
        if (charcode <= 47) {
            Swal.fire({ icon: 'error', title: 'Solo puede ingresar números' })
            return false
        }
        if (charcode >= 58) {
            Swal.fire({ icon: 'error', title: 'Solo puede ingresar números' })
            return false
        }
    }

    const comprovePasteHandler = (e) => {
        isNaN(e.clipboardData.getData('Text')) && Swal.fire({
            icon: 'error', title: 'Solo puede ingresar números',
        }) && e.preventDefault()
    }

    return (
        <div className="container mt-5">
            <div className="row mx-0 justify-content-between align-items-center mb-4">
                <div>
                    {
                        (servicesCurrentVehicle.length > 1 && !!params?.serviceID) &&
                        <select className="custom-select custom-select-sm mx-md-2" onChange={(e) => history.replace(`/servicio/${servicesCurrentVehicle[e.target.value].ServiceCallID}/${params.clientCode}/${params.chasis}`, { ...history?.location?.state })}>
                            {
                                servicesCurrentVehicle.map((s, i) => {
                                    return <option key={s.ServiceCallID} selected={params.serviceID === s.ServiceCallID} value={i}>{' N°' + s.ServiceCallID}</option>
                                })
                            }
                        </select>
                    }
                </div>
                {Object.keys(currentService).length > 0 && Object.keys(filterState).length < 2 ?
                    <Spinner color="text-primary" texto="Cargando Orden..." />
                    :
                    Object.keys(currentService).length > 0 &&
                    <ServicePDF currentService={currentService} items={items} filterState={filterState} />
                }
                <div>
                    <i className="fas fa-times" role="button" onClick={() => {
                        clearServicesCurrentVehicle()
                        clearCurrentService()
                        history.goBack()
                    }}></i>
                </div>
            </div>
            <form>
                <div className="row justify-content-center w-100 mx-0">
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <CustomerCodeInput />
                        <CustomerNameInput />
                        {
                            !history.location.pathname.includes('/servicio-interno') &&
                            <BPCellularInput canEdit={canEdit} onlyNumber={onlyNumber} comprovePasteHandler={comprovePasteHandler} />
                        }
                        {
                            (((('U_Modelo' in currentService) && !!currentService.U_Modelo) && (('InternalSerialNum' in currentService) && !!currentService.InternalSerialNum)) || (('InternalSerialNum' in currentService) && !!currentService.InternalSerialNum)) ?
                                <InternalSerialNumbrer canEdit={canEdit} />
                                :
                                null
                        }
                        <U_ChasisInput canEdit={canEdit} />
                        {
                            (!!currentService.ItemCode || !!currentService.ItemCode) && <ItemCodeInput canEdit={canEdit} />
                        }
                        {
                            (!!currentService.ItemDescription || !!currentService.ItemDescription || !!currentService.U_Modelo) && <ItemDescriptionInput canEdit={canEdit} />
                        }
                        {
                            (Object.keys(currentService).length !== 0 && ((currentService.Status == "-1" && !!currentService.ServiceCallID && !!currentService.ClosingDate) || ((currentService.Status != "-1" && !!currentService.U_Modelo && !!currentService.InternalSerialNum && !!currentService.ItemDescription) === false))) ?
                                null
                                :
                                (Object.keys(currentService).length === 0 && !!currentService.ItemDescription === false && !!currentService.U_Modelo === false) &&
                                < ModalSearchBrand replacePropertyHandler={replacePropertyHandler} />
                        }
                    </div>
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <div className="mx-0 pb-1 h-50 pl-md-5">
                            <StatusSelectInput canEdit={canEdit} />
                            <ServiceCallIDInput canEdit={canEdit} />
                            <PriorityInput canEdit={canEdit} />
                        </div>
                        <div className="mx-0 pl-md-5 pt-1 h-50">
                            <CreationTimeInput canEdit={canEdit} />
                            <CloseTimeInput canEdit={canEdit} />
                        </div>
                    </div>
                    <div className="col-12 my-3 mb-md-0 ">
                        <SubjectInput canEdit={canEdit} />
                        <DescriptionInput canEdit={canEdit} />
                        <ResolutionInput canEdit={canEdit} />
                    </div>
                    <div className="tab-content mx-md-4 w-100" id="nav-tabContent">
                        <MenuServices />
                        <TabsMenu editInventoryOutPut={editInventoryOutPut} inventoryExit={inventoryExit} removeItemInventory={removeItemInventory} removeAllItems={removeAllItems}
                            items={items} loading={loading} editStatesToFilter={editStatesToFilter} filterState={filterState}
                        />
                    </div>
                </div>
                <ButtonActions replacePropertyHandler={replacePropertyHandler} inventoryExit={inventoryExit} />
            </form>
            {/* <ModalInventory editInventoryOutPut={editInventoryOutPut} inventoryExit={inventoryExit} removeItemInventory={removeItemInventory} removeAllItems={removeAllItems} /> */}
        </div >
    )
}

export default React.memo(FormServiceBike)