import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function ServiceCallIDInput() {

    const { newService = {} } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return <InputsElements
        label="ID de Llamada"
        name="ServiceCallID"
        value={currentService.ServiceCallID ?? newService.ServiceCallID}
        inputState={true}
    />

}

export default React.memo(ServiceCallIDInput)