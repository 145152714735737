import React, { createContext, useCallback } from 'react'
import useReducerServiceGralContext from './useReducerServiceGralContext'
import { initialStateAllServices } from '../initialStateAllServices'
import { currentServiceActions } from '../actions'

export default ({ children }) => {

    const { currentService = {}, currentServiceSelectCombo = {}, dinamicDataService = {}, currentVehicleData, stateToFilter, staticDataService, inventoryExit, dispatchServiceGral } = useReducerServiceGralContext(initialStateAllServices)

    const setStaticDataService = useCallback(content => {
        dispatchServiceGral({
            type: currentServiceActions.SET_STATICDATASERVICE,
            payload: content
        })
    }, [dispatchServiceGral])

    const editDinamicServiceDataHandler = useCallback(content => {
        dispatchServiceGral({
            type: currentServiceActions.EDIT_DINAMICDATASERVICE,
            payload: content
        })
    }, [dispatchServiceGral])

    const setCurrentService = useCallback(content => {
        dispatchServiceGral({
            type: currentServiceActions.SET_CURRENTSERVICE,
            payload: content
        })
    }, [dispatchServiceGral])

    const setCurrentServiceSelectCombo = useCallback(content => {
        dispatchServiceGral({
            type: currentServiceActions.SET_CURRENTSERVICESELECTCOMBO,
            payload: content
        })
    }, [dispatchServiceGral])

    const setInventoryExit = useCallback(content => {
        dispatchServiceGral({
            type: currentServiceActions.SET_INVENTORYEXIT,
            payload: content
        })
    }, [dispatchServiceGral])

    const editInventoryExit = useCallback(content => {
        dispatchServiceGral({
            type: currentServiceActions.EDIT_INVENTORYEXIT,
            payload: content
        })
    }, [dispatchServiceGral])

    const clearDinamicDataService = useCallback(() => {
        dispatchServiceGral({
            type: currentServiceActions.CLEAR_DINAMICDATASERVICE,
            payload: {}
        })
    }, [dispatchServiceGral])

    const editStatesToFilter = useCallback(content => {
        dispatchServiceGral({
            type: currentServiceActions.EDIT_STATETOFILTER,
            payload: content
        })
    }, [dispatchServiceGral])

    return (
        <ContextService.Provider value={{
            inventoryExit,
            currentService,
            currentServiceSelectCombo,
            currentVehicleData,
            staticDataService,
            dinamicDataService,
            setInventoryExit,
            editInventoryExit,
            setCurrentService,
            setCurrentServiceSelectCombo,
            editStatesToFilter,
            setStaticDataService,
            clearDinamicDataService,
            editDinamicServiceDataHandler,
        }}>
            {children}
        </ContextService.Provider>
    )
}

export const ContextService = createContext()
