import React, { useContext, useEffect, useRef, useState } from 'react'
import { BikesContext } from '../../../../../contexts/bikesContext'
import { MotorBikesContext } from '../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../elements/SelectInput'
import clientAxios from '../../../../../utils/clientAxios'

function ProblemType() {

    const [problemTypeData, setProblemTypeData] = useState([])

    const { newService } = useContext(MotorBikesContext)

    const { editStatesToFilter, filterState } = useContext(BikesContext)

    const { currentService = {}, dinamicDataService = {}, editDinamicServiceDataHandler } = useContext(ContextService)

    


    // const editStatesToFilter = ({ ProblemType }) => {
    //     // dispatchHandlerServiceBikes({ type: actionsServices.UPDATE_INPUTSNAME, payLoad: ProblemType })
    // }

    console.log('currentService', currentService)

    const problemTypeSelect = useRef(null)

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async function getProblemData() {
        try {
            const resultServiceCallProblemTypes = await clientAxios.get('/api/v1/ServiceCallProblemTypes', {
                params: {
                    conditions: "(contains(Description, 'VTA-B') or contains(Description, 'POSTVENTA-B'))"
                }
            })
            setProblemTypeData(resultServiceCallProblemTypes.data)
            sleep(1000).then(() =>
                editStatesToFilter({ [problemTypeSelect?.current?.name]: problemTypeSelect?.current?.selectedOptions?.length > 0 ? problemTypeSelect?.current?.selectedOptions[0]?.innerText : '' })
            )
        } catch (error) {
            console.log(error && !!error.response ? error.response : error)
        }
    }

    useEffect(() => getProblemData(), [])

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        refProps={problemTypeSelect}
                        label="Tipo de Problema"
                        name="ProblemType"
                        id="ProblemType"
                        value={newService.ProblemType ?? ''}
                        inputState={true}
                        functEdit={e => {
                            editDinamicServiceDataHandler({ [e.target.name]: e.target.value })
                            editStatesToFilter({ [e.target.name]: e.target.selectedOptions?.length > 0 ? e.target.selectedOptions[0]?.innerText : '' })
                        }}
                    >
                        <option hidden>Elegir Problema</option>
                        {
                            (problemTypeData.length > 0 && Object.keys(filterState).length > 0) &&
                            problemTypeData.sort((a, b) => a.Name.localeCompare(b.Name))
                                .filter(desc => desc.Description === filterState.Origin)
                                .map(array1 => <option key={'problemType' + array1.ProblemTypeID} selected={(newService.ProblemType == array1.ProblemTypeID)} value={array1.ProblemTypeID} >{array1.Name}</option>)

                        }
                    </SelectInput>
                    :
                    <SelectInput
                        refProps={problemTypeSelect}
                        label="Tipo de Problema"
                        name="ProblemType"
                        id="ProblemType"
                        value={currentService.ProblemType ?? ''}
                        inputState={true}
                        functEdit={(e) => editStatesToFilter({ [e.target.name]: e.target.selectedOptions?.length > 0 ? e.target.selectedOptions[0]?.innerText : '' })
                        }
                    >
                        <option hidden>Elegir Problema</option>
                        {
                            (problemTypeData && Object.keys(filterState).length > 0) &&
                            problemTypeData.sort((a, b) => a.Name.localeCompare(b.Name))
                                .filter(desc => desc.Description === filterState.Origin)
                                .map(array1 => <option key={'problemType' + array1.ProblemTypeID} selected={(currentService.ProblemType == array1.ProblemTypeID)} value={array1.ProblemTypeID} >{array1.Name}</option>)

                        }
                    </SelectInput >
            }
        </>
    )
}

export default React.memo(ProblemType)