import React, { createContext } from 'react'
import useReducerBikes from './useReducerBikes'
import { servicesBikesActions } from '../actions'

export default ({ children }) => {

    const initialState = {
        currentService: {},
        newServiceBike: {},
        filterState: {},
    }

    const { currentService, filterState, newServiceBike, dispatchBikes } = useReducerBikes(initialState)


    const editStatesToFilter = (content) => {
        dispatchBikes({
            type: servicesBikesActions.UPDATE_OPTIONSTEXT,
            payload: content
        })
    }

    return (
        <BikesContext.Provider
            value={{
                currentService,
                filterState,
                editStatesToFilter
            }}>
            {children}
        </BikesContext.Provider>
    )
}

export const BikesContext = createContext()