import React, { useContext } from 'react'
import { ContextService } from '../../../../../contexts/serviceGralContext'
import TableComponent from '../../../../../elements/TableComponent'

const History = () => {

    const { serviceData = [] } = useContext(ContextService)

    return (
        <div className="tab-pane fade" id="nav-Historial" role="tabpanel" aria-labelledby="nav-Historial-tab">
            <div className="row justify-content-center mx-0 p-2">
                {
                    serviceData.length > 0 ?
                        <TableComponent columns={
                            <>
                                <th>Service</th>
                                <th>Fecha</th>
                                <th>Asunto</th>
                                <th>Sucursal</th>
                                <th>Kilometraje</th>
                            </>
                        }
                            id="docRelTalbe"
                        >
                            {
                                serviceData.sort((a, b) => b.DocNum > a.DocNum ? 1 : a.DocNum > b.DocNum ? -1 : 0).map(d =>
                                    <tr key={d.DocNum}>
                                        <td title={d.ServiceCallID}>{d.ServiceCallID}</td>
                                        <td>{!!d.CreationDate ? new Date(d.CreationDate.replaceAll('-', '/')).toLocaleString('default', { day: '2-digit', month: '2-digit', year: 'numeric' }) : ''}</td>
                                        <td className="text-truncate" title={d.Subject ? d.Subject : ''}>{d.Subject}</td>
                                        <td className="text-truncate" title={d.AliasName ? d.AliasName : ''}>{d.AliasName === undefined ? 'No indica' : d.AliasName}</td>
                                        <td>{d.U_Kilometraje}</td>
                                    </tr>
                                )
                            }
                        </TableComponent>
                        :
                        <p className="text-center">No existen Services para el vehículo</p>

                }
            </div>
        </div>
    )
}

export default React.memo(History)