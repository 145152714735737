import { useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getBusinessData } from "../components/business/getBusinessData";
import { BusinessContext } from "../contexts/businessContext";
import SweetAlert from "../elements/SweetAlert";
import clientAxios from "../utils/clientAxios";
import { useCookies } from "react-cookie";

export function useGetBusinessHandler(dni) {
  const history = useHistory();

  const {
    businessData = {},
    setBusinessData,
    setBusinessVehicles,
    setBusinessVehiclesNotFromYuhmak,
    setBusinessBikes,
  } = useContext(BusinessContext);

  const params = useParams();
  const [cookies, setCookie] = useCookies();

  function newLogin() {
    try {
      clientAxios.post("/api/v1/NewClient").then((response) => {
        console.log("response", response.data);
        const newArray = Object.entries(response.data).map((i) => i[1]);
        console.log(newArray);
        // setCookie('userName', 'dseme')
        const Cookies = newArray.map((cookies) => cookies);
        const b1session = Cookies[1];
        setCookie("B1SESSION", b1session);
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const modalNewBussines = document.getElementById("openModalNewClient");
    if (
      !!dni &&
      (businessData.FederalTaxID !== dni ||
        !!businessData?.FederalTaxID === false) &&
      dni?.length >= 7
    ) {
      (async () => {
        const data = await getBusinessData({
          dni,
          codCliente: params?.clientCode,
        });
        if (!!data) {
          if (!!data.message && !!data.status) {
            if (data.status === 404 && data.message.includes("ningún")) {
              SweetAlert({
                icon: "question",
                showConfirmButton: true,
                showCloseButton: true,
                showCancelButton: true,
                text: data.message,
                cancelButtonText: 'Cancelar',
                confirmButtonText: "Cargar Cliente",
                funct: () => newLogin() & modalNewBussines.click(),
              });
              return;
            }
            if (data.status && data.status !== 404 && data.status !== 301) {
              SweetAlert({ text: `${data.message}`, icon: "error" });
              return;
            }
          }
          setBusinessData(data.businessData);
          setBusinessVehicles(data.motorBikes);
          setBusinessBikes(data.bikes);
          setBusinessVehiclesNotFromYuhmak(data.services);

          if (
            !!data.motorBikes === false && data.status === 401 &&
            Array.isArray(data.services) === false &&
            !!data.status &&
            data.status !== 301
          ) {
            SweetAlert({
              icon: "error",
              text: "El cliente no Registra Vehículo/s",
              showConfirmButton: true,
              confirmButtonText: "Cargar nuevo Service",
              funct: () =>
                history.push(`/servicio/${businessData?.CardCode}}`, {
                  ...history?.location?.state,
                }),
            });
          }
        }
      })();
    }
  }, [dni]);
}
