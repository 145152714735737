import React, { useContext } from 'react'
import { ContextService } from '../../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../../elements/SelectInput'
import { options } from './options'

function KitHerramientaSelect() {

    const { currentService = {}, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)
    const valueForSelected = !!dinamicDataService?.ClosingDate ? currentService?.U_Kit_Herramientas : dinamicDataService?.U_Kit_Herramientas

    return (
        <SelectInput
            label="Kit Herramienta"
            name="U_Kit_Herramientas"
            value={valueForSelected ?? ''}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
        >
            <option hidden>Elegir...</option>
            {
                options?.YESORNO.map(val => <option key={'kit' + val}
                    selected={valueForSelected == val}
                    value={val}>{val}</option>)
            }
        </SelectInput>
    )
}

export default React.memo(KitHerramientaSelect)