import Notiflix from "notiflix"
import clientAxios from "../../../../utils/clientAxios"

export const postActivity = async ({ DocEntry, CardCode }) => {
    Notiflix.Loading.dots('Cargando Actividad')
    try {
        const resultActivities = await clientAxios.post('/api/v1/Activities', { DocEntry: DocEntry, CardCode: CardCode })
        // Notiflix.Loading.remove()
        return resultActivities.data.ActivityCode
    } catch (error) {
        Notiflix.Loading.remove()
        return { status: error.response.status, message: error.response.data.code.message.value, code: error.response.data.code }
    }
}