import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../contexts/motorBikesContext'
import { BusinessContext } from '../../../../contexts/businessContext'
import InputsElements from '../../../../elements/InputsElements'

function CustomerNameInput() {

    const { currentService = {} } = useContext(MotorBikesContext)
    const { businessData = {} } = useContext(BusinessContext)

    return <InputsElements
        label="Nombre de Socio"
        name="CustomerName"
        value={Object.keys(businessData).length > 0 && !!businessData.CardName ? businessData.CardName : Object.keys(currentService).length > 0 && !!currentService.CustomerName ? currentService.CustomerName : ''}
        inputState={true}
    />
}

export default React.memo(CustomerNameInput)