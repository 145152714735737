import React from 'react'

const InputSearchItems = ({ identifierItem, setIdentifierItem }) => {

    return (
        <div className="input-group input-group-sm mb-1 rounded">
            <div className="input-group-append">
                <select onChange={e => setIdentifierItem({ ...identifierItem, typeSearch: e.target.value })} className="custom-select custom-select-sm">
                    <option hidden selected>Buscar Por</option>
                    <option value="Descripción">Descripción</option>
                    <option value="Código">Código</option>
                </select>
            </div>
            <input type="text" className="form-control form-control-sm" placeholder={identifierItem.typeSearch == 'Código' ? "Ej: 47251" : "Ej: ACEITE YAMALUBE"} onChange={e => setIdentifierItem({ ...identifierItem, item: e.target.value.toUpperCase() })} value={identifierItem?.item} />
        </div>
    )
}

export default React.memo(InputSearchItems)