import { useContext, memo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import BusinessInput from '../components/business/BusinessInput'
import TableOfBikes from '../components/business/TableOfBikes'
import TableOfVehicles from '../components/business/TableOfVehicles'
import VehiclesInStock from '../components/VehiclesInStock'
import VehicleInput from '../components/VehiclesInStock/VehicleInput'
import { BusinessContext } from '../contexts/businessContext'
import { useGetPrevPage } from '../hooks/useGetPrevPage'

const ClientPage = () => {

    const { businessData = {}, clearBusinessVehicles, clearBusinessData, setBusinessVehiclesNotFromYuhmak } = useContext(BusinessContext)

    const history = useHistory()
    const interno = history?.location?.pathname?.includes("interno")

    useGetPrevPage({ prevPage: history.location.pathname })

    useEffect(() => {
        clearBusinessData()
        clearBusinessVehicles()
        setBusinessVehiclesNotFromYuhmak([])
    }, [])

    return (
        <div className="container">
            <div className="row mx-0 justify-content-between align-items-center my-4">
                <div className="col p-0 text-capitalize">
                    <p className="mb-0"><small>{`${(businessData?.CardName || interno) ? "Nombre Cliente:" : ''} ${interno ? "Yuhmak" : businessData?.CardName ?? ''}`}</small></p>
                    <p className="mb-0"><small>{`${(businessData?.CardCode || interno) ? "Código Cliente:" : ''} ${interno ? "P30623893096" : businessData?.CardCode ?? ''}`}</small></p>
                </div>
                {
                    !interno ?
                        <>
                            DNI/CUIL Cliente
                            <BusinessInput />
                        </>
                        :
                        <>
                            Chasis del Vehículo
                            <VehicleInput />
                        </>

                }
                {
                    history?.location?.pathname?.includes("bikes") &&
                    <TableOfBikes />
                }
                {
                    history?.location?.pathname?.includes("motos") &&
                    <TableOfVehicles />
                }
                {
                    interno &&
                    <VehiclesInStock />
                }
            </div>

        </div>
    )
}

export default memo(ClientPage)