import React, { useContext } from 'react'
import { BusinessContext } from '../../../../contexts/businessContext'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function CustomerCode() {

    const { businessData = {} } = useContext(BusinessContext)
    const { staticDataService = {} } = useContext(ContextService)

    return <InputsElements
        label="Cod del Socio de Negocio"
        name="CustomerCode"
        value={businessData?.CardCode ?? staticDataService?.CustomerCode ?? "P30623893096"}
        inputState={true}
    />
}

export default CustomerCode