import React, { useContext } from 'react'
import { BusinessContext } from '../../../../contexts/businessContext'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function ItemCodeInput() {

    const { currentService = {}, dinamicDataService = {} } = useContext(ContextService)
    const { currentVehicleData = {} } = useContext(BusinessContext)

    return <InputsElements
        label="Artículo"
        name="ItemCode"
        value={dinamicDataService?.ItemCode ?? currentVehicleData?.ItemCode ?? currentService?.ItemCode ?? ''}
        inputState={true}
    />
}

export default ItemCodeInput