import React, { useContext } from 'react'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'
import { BusinessContext } from '../../../../contexts/businessContext'

export default function U_MotorInput() {

    const { currentService = {}, dinamicDataService = {}, staticDataService = {}, editDinamicServiceDataHandler } = useContext(ContextService)
    const { currentVehicleData } = useContext(BusinessContext)

    const isClosed = !!dinamicDataService?.ClosingDate
    const isYuhmakVehicle = !!currentVehicleData.InternalSerialNumb || !!staticDataService?.InternalSerialNum

    return (
        <InputsElements
            label="N° Motor"
            name="U_Motor"
            value={currentVehicleData?.U_Motor ?? dinamicDataService?.U_Motor ?? currentService?.U_Motor ?? ''}
            inputState={(isClosed || isYuhmakVehicle) ? true : dinamicDataService?.Status == "-1" || (!!currentVehicleData.InternalSerialNumb || !!currentVehicleData.U_Motor)}
            functEdit={(e) => (isClosed === false) && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
        />
    )
}