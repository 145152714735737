import React, { useContext,useEffect } from "react";
import Table from "../../elements/TableComponent";
import { useHistory } from "react-router-dom";
import Button from "../../elements/Button";
import { BusinessContext } from "../../contexts/businessContext";


const TableOfVehicles = () => {
  const history = useHistory();




  const {
    businessData = {},
    businessVehicles = [],
    businessVehiclesNotFromYuhmak = [],
    setCurrentVehicleData,
  } = useContext(BusinessContext);

console.log('businessData', businessData, businessVehicles, businessVehiclesNotFromYuhmak)



  const header = [
    "N° Remito",
    "Descripción",
    "N° Motor",
    "N° Chasis",
    "Sucursal de Venta",
    "Acción",
  ];

  return (
    <div className="my-3 w-100">
      {Object.keys(businessData).length > 0 && (
        <Button
          text="Otra Moto"
          funct={() =>
            history.push(`/servicio/${businessData?.CardCode}`, {
              prevPage: history.location.pathname,
            })
          }
        />
      )}
      {businessVehicles.length > 0 ||
      (Array.isArray(businessVehiclesNotFromYuhmak) &&
        businessVehiclesNotFromYuhmak.length > 0) ? (
        <Table
          columns={header.map((h) => (
            <th className="text-center text-nowrap" key={h}>
              {h}
            </th>
          ))}
        >
          {businessVehicles.map(
            ({
              DocEntry = "",
              FolNumFrom = "",
              AliasName = "",
              PTICode = "",
              Dscription = "",
              ItemCode = "",
              U_Motor = "",
              U_Chasis = "",
              IntrSerial = "",
            }) => {
              return (
                <>
                  {!!U_Chasis && (
                    <tr key={PTICode + "-" + `${FolNumFrom}`.padStart(8, "0")}>
                      <td className="text-nowrap text-center">
                        {PTICode + "-" + `${FolNumFrom}`.padStart(8, "0")}
                      </td>
                      <td
                        className="text-nowrap px-0 text-truncate"
                        title={Dscription}
                      >
                        {Dscription}
                      </td>
                      <td className="text-center">{U_Motor}</td>
                      <td className="text-center">{U_Chasis}</td>
                      <td
                        className="text-center text-truncate"
                        title={!!AliasName ? AliasName : ""}
                      >
                        {AliasName}
                      </td>
                      <td className="text-center">
                        <i
                          className="fas fa-edit"
                          role="button"
                          onClick={() => {
                            setCurrentVehicleData({
                              InternalSerialNumber: IntrSerial,
                              ItemDescription: Dscription,
                              U_Chasis,
                              U_Motor,
                              remitoDocEntry: DocEntry,
                              ItemCode,
                            });
                            history.push(
                              `/servicio/${businessData.CardCode}/${U_Chasis}/`,
                              { prevPage: history.location.pathname }
                            );
                          }}
                        ></i>
                      </td>
                    </tr>
                  )}
                </>
              );
            }
          )}
          {Array.isArray(businessVehiclesNotFromYuhmak) &&
            businessVehiclesNotFromYuhmak.map((v) => (
              <tr key={v.U_Chasis} title="Vehículo no comprado en Yuhmak">
                <td></td>
                <td className="text-nowrap px-0 text-truncate">
                  {v.ItemDescription}
                </td>
                <td>{v.U_Chasis}</td>
                <td>{v.U_Motor}</td>
                <td className="text-nowrap px-0 text-truncate">
                  {v.AliasName}
                </td>
                <td className="text-center">
                  <i
                    className="fas fa-edit"
                    role="button"
                    onClick={() => {
                      setCurrentVehicleData({
                        ItemDescription: v.ItemDescription,
                        U_Chasis: v.U_Chasis,
                        U_Motor: v.U_Motor,
                      });
                      history.push(
                        `/servicio/${
                          businessData.CardCode ?? businessData.CustomerCode
                        }/${v.U_Chasis}/`,
                        { prevPage: history.location.pathname }
                      );
                    }}
                  ></i>
                </td>
              </tr>
            ))}
        </Table>
      ) : (
        <div className="text-center mt-3">No existen datos que mostrar</div>
      )}
    </div>
  );
};

export default React.memo(TableOfVehicles);
