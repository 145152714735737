import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../../../elements/Button'
import { sendService } from './sendService'
import { useCookies } from 'react-cookie'
import SweetAlert from '../../../../elements/SweetAlert'
import { ContextService } from '../../../../contexts/serviceGralContext'
import auth from '../../../../utils/auth'
import { useGetPrevPage } from '../../../../hooks/useGetPrevPage'
import { MotorBikesContext } from '../../../../contexts/motorBikesContext'


function ButtonActions() {

    const history = useHistory()
    const params = useParams()
    const [cookies] = useCookies()

    const { previousPage } = useGetPrevPage({ ...history?.location?.state })

    const [serviceCallActivities, SetServiceCallActivities] = useState([])

    const { currentService = {}, inventoryExit = {},currentServiceSelectCombo={}, staticDataService = {}, dinamicDataService = {}, clearDinamicDataService, editDinamicServiceDataHandler, setCurrentService, editInventoryExit } = useContext(ContextService)

    const { clearServicesCurrentVehicle, clearCurrentService } = useContext(MotorBikesContext)

    const setNewServiceCall = () => {
        clearDinamicDataService()
        editDinamicServiceDataHandler({
            Status: "-3",
            Priority: "scp_Low",
            U_Marca: currentService?.U_Marca,
            U_Modelo: currentService?.U_Modelo
        })
        if (!!params?.serviceID) {
            const fullPathName = history.location.pathname
            history.replace(fullPathName.slice(0, (fullPathName.lastIndexOf("/") + 1)))
        }
    }

    const sendServiceHandler = async (e) => {
        e.preventDefault()
        if (!!cookies.WareHouse === false) {
            SweetAlert({ icon: "error", title: "Operación denegada", text: "No se encontró el dato del Almacen, es necesario cerrar sesión y volver a ingresar", showConfirmButton: true, showCancelButton: false, funct: () => auth.logOut(), allowEscapeKey: false })
            return
        }
        if ((dinamicDataService?.Status == "-1" && (dinamicDataService?.ProblemType == "34" || dinamicDataService?.ProblemType == "37"))) {
            if (serviceCallActivities.length === 0 && inventoryExit?.DocumentLines?.length === 0) {
                SweetAlert({ icon: "error", title: "No se puede cerrar el service", text: "Es necesario cargar al menos 1 salida de mercancía para el tipo de llamada seleccionado", showConfirmButton: true, showCancelButton: false })
                return
            }
        }
        if ((dinamicDataService?.ProblemSubType == "77" || dinamicDataService?.ProblemSubType == "12") && inventoryExit?.DocumentLines?.length > 0) {
            SweetAlert({
                icon: "error", title: "No se puede cargar el service",
                // text: "No es posible cargar salida/s de mercancía/s para el tipo de llamada seleccionado", 
                html: "No es posible cargar salida/s de mercancía/s para el tipo de llamada seleccionado. <br><br> ¿Deseas borrar lo/s artículo/s de salida?",
                showCancelButton: true,
                confirmButtonText: 'Borrar Salida/s',
                cancelButtonText: 'Cerrar',
                funct: () => editInventoryExit({ ...inventoryExit, DocumentLines: [] })
            })
            return
        }
        try {
            const resultNewService = await sendService({ servicio: { ...staticDataService, ...dinamicDataService }, inventoryExit, prevPage: previousPage?.prevPage, currentServiceSelectCombo: {...currentServiceSelectCombo } })
            if (!!resultNewService?.message && !!resultNewService?.status) {
                if (resultNewService.message?.includes('Cannot close service call without solution or resolution')) {
                    SweetAlert({ icon: "error", html: `<p className="text-center">Error al intentar cargar el Service</p><br /> Error: ${resultNewService.code.code}, Descripción: Por favor, ingresá una resolución para poder cerrar el Service`, showConfirmButton: true, showCancelButton: false })
                    return
                }
                if (resultNewService?.message?.includes('Enter valid code  [OINS.customer]')) {
                    SweetAlert({ icon: "error", html: `<p className="text-center">Error al intentar cargar el Service</p><br /> Error: ${resultNewService?.code.code},\n\r Descripción: Por favor, solicitar generación de Tarjeta de Equipo tipo Compra`, showConfirmButton: true, showCancelButton: false })
                    return
                }
                if (resultNewService?.message.includes('There are open service calls for this equipment card')) {
                    SweetAlert({ icon: "error", html: `<p className="text-center">Error al intentar cargar el Service</p><br /> Error: ${resultNewService?.code.code}, Descripción: Esta unidad fue vendida anteriormente a otro cliente. Por favor solicitar el cambio de tarjeta de equipo para poder continuar.`, showConfirmButton: true, showCancelButton: false })
                    return
                }
                if (!resultNewService?.message.includes('Cannot close service call without solution or resolution') && !resultNewService?.message.includes('Enter valid code  [OINS.customer]') && !resultNewService?.message.includes('There are open service calls for this equipment card')) {
                    SweetAlert({ icon: "error", html: `<p className="text-center">Error al intentar cargar el Service</p><br /> Error: ${resultNewService?.code.code}, Descripción: ${resultNewService?.message}`, showConfirmButton: true, showCancelButton: false })
                    return
                }
            } else if (resultNewService === 204 || resultNewService === 200) {
                SweetAlert({
                    icon: "success", html: '<p className="text-center">Service cargado Correctamente</p>', showConfirmButton: true, funct: () => {
                        clearServicesCurrentVehicle()
                        clearCurrentService()
                        clearDinamicDataService({})
                        setCurrentService({})
                        history.goBack()
                    }
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (Object.keys(currentService).length > 0) {
            SetServiceCallActivities(currentService?.ServiceCallActivities)
        }
    }, [currentService])

    return (
        <div className="row justify-content-end mx-0 my-3">
            {
                <>
                    {
                        !!dinamicDataService?.ClosingDate && !!dinamicDataService?.ServiceCallID ?
                            null
                            :
                            <Button text='Enviar' funct={e => {
                                // (!!dinamicDataService?.CreationDate === false && !!dinamicDataService?.ServiceCallID === false) && delete dinamicDataService.ServiceCallID
                                sendServiceHandler(e)
                            }}
                            />
                    }
                    {
                        (!!dinamicDataService?.ClosingDate || !!dinamicDataService?.CreationDate) && <Button text='Nuevo Service' funct={setNewServiceCall} />
                    }
                </>
            }

        </div>
    )
}

export default React.memo(ButtonActions)