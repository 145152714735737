import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ChangePassword from "../components/user/ChangePassword.js";
import Button from "../elements/Button";
import DashBoard from "../components/dashboard";

const HomePage = () => {
  const history = useHistory();


  return (
    <div className="container my-5">
      <div className="row mx-0 justify-content-end">
        <Button text="Cambiar Contraseña" target="changePass" modal={true} />
        <ChangePassword />
      </div>
      <h3 className="text-center">Elegir Tipo de Servicio a Realizar</h3>
      <div className="row justify-content-center mx-0">
        <Button text="Motos" funct={() => history.push("/motos/")} />
        <Button text="Motos Interno" funct={() => history.push("/interno/")} />
        {/* <Button text="Bikes" funct={() => history.push('/bikes/')} /> */}
      </div>

      <DashBoard />
    </div>
  );
};

export default HomePage;
