import { useContext, useEffect, useState, memo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { ContextService } from '../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../elements/SelectInput'
import clientAxios from '../../../../../utils/clientAxios'

function TypeOperation() {

    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)
    const valueForSelected = !!dinamicDataService?.ClosingDate ? currentService?.CallType : dinamicDataService?.CallType

    const [typeOperationData, setTypeOperationData] = useState([])
    const typeOperationSelect = useRef(null)

    const history = useHistory()

    const isServiceInterno = history.location.pathname?.includes("servicio-interno")

    useEffect(() => {
        (async () => {
            if (typeOperationData.length === 0) {
                try {
                    const resultServiceCallTypeOperations = await clientAxios.get('/api/v1/ServiceCallTypes')
                    setTypeOperationData(resultServiceCallTypeOperations.data)
                } catch (error) {
                    console.log(error && !!error.response ? error.response : error)
                }
            }
        })()
    }, [])

    useEffect(() => {
        (!!dinamicDataService?.CallType === false && typeOperationSelect?.current?.value !== '') && editDinamicServiceDataHandler({ CallType: Number(typeOperationSelect?.current?.value) })
    }, [isServiceInterno, dinamicDataService?.CallType, typeOperationSelect?.current?.value])


    return (
        <SelectInput
            label="Tipo de Llamada"
            name="CallType"
            refProps={typeOperationSelect}
            value={valueForSelected ?? ''}
            inputState={!!dinamicDataService?.ClosingDate}
            functEdit={e => !!dinamicDataService?.ClosingDate === false && editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
        >
            <option hidden>Elegir Tipo de Llamada</option>
            {
                typeOperationData?.sort((a, b) => a.Name.localeCompare(b.Name))
                    .map(typeOperation =>
                        <option key={'CallType' + typeOperation.CallTypeID}
                            selected={dinamicDataService?.CallType == typeOperation.CallTypeID || (typeOperation.Name?.includes("EXTERNA") && !isServiceInterno) || (typeOperation.Name?.includes("INTERNA") && isServiceInterno)}
                            value={typeOperation.CallTypeID}
                        >
                            {typeOperation.Name}
                        </option>
                    )

            }
        </SelectInput >
    )
}

export default memo(TypeOperation)