import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../../elements/SelectInput'
import { options } from './options'

function CascoSelect() {

    const { editNewServiceHandler, newService } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        label="Casco"
                        name="U_Casco"
                        value={newService.U_Casco ?? ''}
                        inputState={true}
                        functEdit={e => editNewServiceHandler(e)}
                    >
                        <option hidden>Elegir...</option>
                        {options.YESORNO.map(val => <option key={'casco' + val}
                            selected={!!newService.U_Casco && newService.U_Casco === val}
                            value={val}>{val}</option>)}
                    </SelectInput>
                    :
                    <SelectInput
                        label="Casco"
                        name="U_Casco"
                        value={currentService.U_Casco ?? ''}
                        inputState={true}
                    >
                        <option hidden>Elegir...</option>
                        {options.YESORNO.map(val => <option key={'casco' + val}
                            selected={!!currentService.U_Casco && currentService.U_Casco === val}
                            value={val}>{val}</option>)}
                    </SelectInput>
            }
        </>
    )
}

export default React.memo(CascoSelect)