import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../../elements/SelectInput'
import { options } from './options'

const CombustibleSelect = () => {

    const { editNewServiceHandler, newService } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        label="Nivel Combustible"
                        name="U_Nivel_Combustible"
                        value={!!newService.U_Nivel_Combustible ? newService.U_Nivel_Combustible : ''}
                        inputState={true}
                        functEdit={e => editNewServiceHandler(e)}
                    >
                        <option hidden>Elegir...</option>
                        {Object.keys(options.COMBUSTIBLE[0]).map(val => <option key={options.COMBUSTIBLE[0][val]}
                            selected={newService.U_Nivel_Combustible && currentService.U_Nivel_Combustible == val}
                            value={val}>{options.COMBUSTIBLE[0][val]}</option>
                        )}
                    </SelectInput>
                    :
                    <SelectInput
                        label="Nivel Combustible"
                        name="U_Nivel_Combustible"
                        value={currentService.U_Nivel_Combustible ?? ''}
                        inputState={true}
                    >
                        <option hidden>Elegir...</option>
                        {Object.keys(options.COMBUSTIBLE[0]).map(val => <option key={options.COMBUSTIBLE[0][val]}
                            selected={currentService.U_Nivel_Combustible && currentService.U_Nivel_Combustible == val}
                            value={val}>{options.COMBUSTIBLE[0][val]}</option>
                        )}
                    </SelectInput>
            }
        </>
    )
}

export default React.memo(CombustibleSelect)