import React, { useContext } from 'react'
import { BusinessContext } from '../../../../contexts/businessContext'
import { MotorBikesContext } from '../../../../contexts/motorBikesContext'
import InputsElements from '../../../../elements/InputsElements'

function CustomerCode() {

    const { businessData = {} } = useContext(BusinessContext)
    const { currentService = {} } = useContext(MotorBikesContext)

    return <InputsElements
        label="Cod del Socio de Negocio"
        name="CustomerCode"
        value={businessData.CardCode ?? currentService.CustomerCode}
        inputState={true}
    />
}

export default React.memo(CustomerCode)