import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Table from '../../elements/TableComponent'
import '../../css/tables.css'
import { BusinessContext } from '../../contexts/businessContext'

function VehiclesInStock() {

    const history = useHistory()
    const { internalVehicles = [], setCurrentVehicleData, currentVehicleData } = useContext(BusinessContext)
    console.log(`VehiclesInStock ~ currentVehicleData`, currentVehicleData)

    const headerTable = ["Chasis", "Motor", "Vehículo", "Codigo Vehículo", "Código Interno", "Acción"]

    return (
        <div className="mt-5">
            {
                internalVehicles.length > 0 &&
                <Table columns={headerTable.map((h, i) => <th key={i}>{h}</th>)}>
                    {
                        internalVehicles.map((v, i) => <tr key={i}>
                            <td className="text-truncate" title={v.U_Chasis}>{v.U_Chasis}</td>
                            <td className="text-truncate" title={v.U_Motor}>{v.U_Motor}</td>
                            <td className="text-truncate" title={v.ItemName}>{v.ItemName}</td>
                            <td>{v.ItemCode}</td>
                            <td>{v.WhsCode}</td>
                            <td><i className="fas fa-edit" role="button" onClick={() => {
                                setCurrentVehicleData({ InternalSerialNumber: v.IntrSerial, ItemDescription: v.ItemName, U_Chasis: v.U_Chasis, U_Motor: v.U_Motor, ItemCode: v.ItemCode })
                                history.push(`/servicio-interno/P30623893096/${v.U_Chasis}/`, { prevPage: history.location.pathname })
                            }}></i></td>
                        </tr>)
                    }
                </Table>
            }
        </div>
    )
}

export default React.memo(VehiclesInStock)