import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { getItemService } from '../components/motorbikes/services/getItemsService'
import SweetAlert from '../elements/SweetAlert'

export function useGetItems({ item = "", searchType = "" }) {

    const [items, setItems] = useState([])
    const [cookies,] = useCookies()
    const almacen = cookies.WareHouse

    useEffect(() => {
        if (!!item && !!searchType) {
            if (item.length > 4) {
                if (item.includes('10359')) return SweetAlert({
                    icon: 'error',
                    title: 'Código no permitido',
                    html: 'No es posible usar este Código de alarma'
                });
                (async () => {
                    try {
                        const itemsResult = await getItemService({ item, searchType, almacen })
                        setItems(itemsResult)
                    } catch (error) {
                        console.log(error && !!error.response ? error.response : error)
                    }
                })()
            }
        }
    }, [item, searchType])
    return { items, setItems }
}