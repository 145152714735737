import Notiflix from "notiflix"
import clientAxios from "../../../utils/clientAxios"

export const getStock = async (code, WareHouse) => {
    Notiflix.Loading.dots('Comprobando Stock...')
    try {
        const restulGetItems = await clientAxios.get(`/api/v1/Items`, {
            params: {
                item: code,
                searchType: 'Código',
                warehouse: WareHouse
            }
        })
        Notiflix.Loading.remove()
        return restulGetItems.data
    } catch (error) {
        const { response } = error
        Notiflix.Loading.remove()
        console.log(response)
    }
}