import React, { useContext, useEffect, useRef, useState } from 'react'
import { BikesContext } from '../../../../../contexts/bikesContext'
import { MotorBikesContext } from '../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../elements/SelectInput'
import clientAxios from '../../../../../utils/clientAxios'

const SubProblemType = () => {

    const { newService } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    const [subProblemTypeData, setSubProblemTypeData] = useState([])

    const problemSubTypeSelect = useRef(null)

    const { filterState = {}, editStatesToFilter } = useContext(BikesContext)

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    async function getSubProblemType() {
        try {
            const resultServiceCallSubProblemTypes = await clientAxios.get('/api/v1/ServiceCallProblemSubTypes', {
                params: {
                    conditions: "(contains(Description, 'GARANTIA-B') or contains(Description, 'ARMADO-B'))"
                }
            })
            setSubProblemTypeData(resultServiceCallSubProblemTypes.data)
            sleep(2000).then(() =>
                editStatesToFilter(problemSubTypeSelect)
            )
        } catch (error) {
            console.log(error && !!error.response ? error.response : error)
        }
    }

    useEffect(() => {
        getSubProblemType()
    }, [])

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        label="Subtipo de Problema"
                        name="ProblemSubType"
                        id="ProblemSubType"
                        refProps={problemSubTypeSelect}
                        value={newService.ProblemSubType ?? ''}
                        inputStat={!currentService?.Status != "-1"}
                        functEdit={e => {
                            editDinamicServiceDataHandler({ [e.target.name]: e.target.value })
                            editStatesToFilter(problemSubTypeSelect)
                        }}
                    >
                        <option hidden>Elegir Subtipo de Problema</option>
                        {
                            (subProblemTypeData && Object.keys(filterState).length > 0) &&
                            subProblemTypeData.sort((a, b) => a.Name.localeCompare(b.Name))
                                .filter(problem => problem.Description.includes(filterState.ProblemType))
                                .map(array1 =>
                                    <option key={'problemSubType' + array1.ProblemSubTypeID} selected={newService.ProblemSubType ?? '' == array1.ProblemSubTypeID} value={array1.ProblemSubTypeID}>{array1.Name}</option>
                                )

                        }
                    </SelectInput>
                    :
                    <SelectInput
                        label="Subtipo de Problema"
                        name="ProblemSubType"
                        id="ProblemSubType"
                        refProps={problemSubTypeSelect}
                        value={currentService.ProblemSubType ?? ''}
                        inputStat={!currentService?.Status != "-1"}
                        functEdit={() => editStatesToFilter(problemSubTypeSelect)}
                    >
                        <option hidden>Elegir Subtipo de Problema</option>
                        {
                            (subProblemTypeData && Object.keys(filterState).length > 0) &&
                            subProblemTypeData.sort((a, b) => a.Name.localeCompare(b.Name))
                                .filter(problem => problem.Description.includes(filterState.ProblemType))
                                .map(array1 =>
                                    <option key={'problemSubType' + array1.ProblemSubTypeID} selected={currentService.ProblemSubType ?? '' == array1.ProblemSubTypeID} value={array1.ProblemSubTypeID}>{array1.Name}</option>
                                )

                        }
                    </SelectInput >
            }
        </>
    )
}

export default React.memo(SubProblemType)