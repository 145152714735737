import clientAxios from "../../utils/clientAxios"

export const getOwnServices = async ({ user, frDate, ltDate }) => {
    try {
        const resultGetOwnServices = await clientAxios.get('/api/v1/getOwnServices', {
            params: {
                user: user,
                frDate: frDate,
                ltDate: ltDate
            }
        })
        return resultGetOwnServices.data
    } catch (error) {
        console.log(error)
    }
}