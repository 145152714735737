import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../contexts/motorBikesContext'
import DobleInputs from '../../../../elements/DobleInputs'

function CreationTimeInput() {

    const { currentService, newService } = useContext(MotorBikesContext)

    return <DobleInputs
        label="Creado el"
        valueDate={currentService.CreationDate ?? newService.CreationDate}
        valueTime={currentService.CreationTime ?? newService.CreationTime}
    />
}

export default React.memo(CreationTimeInput)