import React, { useContext, memo } from 'react'
import { useCookies } from 'react-cookie'
import { useParams } from 'react-router'
import { ContextService } from '../contexts/serviceGralContext'
import InputsElements from '../elements/InputsElements'
import { useGetUserData } from '../hooks/useGetUserData'

function UserLogged() {

    const { currentService } = useContext(ContextService)
    const params = useParams()
    const [cookies] = useCookies()

    const { userData = '' } = useGetUserData({ internalKey: currentService?.AssigneeCode })

    const inputValue = !!params?.serviceID === false ? cookies.userName : !!userData ? userData : cookies.userName

    return (
        <div className="row mx-0 flex-nowrap">
            <InputsElements label="Realizado Por:" type="text" inputState={true} value={inputValue} />
        </div>
    )
}

export default memo(UserLogged)