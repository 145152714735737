import Notiflix from "notiflix"
import clientAxios from "../../../utils/clientAxios"

export const getVehiclesInStock = async ({ WareHouse, Chasis }) => {
    Notiflix.Loading.dots('Buscando Vehículo/s...')
    try {
        const resultVehicles = await clientAxios.get('/api/v1/vehiclesInStock', {
            params: {
                WareHouse: WareHouse,
                Chasis: Chasis
            }
        })
        Notiflix.Loading.remove()
        return { vehicles: resultVehicles.data }
    } catch (error) {
        Notiflix.Loading.remove()
        return { error: error, response: error.response, status: error.status }
    }
}

