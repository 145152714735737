import { useReducer } from "react"
import { businessActions } from '../actions'

function useReducerBusinessParner(initialState) {

    function reducer(state, action) {

        const { type, payload } = action
        switch (type) {
            case businessActions.SET_BUSINESS:
                return {
                    ...state,
                    businessData: payload
                }
            case businessActions.CLEAR_BUSINESS:
                return {
                    ...state,
                    businessData: {}
                }
            case businessActions.SET_BUSINESSVEHICLES:
                return {
                    ...state,
                    businessVehicles: payload
                }
            case businessActions.CLEAR_BUSINESSVEHICLES:
                return {
                    ...state,
                    businessVehicles: payload
                }
            case businessActions.SET_BUSINESSVEHICLESNOTFROMYUHMAK:
                return {
                    ...state,
                    businessVehiclesNotFromYuhmak: payload
                }
            case businessActions.CLEAR_BUSINESSVEHICLESNOTFROMYUHMAK:
                return {
                    ...state,
                    businessVehiclesNotFromYuhmak: payload
                }
            case businessActions.SET_BUSINESSBIKES:
                return {
                    ...state,
                    businessBikes: payload
                }
            case businessActions.CLEAR_BUSINESSBIKES:
                return {
                    ...state,
                    businessBikes: payload
                }
            case businessActions.SET_BUSINESSBIKESNOTFROMYUHMAK:
                return {
                    ...state,
                    businessBikesNorFromYuhmak: payload
                }
            case businessActions.CLEAR_BUSINESSBIKESNOTFROMYUHMAK:
                return {
                    ...state,
                    businessBikesNorFromYuhmak: payload
                }
            case businessActions.SET_CURRENTVEHICLEDATA:
                return {
                    ...state,
                    currentVehicleData: payload
                }
            case businessActions.SET_INTERNALVEHICLES:
                return {
                    ...state,
                    internalVehicles: payload
                }
            default:
                return state
        }
    }

    const [state, dispatchBusinessPartner] = useReducer(reducer, initialState)

    const { businessData, businessVehicles, businessVehiclesNotFromYuhmak, businessBikes, businessBikesNorFromYuhmak, currentVehicleData, internalVehicles } = state

    return { businessData, businessVehicles, businessVehiclesNotFromYuhmak, businessBikes, businessBikesNorFromYuhmak, currentVehicleData, internalVehicles, dispatchBusinessPartner }
}

export default useReducerBusinessParner