import React, { useContext } from 'react'
import { useCookies } from 'react-cookie'
import { ContextService } from '../../../../../contexts/serviceGralContext'
import SweetAlert from '../../../../../elements/SweetAlert'
import { getStock } from '../../../services/getStock'

const EachItem = ({ datos, searchType }) => {

    const [cookies,] = useCookies()

    const { inventoryExit = {}, setInventoryExit, editInventoryExit } = useContext(ContextService)

    const OnlyNumber = (event, { datos }) => {
        if (datos?.MeasureUnit === 'UNIDAD') {
            if (event.charCode <= 47) {
                SweetAlert({
                    icon: 'error',
                    title: 'Articulo tipo ' + datos?.MeasureUnit,
                    text: 'Solo puede ingresar NÚMEROS de tipo ENTERO'
                })
                return false
            }
            if (event.charCode >= 58) {
                SweetAlert({
                    icon: 'error',
                    title: 'Articulo tipo ' + datos?.MeasureUnit,
                    text: 'Solo puede ingresar NÚMEROS de tipo ENTERO'
                })
                return false
            }
        }
    }

    const addItem = async (datos) => {
        if (searchType === 'Descripción') {
            const checkStock = await getStock(datos?.ItemCode, cookies.WareHouse)
            if (checkStock) {
                if (checkStock.message && checkStock.message.includes('No hay')) {
                    SweetAlert({
                        icon: 'info',
                        title: 'No hay stock del item Seleccionado',
                        width: 400,
                        html: `<div className="mt-4">
                        <p className="text-left mb-0"><small>CÓDIGO: ${checkStock.itemCode}<br />DESCRIPCIÓN: ${checkStock.itemDescrip}</small></p>
                        </div>`,
                        showConfirmButton: true
                    })
                    return
                }
            }
        }

        setInventoryExit({
            BPL_IDAssignedToInvoice: cookies?.BPLID,
            U_Tipo_Operacion: '1',
            GroupNumber: -2,
            Line: {
                ItemCode: datos?.ItemCode,
                ItemName: datos?.ItemName,
                MeasureUnit: datos?.SalesUnit,
                Quantity: 1,
                UseBaseUnits: datos?.SalesUnit === 'LITROS' ? 'tNO' : 'tYES',
                ProjectCode: "Servicio_Postventa",
                WarehouseCode: cookies?.WareHouse,
            }
        })
    }

    const checkIfAlreadyExist = async (datos) => {
        if (inventoryExit?.DocumentLines?.length > 0) {
            let itemExist = inventoryExit?.DocumentLines?.filter(({ ItemCode }) => ItemCode === datos?.ItemCode)
            console.log(`checkIfAlreadyExist ~ itemExist`, itemExist)
            if (itemExist.length > 0) {
                SweetAlert({ icon: 'error', title: 'Ya existe el item que intentas seleccionar', showConfirmButton: true })
                return
            } else if (itemExist.length === 0) {
                addItem(datos)
            }
        } else {
            addItem(datos)
        }
    }

    const removeItem = (ItemCode) => {
        const index = inventoryExit?.DocumentLines?.findIndex(val => val.ItemCode == ItemCode)
        const newArray = inventoryExit?.DocumentLines?.filter((items, i) => i !== index)
        editInventoryExit({ ...inventoryExit, DocumentLines: newArray })
    }

    const onChangeValueQuantity = (ItemCode, value) => {
        const index = inventoryExit?.DocumentLines?.findIndex(val => val.ItemCode == ItemCode)
        inventoryExit.DocumentLines[index].Quantity = Number(value)
        console.log({ ...inventoryExit })
        editInventoryExit({ ...inventoryExit })
    }

    return (
        <div key={datos?.ItemCode} className="row mx-0 justify-content-between align-items-center w-100 mb-0 border rounded mt-1 py-1 px-3">
            <small className="col-8 text-truncate" title={'Código: ' + datos?.ItemCode + ' ' + datos?.ItemName}>{datos?.ItemName} Tipo: {datos?.SalesUnit ? datos?.SalesUnit : datos?.MeasureUnit}</small>
            <div className="col-3">
                <div className={`row ${datos && datos?.Quantity !== undefined ? 'justify-content-arround' : 'justify-content-end'} align-items-center mx-0`}>
                    {datos?.Quantity !== undefined &&
                        <div className="col-10">
                            <div className="row align-items-center">
                                <input type="number" onKeyPress={(e) => OnlyNumber(e, { datos: datos })} name={`${datos?.ItemCode}`} className="form-control form-control-sm col-5 py-0 text-center" onChange={(e) => onChangeValueQuantity(datos?.ItemCode, e.target.value)} defaultValue={1} />
                            </div>
                        </div>
                    }
                    <i className={`fas ${datos?.Quantity !== undefined ? 'fa-times' : 'fa-check'} text-SAP`} role="button" onClick={() => datos?.Quantity !== undefined ?
                        removeItem(datos?.ItemCode)
                        :
                        checkIfAlreadyExist(datos)
                    }></i>
                </div>
            </div>
        </div>
    )
}

export default React.memo(EachItem)