import React, { useContext, useRef, useState } from 'react'
import clientAxios from '../utils/clientAxios'
import Notiflix from 'notiflix'
import SweetAlert from '../elements/SweetAlert'
import { ContextService } from '../contexts/serviceGralContext'

function ModalSearchBrand() {

    const btnClose1 = useRef()

    const [aggregateItem, setAggregateItem] = useState({})
    const [resultItemSearch, setResultItemSearch] = useState([])
    const [inputDescpItem, setInputDescpItem] = useState({})

    const { editDinamicServiceDataHandler } = useContext(ContextService)

    const ItemsAggregate = ({ datos }) => {
        return (
            <div key={datos.Code} className="row mx-0 justify-content-between align-items-center w-100 mb-0 border rounded mt-1 py-1 px-3">
                <div>
                    <small className="mx-1">Marca: <strong>{datos.nameBrand}</strong></small>
                    <small className="mx-1">Modelo: <strong>{datos.nameModel}</strong></small>
                </div>
                <div className="col-3">
                    <div className="row justify-content-end align-items-center mx-0">
                        {Object.keys(aggregateItem).length > 0 && aggregateItem?.U_Modelo === datos.U_Modelo ?
                            <i className="fas fa-times text-SAP" role="button" onClick={() => setAggregateItem({})}></i>
                            :
                            <i className="fas fa-check text-SAP" role="button" onClick={() => Object.keys(aggregateItem).length > 0 ?
                                SweetAlert({
                                    showConfirmButton: true,
                                    icon: 'question',
                                    title: 'Ya existe un vehículo seleccionado',
                                    text: 'No es posible seleccionar más de uno',
                                })
                                :
                                setAggregateItem(datos)}>
                            </i>
                        }
                    </div>
                </div>

            </div >
        )
    }

    const getVehicles = async (e) => {
        e.preventDefault()
        Notiflix.Loading.dots('Cargando Vehículos...')
        setAggregateItem([])
        setResultItemSearch([])
        try {
            const resultVehicles = await clientAxios.get('/api/v1/BrandAndModel', {
                params: {
                    marca: inputDescpItem.Marca,
                    modelo: inputDescpItem.Modelo
                }
            })
            setResultItemSearch(resultVehicles.data)
            Notiflix.Loading.remove()
        } catch (error) {
            const { response } = error
            Notiflix.Loading.remove()
            SweetAlert({ icon: "error", text: `${response.data}`, showConfirmButton: true, showCancelButton: false })
        }
    }

    const saveVehicle = (e) => {
        e.preventDefault()
        if (aggregateItem.length === 0) {
            SweetAlert({ icon: 'error', title: 'Debés seleccionar al menos un vehículo' })
            return
        }
        editDinamicServiceDataHandler({
            U_Modelo: aggregateItem.U_Modelo,
            U_Marca: aggregateItem.U_Marca,
            ItemDescription: aggregateItem.ItemName,
            ItemCode: aggregateItem.ItemCode
        })
        btnClose1.current.click()
    }

    return (
        <>
            <button type="button" className="btn-block btn-bgSAP" data-toggle="modal" data-target="#modalSearchBrand">Buscar Marca y Modelo</button>
            <div className="modal" id="modalSearchBrand" tabIndex="-1" role="dialog" aria-labelledby="modalSearchBrandLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-nowrap" id="modalSearchBrandLabel">Marca y Modelo</h5>
                            <div className="row justify-content-center mx-0 input-group input-group-sm mb-1">
                                <input required title="Este campo es Obligatorio" type="text" className="form-control form-control-sm col-3" name="Marca" placeholder="Ej: HONDA" onChange={e => setInputDescpItem({ ...inputDescpItem, [e.target.name]: e.target.value.toUpperCase() })} value={inputDescpItem?.Marca ?? ''} />
                                <input required title="Este campo es Obligatorio" type="text" className="form-control form-control-sm col-3" name="Modelo" placeholder="Ej: MODELO" onChange={e => setInputDescpItem({ ...inputDescpItem, [e.target.name]: e.target.value.toUpperCase() })} value={inputDescpItem?.Modelo ?? ''} />
                                <button hidden onClick={getVehicles}></button>
                            </div>
                            <button type="button" className="close mx-0" data-dismiss="modal" aria-label="Close" onClick={() => setInputDescpItem({})} ref={btnClose1}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="row mx-0 px-4 w-100 justify-content-center">
                            {Object.keys(aggregateItem)?.length > 0 &&
                                <>
                                    <small className="my-2"><strong>Vehículo a agregar: </strong></small>
                                    {
                                        <ItemsAggregate datos={aggregateItem} />
                                    }
                                </>
                            }
                            {
                                resultItemSearch?.length > 0 && <p className="my-2">
                                    <small><strong>Resultado/s de la búsqueda</strong></small>
                                </p>
                            }
                        </div>
                        <div className="modal-body">
                            <div className="row mx-0">
                                <div className="row mx-0 w-100">
                                    {
                                        resultItemSearch?.length > 0 ?
                                            resultItemSearch?.map((r, i) =>
                                                <ItemsAggregate key={i} datos={r} />
                                            )
                                            :
                                            <p>Debes realizar una búsqueda para ver los vehículos</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setInputDescpItem({})}>Cerrar</button>
                            <button className="btn-bgSAP mx-1" onClick={saveVehicle}>Guardar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(ModalSearchBrand)