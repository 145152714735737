import clientAxios from "../../../utils/clientAxios"

export const getCombos = async ({ cardCode }) => {
    try {
        const resultCombos = await clientAxios.get('api/v1/Combos', {
            params: {
                CardCode: cardCode
            }
        })
        return resultCombos.data
    } catch (error) {
        return error
    }
}