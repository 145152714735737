import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../contexts/motorBikesContext'
import DobleInputs from '../../../../elements/DobleInputs'

function CloseTimeInput() {

    const { currentService, newService } = useContext(MotorBikesContext)


    return <DobleInputs
        label="Cerrado el"
        valueDate={currentService.ClosingDate ?? newService.ClosingDate}
        valueTime={currentService.ClosingTime ?? newService.ClosingTime}
    />
}

export default React.memo(CloseTimeInput)