import { createContext } from 'react'
import useReducerBusinessParner from './useReducerBusiness'
import { businessActions } from '../actions'

export default ({ children }) => {

    const initialState = {
        businessData: {},
        businessVehicles: [],
        businessBikes: [],
        businessVehiclesNotFromYuhmak: [],
        businessBikesNorFromYuhmak: [],
        currentVehicleData: {},
        internalVehicles: []
    }

    const { businessData, businessVehicles, businessVehiclesNotFromYuhmak, businessBikes, businessBikesNorFromYuhmak, currentVehicleData, internalVehicles, dispatchBusinessPartner } = useReducerBusinessParner(initialState)

    const setBusinessData = content => {
        dispatchBusinessPartner({
            type: businessActions.SET_BUSINESS,
            payload: content
        })
    }

    const clearBusinessData = () => {
        dispatchBusinessPartner({
            type: businessActions.CLEAR_BUSINESS,
            payload: {}
        })
    }

    const setBusinessVehicles = content => {
        dispatchBusinessPartner({
            type: businessActions.SET_BUSINESSVEHICLES,
            payload: content
        })
    }

    const setInternalVehicles = content => {
        dispatchBusinessPartner({
            type: businessActions.SET_INTERNALVEHICLES,
            payload: content
        })
    }

    const clearBusinessVehicles = () => {
        dispatchBusinessPartner({
            type: businessActions.CLEAR_BUSINESSVEHICLES,
            payload: []
        })
    }

    const setBusinessVehiclesNotFromYuhmak = content => {
        dispatchBusinessPartner({
            type: businessActions.SET_BUSINESSVEHICLESNOTFROMYUHMAK,
            payload: content
        })
    }

    const setBusinessBikes = content => {
        dispatchBusinessPartner({
            type: businessActions.SET_BUSINESSBIKES,
            payload: content
        })
    }

    const setCurrentVehicleData = content => {
        dispatchBusinessPartner({
            type: businessActions.SET_CURRENTVEHICLEDATA,
            payload: content
        })
    }

    const setBusinessBikesNotFromYuhmak = () => {
        dispatchBusinessPartner({
            type: businessActions.SET_BUSINESSBIKESNOTFROMYUHMAK,
            payload: {}
        })
    }

    return (
        <BusinessContext.Provider
            value={{
                businessData,
                businessBikes,
                businessVehicles,
                internalVehicles,
                currentVehicleData,
                businessBikesNorFromYuhmak,
                businessVehiclesNotFromYuhmak,
                setBusinessData,
                setBusinessBikes,
                clearBusinessData,
                setInternalVehicles,
                setBusinessVehicles,
                clearBusinessVehicles,
                setCurrentVehicleData,
                setBusinessBikesNotFromYuhmak,
                setBusinessVehiclesNotFromYuhmak,
            }}
        >
            {children}
        </BusinessContext.Provider>
    )
}

export const BusinessContext = createContext()