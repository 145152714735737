import clientAxios from "../../../../utils/clientAxios"
import Notiflix from 'notiflix'

export const getCombosQuantity = async ({ cardCode }) => {
    Notiflix.Loading.dots('Cargando Datos del Cliente...')
    try {
        const resultCombos = await clientAxios.get('api/v1/CombosQuantity', {
            params: {
                CardCode: cardCode
            }
        })
        Notiflix.Loading.remove()
        return resultCombos.data
    } catch (error) {
                return error
    }
}