import React, { useContext } from 'react'
import InputsElements from '../../../../elements/InputsElements'
import { ContextService } from '../../../../contexts/serviceGralContext'

function CustomerNameInput() {

    const { staticDataService = {}, currentService = {} } = useContext(ContextService)

    return <InputsElements
        label="Nombre de Socio"
        name="CustomerName"
        value={staticDataService?.CustomerName ?? currentService?.CustomerName ?? ''}
        inputState={true}
    />
}

export default CustomerNameInput