import React from 'react'
import auth from '../utils/auth'
import { Route, Redirect } from 'react-router-dom'
import { useCookies } from 'react-cookie'

export default function PrivateRoute({ component: Component, role, ...rest }) {

    const [cookies] = useCookies()

    return (
        <	Route {...rest} render={(props) => (
            auth.isAuthenticated() && !!cookies.WareHouse
                // && (role ? auth.hasRole(role) : true)
                ? <Component {...props} />
                : <Redirect to={{
                    pathname: '/',
                    state: { from: props.location }
                }} />
        )} />
    )
}
