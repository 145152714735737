import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function DescriptionInput() {

    const { newService, editNewServiceHandler } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <>
            {
                currentService?.Status != "-1" ?
                    <InputsElements
                        label="Comentario"
                        name="Description"
                        value={newService.Description ?? ''}
                        functEdit={e => editNewServiceHandler(e)}
                        inputState={true}
                    />
                    :
                    <InputsElements
                        label="Comentario"
                        name="Description"
                        value={currentService.Description ?? ''}
                        inputState={true}
                    />
            }
        </>
    )
}

export default React.memo(DescriptionInput)