import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../contexts/serviceGralContext'
import InputsElements from '../../../../elements/InputsElements'

function U_ChasisInput() {

    const { newService } = useContext(MotorBikesContext)
    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <InputsElements
                        label="N° Chasis"
                        name="U_Chasis"
                        value={dinamicDataService.U_Chasis ?? currentService?.U_Chasis ?? ''}
                        inputState={(!currentService?.Status != "-1" && (!!newService.InternalSerialNumb || !!newService.U_Chasis))}
                        functEdit={(e) => editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
                    />
                    :
                    <InputsElements
                        label="N° Chasis"
                        name="U_Chasis"
                        value={currentService.U_Chasis ?? ''}
                        inputState={true}
                    />
            }
        </>
    )
}

export default React.memo(U_ChasisInput)