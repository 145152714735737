import clientAxios from '../../../utils/clientAxios'
import Notiflix from 'notiflix'

export default async function getService({ vehicle, client, serviceCallID }) {
    Notiflix.Loading.dots('Cargando Services...')
    try {
        const services = await clientAxios.get(`/api/v1/ServiceCalls`,
            {
                params: {
                    Chasis: vehicle,
                    CustomerCode: client,
                    serviceCallID: serviceCallID
                }
            }
        )
        Notiflix.Loading.remove()
        return services.data
    } catch (error) {
        const { response } = error
        console.log(response)
        Notiflix.Loading.remove()
    }
}