import React, { useContext } from 'react'
import InputsElements from '../../../../elements/InputsElements'
import { useParams } from 'react-router-dom'
import { ContextService } from '../../../../contexts/serviceGralContext'

function ServiceCallIDInput() {

    const params = useParams()

    const { dinamicDataService } = useContext(ContextService)

    return <InputsElements
        label="ID de Llamada"
        name="ServiceCallID"
        value={!!dinamicDataService?.ServiceCallID ? dinamicDataService?.ServiceCallID : !!params?.serviceID ? params?.serviceID : ''}
        inputState={true}
    />

}

export default ServiceCallIDInput