import React, { useContext } from 'react'
import { ContextService } from '../../../../contexts/serviceGralContext'
import DobleInputs from '../../../../elements/DobleInputs'

function CreationTimeInput() {

    const { dinamicDataService } = useContext(ContextService)

    return <DobleInputs
        label="Creado el"
        valueDate={dinamicDataService?.CreationDate ?? ""}
        valueTime={dinamicDataService?.CreationTime ?? ""}
    />
}

export default CreationTimeInput