import React, { useContext } from 'react'
import { MotorBikesContext } from '../../../../../../contexts/motorBikesContext'
import { ContextService } from '../../../../../../contexts/serviceGralContext'
import SelectInput from '../../../../../../elements/SelectInput'
import { options } from './options'

function KitHerramientaSelect() {

    const { editNewServiceHandler, newService } = useContext(MotorBikesContext)
    const { currentService = {}, dinamicDataService = {} } = useContext(ContextService)

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        label="Kit Herramienta"
                        name="U_Kit_Herramientas"
                        value={!!newService.U_Kit_Herramientas ? newService.U_Kit_Herramientas : ''}
                        inputState={true}
                        functEdit={e => editNewServiceHandler(e)}
                    >
                        <option hidden>Elegir...</option>
                        {options?.YESORNO.map(val => <option key={'kit' + val}
                            selected={!!newService.U_Kit_Herramientas && newService.U_Kit_Herramientas === val}
                            value={val}>{val}</option>)}
                    </SelectInput>
                    :
                    <SelectInput
                        label="Kit Herramienta"
                        name="U_Kit_Herramientas"
                        value={!!currentService.U_Kit_Herramientas ? currentService.U_Kit_Herramientas : ''}
                        inputState={true}
                    >
                        <option hidden>Elegir...</option>
                        {options?.YESORNO.map(val => <option key={'kit' + val}
                            selected={!!currentService.U_Kit_Herramientas && currentService.U_Kit_Herramientas === val}
                            value={val}>{val}</option>)}
                    </SelectInput>
            }
        </>
    )
}

export default React.memo(KitHerramientaSelect)