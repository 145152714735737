import React, { useContext } from 'react'
import { BusinessContext } from '../../../../../contexts/businessContext'

const BPBillToAddressInput = ({ editHandler }) => {

    const { businessData = {} } = useContext(BusinessContext)
    console.log(" businessData", businessData)

    return (
        <div className="my-4">
            <div className="row mx-0 justify-content-between">
                <div className="input-group input-group-sm mb-1">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id=""><small>Destino de Factura</small></span>
                    </div>
                    <textarea className="form-control form-control-sm" rows="2" name="BPBillToAddress"
                        onChange={editHandler}
                        title={businessData?.Address?.toUpperCase() + '\r' + businessData?.City}
                        disabled={businessData.length > 0 ? false : true}
                        defaultValue={businessData?.Address?.toUpperCase() + '\r' + businessData?.City}>
                    </textarea>
                </div>
            </div>
        </div>
    )
}

export default React.memo(BPBillToAddressInput)