import Swal from 'sweetalert2'

function SweetAlert({ icon, title, text, showConfirmButton = true, showCancelButton = false, html, confirmButtonText = 'Ok', cancelButtonText = "", funct, functDenied, width, allowEscapeKey = true }) {
    Swal.fire({
        icon: icon ? icon : false,
        html: html ? html : false,
        title: title ? title : false,
        text: text ? text : false,
        width: width && width,
        showConfirmButton: showConfirmButton,
        showCancelButton: showCancelButton,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        allowEscapeKey: allowEscapeKey,
        allowOutsideClick: allowEscapeKey,
        showClass: {
            popup: '',
            icon: ''
        },
        hideClass: {
            popup: ''
        }
    }).then((result) => {
        if (funct) {
            if (result.isConfirmed) {
                funct()
            };
        }
        if (functDenied) {
            if (result.isDismissed) {
                functDenied()
            }
        }
    })
}

export default SweetAlert