import React, { useContext } from 'react'
import { ContextService } from '../../../../contexts/serviceGralContext'
import SelectInput from '../../../../elements/SelectInput'

function PriorityInput() {

    const { currentService, dinamicDataService, editDinamicServiceDataHandler } = useContext(ContextService)

    return (
        <>
            {
                dinamicDataService?.Status != "-1" ?
                    <SelectInput
                        label="Prioridad"
                        name="Priority"
                        value={dinamicDataService?.Priority ?? currentService?.Priority ?? ''}
                        inputState={true}
                        functEdit={e => editDinamicServiceDataHandler({ [e.target.name]: e.target.value })}
                    >
                        <option hidden selected>Elegir Prioridad...</option>
                        <option selected={(dinamicDataService?.Priority === "scp_Low" || currentService?.Priority === "scp_Low")} value="scp_Low">Baja</option>
                        <option selected={(dinamicDataService?.Priority === "scp_Medium" || currentService?.Priority === "scp_Medium")} value="scp_Medium">Media</option>
                        <option selected={(dinamicDataService?.Priority === "scp_High" || currentService?.Priority === "scp_High")} value="scp_High">Alta</option>
                    </SelectInput>
                    :
                    <SelectInput
                        label="Prioridad"
                        name="Priority"
                        value={currentService.Priority ?? ''}
                        inputState={true}
                    >
                        <option hidden selected>Elegir Prioridad...</option>
                        <option selected={(currentService.Priority === "scp_Low")} value="scp_Low">Baja</option>
                        <option selected={(currentService.Priority === "scp_Medium")} value="scp_Medium">Media</option>
                        <option selected={(currentService.Priority === "scp_High")} value="scp_High">Alta</option>
                    </SelectInput >
            }
        </>
    )
}

export default React.memo(PriorityInput)