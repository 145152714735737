import React, { useContext } from 'react'
import { ContextService } from '../../../../contexts/serviceGralContext'
import DobleInputs from '../../../../elements/DobleInputs'

function CloseTimeInput() {

    const { dinamicDataService } = useContext(ContextService)


    return <DobleInputs
        label="Cerrado el"
        valueDate={dinamicDataService?.ClosingDate ?? ""}
        valueTime={dinamicDataService?.ClosingTime ?? ""}
    />
}

export default CloseTimeInput